import React, { useState, useEffect, useLayoutEffect } from 'react'
import MapContext from './MapContext'
import 'ol/ol.css'
import { Map as OlMap, View } from 'ol'
import { defaults as defaultControls, FullScreen } from 'ol/control'
import { fromLonLat, get as getProjection } from 'ol/proj'
import { XYZ } from 'ol/source'
import {Cluster, Vector,TileWMS, Tile} from 'ol/source.js';
import sourceTile from 'ol/source/Tile.js';
import {Tile as TileLayer,} from 'ol/layer.js';
import WebGLLayer from 'ol/layer/WebGLPoints'
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Select from 'ol/interaction/Select.js';
import {AssertionError, Collection, Disposable, Geolocation, Graticule, Image, ImageBase, ImageCanvas, ImageTile, Kinetic, MapBrowserEvent, MapBrowserEventHandler, MapEvent, Object, Observable, Overlay, TileCache, TileQueue, TileRange, VERSION, VectorRenderTile, VectorTile, getUid} from 'ol'
import {
  DragRotateAndZoom,
  defaults as defaultInteractions,
} from 'ol/interaction'
import {
  Circle as CircleStyle,
  Fill,
  Text,
  Icon,
  Stroke,
  Style,
} from 'ol/style.js';
import {altKeyOnly, altShiftKeysOnly, always, click, doubleClick, focus, focusWithTabindex, mouseActionButton, mouseOnly, never, noModifierKeys, penOnly, platformModifierKeyOnly, pointerMove, primaryAction, shiftKeyOnly, singleClick, targetNotEditable, touchOnly} from 'ol/events/condition'
import {Interaction} from 'ol/interaction'
import {bbox, tile} from 'ol/loadingstrategy.js'
import axios from 'axios';



const Map = ({ children }) => {
  const [mapObj, setMapObj] = useState({})
  const view = new View({
    projection: getProjection('EPSG:3857'), //구글지도 쓸 경우 변경하여야 함
    center: fromLonLat( 
      [127.478275264, 35.726642192], // 추후 평균값 입력
      getProjection('EPSG:3857')
    ),
    zoom: 7,
    minZoom: 7.6
  })

  useLayoutEffect(() => {
    const map = new OlMap({
      controls: defaultControls({ zoom: false, rotate: false }).extend([
        new FullScreen(),
      ]),
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      layers: [
        new TileLayer({
          source: new XYZ({ // 인증키 관리자 -> dylan
            url: 'http://api.vworld.kr/req/wmts/1.0.0/562DFD04-9C7D-3374-9EB5-F6F3A9C9720D/Base/{z}/{y}/{x}.png',
          }),
        }),
      ],
      target: 'map',
      view: view,
    })
    setMapObj({ map })


    // ******************************************************************************************
    // ******************************************************************************************
    var markerList = []

    //현재는 모든 위험물 데이터를 불러온다
    //2차탐지 서버가 추가되면, 2차탐지완료시 지역 행정코드도 발급하여 위치 확인한다.
    // axios.get('/demo/hazard/')
    // .then((response)=>{
    //   response.data.forEach(element => {

    //     console.log(element)

    //     var changeHostStr = element.image.replace("localhost","52.79.253.46")
        
    //     const marker = new Feature({
    //       type: 'icon',
    //       geometry: new Point([element.longitude,element.latitude]).transform('EPSG:4326', 'EPSG:3857'),
    //       data: changeHostStr
    //     });

    //     markerList.push(marker)

    //     const styles = {
    //       'icon': new Style({
    //         // image: new Icon({
    //         //   anchor: [0.1, 1],
    //         //   src: element.image,
    //         // }),
    //         image: new CircleStyle({
    //           radius: 7,
    //           fill: new Fill({color: 'black'}),
    //           stroke: new Stroke({
    //             color: 'white',
    //             width: 2,
    //           }),
    //         }),
    //       }),
    //     };

    //     const vectorLayer = new VectorLayer({
    //       source: new VectorSource({
    //         features: [marker],
    //       }),
    //       style: function (feature) {
    //         return styles[feature.get('type')];
    //       },
    //     });
    
    //     map.addLayer(vectorLayer);
        


    //   });

    //   const source = new VectorSource({
    //     features: markerList,
    //   });
      
    //   const clusterSource = new Cluster({
    //     distance: parseInt(20, 10),
    //     minDistance: parseInt(10, 10),
    //     source: source,
    //   });

    //   const styleCache = {};
    //   const clusters = new VectorLayer({
    //     source: clusterSource,
    //     style: function (feature) {
    //       const size = feature.get('features').length;
    //       let style = styleCache[size];
    //       if (!style) {
    //         style = new Style({
    //           image: new CircleStyle({
    //             radius: 10,
    //             stroke: new Stroke({
    //               color: '#fff',
    //             }),
    //             fill: new Fill({
    //               color: '#000',
    //             }),
    //           }),
    //           text: new Text({
    //             text: size.toString(),
    //             fill: new Fill({
    //               color: '#fff',
    //             }),
    //           }),
    //         });
    //         styleCache[size] = style;
    //       }
    //       return style;
    //     },
    //   });

    //   map.addLayer(clusters);

    //   // end response
    // })
    // .catch((e)=>{console.log(e)})
    // ******************************************************************************************
    // ******************************************************************************************





    // ******************************************************************************************
    // ******************************************************************************************
    var symbStyle = {

    }

    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        url: 'http://141.164.46.165:12080/geoserver/ropik/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ropik%3Actp_rvn&maxFeatures=50&srsname=EPSG:3857&outputFormat=application%2Fjson',
        // url: 'http://141.164.46.165:12080/geoserver/ropik/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ropik%3Aemd&srsname=EPSG:3857&outputFormat=application%2Fjson', 
        format: new GeoJSON(),
      }),
      style: new Style({
        stroke: new Stroke({
            color: 'white',
            width: 0
        }),
        fill: new Fill({
            color: 'rgba(200,0,0,0.3)'
        })
      }),
    // background:'rgba(0, 0, 0, 1)'
      
    });
    // //행정구역 레이어 추가
    map.addLayer(vectorLayer);

    // ******************************************************************************************
    // ******************************************************************************************

    // ******************************************************************************************
    // ******************************************************************************************
    // 각 행정구역 마우스 오버시 하이라이팅
    // 마우스 오버시 스타일 지정
    var selectPointerMove = new Select({
        condition: pointerMove,
        style: new Style({
            stroke: new Stroke({
                color: 'red',
                width: 0
            }),
            fill: new Fill({
                color: 'rgba(0,0,0,0)'
            })
        })
    });

    console.log("selectPointerMove :: ",selectPointerMove)

    // // interaction 추가
    map.addInteraction(selectPointerMove);

    // ******************************************************************************************
    // ******************************************************************************************
      


function flyTo(location, zoomDepth, done) {
  const duration = 1000;
  const zoom = view.getZoom();
  let parts = 1;
  let called = false;
  function callback(complete) {
    --parts;
    if (called) {
      return;
    }
    if (parts === 0 || !complete) {
      called = true;
      done(complete);
    }
  }
  view.animate(
    {
      center: location,
      duration: duration,
    },
    callback
  );
  view.animate(
    {
      zoom: zoom -0.1,
      duration: duration / 2,
    },
    {
      zoom: zoomDepth,
      duration: duration / 2,
    },
    callback
  );
}


    // ******************************************************************************************
    // ******************************************************************************************
    map.on('click', function(evt) {
      // 해당지도의 feature객체를 가져온다
        var feature = map.forEachFeatureAtPixel(evt.pixel,
          function(feature) {
            console.log("feature option",feature)
            return feature;
          }
        );

        //feature가 빈값이 아닐때 프로퍼티값을 확인 ... 이 if구문에서는 법정동 코드를 체크함.
        //추후 자신의 행정구역 클릭시 마커 추가 예정
        //api도 지역별 분류 예정 (2차탐지 이후 분류)
        if (feature !== undefined) {
          let values = feature.getProperties()
          switch(values["CTPRVN_CD"]){
            case '11':
              console.log("서울")
              // map.getView().setCenter(fromLonLat( 
              //   [126.9783882,37.5666103], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(12);

              flyTo(fromLonLat( 
                [126.9783882,37.5666103], 
                getProjection('EPSG:3857')
              ), 12,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '26':
              console.log("부산")
              // map.getView().setCenter(fromLonLat( 
              //   [129.0750223,35.179816], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(11.5);
              // setTimeout(() => window.location.href = '/Dashboard', 800);

              flyTo(fromLonLat( 
                  [129.0750223,35.179816], 
                  getProjection('EPSG:3857')
                ), 11.5,  function () {
                  setTimeout(() => window.location.href = '/Dashboard', 600);
                });
              
              break;
            case '27':
              console.log("대구")
              // map.getView().setCenter(fromLonLat( 
              //   [128.601763,35.87139], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(11.3);

              flyTo(fromLonLat( 
                [128.601763,35.87139], 
                getProjection('EPSG:3857')
              ), 11.3,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });



              break;
            case '28':
              console.log("인천")
              // map.getView().setCenter(fromLonLat( 
              //   [126.7051505,37.4559418], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(11);

              flyTo(fromLonLat( 
                [126.7051505,37.4559418], 
                getProjection('EPSG:3857')
              ), 11,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '29':
              console.log("광주")
              // map.getView().setCenter(fromLonLat( 
              //   [126.851338,35.160032], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(12.4);

              flyTo(fromLonLat( 
                [126.851338,35.160032],
                getProjection('EPSG:3857')
              ), 12.4,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '30':
              console.log("대전")
              // map.getView().setCenter(fromLonLat( 
              //   [127.3849508,36.3504396], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(12);

              flyTo(fromLonLat( 
                [127.3849508,36.3504396], 
                getProjection('EPSG:3857')
              ), 12,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '31':
              console.log("울산")
              // map.getView().setCenter(fromLonLat( 
              //   [129.3112994,35.5394773], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(11.5);

              flyTo(fromLonLat( 
                [129.3112994,35.5394773],  
                getProjection('EPSG:3857')
              ), 11.5,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '36':
              console.log("세종")
              // map.getView().setCenter(fromLonLat( 
              //   [127.2894325,36.4803512], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(11);

              flyTo(fromLonLat( 
                [127.2894325,36.4803512], 
                getProjection('EPSG:3857')
              ), 11,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '41':
              console.log("경기도")
              // map.getView().setCenter(fromLonLat( 
              //   [127.550802,37.4363177], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(9.5);

              flyTo(fromLonLat( 
                [127.550802,37.4363177],  
                getProjection('EPSG:3857')
              ), 9.5,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '42':
              console.log("강원도")
              // map.getView().setCenter(fromLonLat( 
              //   [128.3115261,37.8603672], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(9.2);

              flyTo(fromLonLat( 
                [128.3115261,37.8603672],
                getProjection('EPSG:3857')
              ), 9.2,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '43':
              console.log("충북")
              // map.getView().setCenter(fromLonLat( 
              //   [127.6551404,36.7853718], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(9.5);

              flyTo(fromLonLat( 
                [127.6551404,36.7853718],
                getProjection('EPSG:3857')
              ), 9.5,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '44':
              console.log("충남")
              // map.getView().setCenter(fromLonLat( 
              //   [126.8453965,36.6173379], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(9.8);

              flyTo(fromLonLat( 
                [126.8453965,36.6173379],
                getProjection('EPSG:3857')
              ), 9.8,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '45':
              console.log("전북")
              // map.getView().setCenter(fromLonLat( 
              //   [127.2368291,35.6910153], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(10);

              flyTo(fromLonLat( 
                [127.2368291,35.6910153],
                getProjection('EPSG:3857')
              ), 10,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '46':
              console.log("전남")
              // map.getView().setCenter(fromLonLat( 
              //   [126.9571667,34.9007274], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(10);

              flyTo(fromLonLat( 
                [126.9571667,34.9007274], 
                getProjection('EPSG:3857')
              ), 10,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '47':
              console.log("경북")
              // map.getView().setCenter(fromLonLat( 
              //   [128.962578,36.6308397], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(9);

              flyTo(fromLonLat( 
                [128.962578,36.6308397], 
                getProjection('EPSG:3857')
              ), 9,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '48':
              console.log("경남")
              // map.getView().setCenter(fromLonLat( 
              //   [128.2417453,35.2414209], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(9.8);

              flyTo(fromLonLat( 
                [128.2417453,35.2414209],
                getProjection('EPSG:3857')
              ), 9.8,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
            case '50':
              console.log("제주")
              // map.getView().setCenter(fromLonLat( 
              //   [126.5758344,33.4273366], 
              //   getProjection('EPSG:3857')
              // ));
              // map.getView().setZoom(11);

              flyTo(fromLonLat( 
                [126.5758344,33.4273366],
                getProjection('EPSG:3857')
              ), 11,  function () {
                setTimeout(() => alert("해당지역은 탐지된 도로 위험물이 없습니다!"), 600);
              });


              break;
          }

          // map.getInteractions().pop()
          


          //feature내부에 features값이 있다면 ... 이 if구문에서의 features는 마커를 의미함.
          if(values["features"] !== undefined){
            //배열내 데이터가 하나일 경우 0번 데이터출력
            if (values["features"].length === 1) {
              console.log(values["features"][0].values_.data)
              window.open(values["features"][0].values_.data, "_blank");
            } else {
              //임시로 대충 출력
              window.open(values["features"][0].values_.data, "_blank");
            }
          }
          
        }
          
        
      });

    // ******************************************************************************************
    // ******************************************************************************************


    map.on('loadstart', function () {
      map.getTargetElement().classList.add('spinner');
    });
    
    map.on('loadend', function () {
      map.getTargetElement().classList.remove('spinner');
    });


    return () => map.setTarget(undefined)
  }, [])

  return <MapContext.Provider value={mapObj}>{children}</MapContext.Provider>
}

export default Map
