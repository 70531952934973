import PropTypes from 'prop-types'
import React,{useState, useContext} from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap"
import  Util  from "./Common/Util"

import moment from 'moment';

// import images
import client from "../../assets/images/client.png";
import transactionAmount from "../../assets/images/transactionAmount.png";
import transactionPrice from "../../assets/images/transactionPrice.png";
import tradeHistory from "../../assets/images/거래내역.jpg";
import news from "../../assets/images/news.jpg";

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import ChartistGraph from 'react-chartist';

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useLocation, useHistory } from "react-router-dom"

import "chartist/dist/scss/chartist.scss";
import "./animate.css";

import { useSelector } from 'react-redux';
import defaultImg from "../../assets/images/default.png"

//i18n
import { withTranslation } from "react-i18next"
import { dashboardResponse, mainResponse, newsResponse, postProcessingWearing, postWearing } from 'crud/auth.crud';
import { useEffect } from 'react';

import MapContext from '../Maps/MapContext'
import MapDetail from '../Maps/MapDetail'

import FadeIn from 'react-fade-in';

const Dashboard = props => {
  const selector = useSelector(state => state)
  const { map } = useContext(MapContext)
  const newDate = new Date()
  const nextDate = new Date()
  const history = useHistory()
  const location = useLocation()

  const [isFetching, setIsFetching] = useState(false)
  const [isListEnd, setIsListEnd] = useState(false)
  const [newYear, setNewYear] = useState(moment(newDate).format("YYYY"))
  const [fixedYear, setfixedYear] = useState(moment(newDate).format("YYYY")) //최신으로 고정되는 년도값
  const [newDateText, setNewDateText] = useState(moment(newDate).format('DD'))
  const [newMonth, setNewMonth] = useState(moment(newDate).format("MM"))
  const [page, setPage] = useState(1)
  const [companyCount, setCompanyCount] = useState();
  const [collectSum, setCollectSum] = useState();
  const [collectMoneySum, setCollectMoneySum] = useState();
  const [newsList, setNewsList] = useState();
  const [isRefresh, setIsRefresh] = useState(false)
  const [dataList, setDataList] = useState([])
  const [monthDateText, setMonthDateText] = useState(moment(newDate).format("MM/DD")+'일자')
  const [maxDate, setMaxDate] = useState(new Date(moment(newDate).format("YYYY"), moment(newDate).format("MM"),0).getDate())
  const [thisMonth, setThisMonth] = useState() //이달의 배출량
  const [prevMonth, setPrevMonth] = useState() //저번달의 배출량
  const [user, setUser] = useState(selector.auth?.user)
  const [myWearingSum, setMyWearingSum] = useState(0)
  const [myProcessSum, setMyProcessSum] = useState(0)
  const [maxProcessWearing, setMaxProcessWearing] = useState(selector.auth?.user?.member_group?.[0]?.company_group?.company_detail?.[0]?.capacity || 500)
  const [startDate, setStartDate] = useState(moment(newDate).format('YYYY-MM-DD') + ' 00:00:00')
  const [endDate, setEndDate] = useState(moment(nextDate.setDate(nextDate.getDate()+1)).format('YYYY-MM-DD') + ' 00:00:00')
  

  const [inputText,setInputText] = useState('');
    const onChange = (e)=>{
        setInputText(e.target.value);
    }

  const getNewsList = async () => {
    try {
      const response = await newsResponse();
      if (response.status === 200) {
        // console.log(response.data)
        const newsList = response.data
        // setNewsList(newsList);
        setNewsList([
          {
            "id": "1001367",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole1.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88267715"
          },
          {
            "id": "1001368",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole2.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88645328"
          },
          {
            "id": "1001369",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole3.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91335314"
          },
          {
            "id": "1001370",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole4.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87565577"
          },
          {
            "id": "1001371",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole5.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88824755"
          },
          {
            "id": "1001372",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole6.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87032122"
          },
          {
            "id": "1001373",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole7.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89585175"
          },
          {
            "id": "1001374",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole8.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90352230"
          },
          {
            "id": "1001375",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole9.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90589769"
          },
          {
            "id": "1001376",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole10.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87905128"
          },
          {
            "id": "1001377",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole11.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89360190"
          },
          {
            "id": "1001378",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole12.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90071437"
          },
          {
            "id": "1001379",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole13.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89423518"
          },
          {
            "id": "1001380",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole14.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88855856"
          },
          {
            "id": "1001381",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole15.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88869680"
          },
          {
            "id": "1001382",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole16.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87166572"
          },
          {
            "id": "1001383",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole17.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91270946"
          },
          {
            "id": "1001384",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole18.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88729593"
          },
          {
            "id": "1001385",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole19.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88163484"
          },
          {
            "id": "1001386",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole20.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90017745"
          },
          {
            "id": "1001387",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole21.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88593570"
          },
          {
            "id": "1001388",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole22.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88007833"
          },
          {
            "id": "1001389",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole23.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87960230"
          },
          {
            "id": "1001390",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole24.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90506803"
          },
          {
            "id": "1001391",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole25.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89591050"
          },
          {
            "id": "1001392",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole26.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88082412"
          },
          {
            "id": "1001393",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole27.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89927702"
          },
          {
            "id": "1001394",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole28.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91525044"
          },
          {
            "id": "1001395",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole29.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91068131"
          },
          {
            "id": "1001396",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole30.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89431069"
          },
          {
            "id": "1001397",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole31.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87882300"
          },
          {
            "id": "1001398",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole32.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89426901"
          },
          {
            "id": "1001399",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole33.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89458592"
          },
          {
            "id": "1001400",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole34.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88281198"
          },
          {
            "id": "1001401",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole35.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87810653"
          },
          {
            "id": "1001402",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole36.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89304809"
          },
          {
            "id": "1001403",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole37.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88404675"
          },
          {
            "id": "1001404",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole38.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89326067"
          },
          {
            "id": "1001405",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole39.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88813164"
          },
          {
            "id": "1001406",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole40.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87075866"
          },
          {
            "id": "1001407",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole41.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91387688"
          },
          {
            "id": "1001408",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole42.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89519196"
          },
          {
            "id": "1001409",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole43.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88011402"
          },
          {
            "id": "1001410",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole44.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87996904"
          },
          {
            "id": "1001411",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole45.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89649673"
          },
          {
            "id": "1001412",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole46.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87591327"
          },
          {
            "id": "1001413",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole47.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87532215"
          },
          {
            "id": "1001414",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole48.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89388636"
          },
          {
            "id": "1001415",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole49.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88476603"
          },
          {
            "id": "1001416",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole50.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88788201"
          },
          {
            "id": "1001417",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole51.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87167330"
          },
          {
            "id": "1001418",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole52.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87013153"
          },
          {
            "id": "1001419",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole53.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88626850"
          },
          {
            "id": "1001420",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole54.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89253414"
          },
          {
            "id": "1001421",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole55.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90729443"
          },
          {
            "id": "1001422",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole56.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91133091"
          },
          {
            "id": "1001423",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole57.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90990205"
          },
          {
            "id": "1001424",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole58.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88765385"
          },
          {
            "id": "1001425",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole59.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88412482"
          },
          {
            "id": "1001426",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole60.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90013901"
          },
          {
            "id": "1001427",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole61.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87242952"
          },
          {
            "id": "1001428",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole62.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88061729"
          },
          {
            "id": "1001429",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole63.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91188253"
          },
          {
            "id": "1001430",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole64.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87268927"
          },
          {
            "id": "1001431",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole65.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91353581"
          },
          {
            "id": "1001432",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole66.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87317618"
          },
          {
            "id": "1001433",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole67.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87036565"
          },
          {
            "id": "1001434",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole68.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87541737"
          },
          {
            "id": "1001435",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole69.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90281519"
          },
          {
            "id": "1001436",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole70.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87297649"
          },
          {
            "id": "1001437",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole71.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89315182"
          },
          {
            "id": "1001438",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole72.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87394772"
          },
          {
            "id": "1001439",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole73.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90952401"
          },
          {
            "id": "1001440",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole74.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88654200"
          },
          {
            "id": "1001441",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole75.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88898828"
          },
          {
            "id": "1001442",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole76.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88857964"
          },
          {
            "id": "1001443",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole77.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90383075"
          },
          {
            "id": "1001444",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole78.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88408077"
          },
          {
            "id": "1001445",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole79.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88872979"
          },
          {
            "id": "1001446",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole80.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89861682"
          },
          {
            "id": "1001447",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole81.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90061709"
          },
          {
            "id": "1001448",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole82.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87901217"
          },
          {
            "id": "1001449",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole83.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91242707"
          },
          {
            "id": "1001450",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole84.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89363789"
          },
          {
            "id": "1001451",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole85.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90304044"
          },
          {
            "id": "1001452",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole86.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89042593"
          },
          {
            "id": "1001453",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole87.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90228139"
          },
          {
            "id": "1001454",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole88.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87325555"
          },
          {
            "id": "1001455",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole89.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87383427"
          },
          {
            "id": "1001456",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole90.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91608866"
          },
          {
            "id": "1001457",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole91.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88987365"
          },
          {
            "id": "1001458",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole92.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90930335"
          },
          {
            "id": "1001459",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole93.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89601213"
          },
          {
            "id": "1001460",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole94.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89310199"
          },
          {
            "id": "1001461",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole95.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88772432"
          },
          {
            "id": "1001462",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole96.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90940408"
          },
          {
            "id": "1001463",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole97.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87260216"
          },
          {
            "id": "1001464",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole98.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90120154"
          },
          {
            "id": "1001465",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole99.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89991360"
          },
          {
            "id": "1001466",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole100.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90238890"
          },
          {
            "id": "1001467",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole101.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91084624"
          },
          {
            "id": "1001468",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole102.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91436661"
          },
          {
            "id": "1001469",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole103.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88887020"
          },
          {
            "id": "1001470",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole104.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90401818"
          },
          {
            "id": "1001471",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole105.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88734101"
          },
          {
            "id": "1001472",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole106.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89703003"
          },
          {
            "id": "1001473",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole107.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88889395"
          },
          {
            "id": "1001474",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole108.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87578846"
          },
          {
            "id": "1001475",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole109.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89062246"
          },
          {
            "id": "1001476",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole110.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87137643"
          },
          {
            "id": "1001477",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole111.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89942543"
          },
          {
            "id": "1001478",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole112.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90200077"
          },
          {
            "id": "1001479",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole113.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88893810"
          },
          {
            "id": "1001480",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole114.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90491666"
          },
          {
            "id": "1001481",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole115.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88867997"
          },
          {
            "id": "1001482",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole116.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89118278"
          },
          {
            "id": "1001483",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole117.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90571208"
          },
          {
            "id": "1001484",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole118.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89210441"
          },
          {
            "id": "1001485",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole119.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87808111"
          },
          {
            "id": "1001486",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole120.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87278416"
          },
          {
            "id": "1001487",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole121.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88513387"
          },
          {
            "id": "1001488",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole122.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88873688"
          },
          {
            "id": "1001489",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole123.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89752221"
          },
          {
            "id": "1001490",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole124.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89750302"
          },
          {
            "id": "1001491",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole125.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88626927"
          },
          {
            "id": "1001492",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole126.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87805869"
          },
          {
            "id": "1001493",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole127.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91374526"
          },
          {
            "id": "1001494",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole128.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91629665"
          }
        ])
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (page,idx, image, classification_type, comment) => {
    const url = {
    pathname : `/${page}`,
    state:{
    	id:idx,
      img:image,
      ctype:classification_type,
      comment: comment,
    }
    }    
    console.log("url :::",url)
    console.log("idx :::",idx)
    

    history.push(url);
  }

  useEffect(() => {
    getNewsList();    
    // getDashboardData();    
    // getFixedData();
    // getWearingData()
    // console.log("설렉터", selector)
  }, [])

  return (
    // <React.Fragment>
    //   <div className="page-content">
    //     <MetaTags>
    //       <title>홈 | Road Safety Total Operating Center </title>
    //     </MetaTags>
    //     <Container fluid>
    //       <div className="page-title-box">
    //         <Row className="align-items-center">
    //           <Col md={8}>
    //             <h6 className="page-title">웨버 홈</h6>
    //             <ol className="breadcrumb m-0">
    //             </ol>
    //           </Col>

    //         </Row>
    //       </div>
        <>


    
    
          
{/* 
          <div id="map" style={{paddingTop: 0, marginBottom: 10, width: '100%', height: '600px',}}>
            <MapDetail/>
          </div> */}

          {/* 여기 부터 테스트  */}
          <div id="layertree" style={{backgroundColor: 'white', display:'none'}}>
      <ul>
        <li>
          <span>배경지도</span>
          <fieldset id="layer0">
            <input id="visible0" class="visible" type="checkbox"/>
            <input class="opacity" type="range" min="0" max="1" step="0.01" />
          </fieldset>
        </li>
        <li>
          <span>행정구역</span>
          <fieldset id="layer1">
          <input id="visible1" class="visible" type="checkbox"/>
          <input class="opacity" type="range" min="0" max="1" step="0.01"/>
          </fieldset>
        </li>
        <li>
          <span>히트맵 </span>
          <fieldset id="layer2">
          <input id="visible1" class="visible" type="checkbox"/>
          {/* <input class="opacity" type="range" min="0" max="1" step="0.01"/> */}
          </fieldset>
        </li>
        <li>
          <span>위험물 </span>
          <fieldset id="layer3">
          <input id="visible1" class="visible" type="checkbox"/>
          <input class="opacity" type="range" min="0" max="1" step="0.01"/>
          </fieldset>
        </li>
      </ul>
    </div>

    <div>
      <input style={{width:'280px', height:'40px'}} placeholder='검색어를 입력해 주세요.        ex)포트홀, 광고판' onChange={onChange} value={inputText}></input> <a onClick={() => {
        if(inputText == '포트홀') {
          setNewsList([
            {
              "id": "1001367",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole1.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88267715"
            },
            {
              "id": "1001368",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole2.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88645328"
            },
            {
              "id": "1001369",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole3.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91335314"
            },
            {
              "id": "1001370",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole4.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87565577"
            },
            {
              "id": "1001371",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole5.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88824755"
            },
            {
              "id": "1001372",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole6.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87032122"
            },
            {
              "id": "1001373",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole7.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89585175"
            },
            {
              "id": "1001374",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole8.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90352230"
            },
            {
              "id": "1001375",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole9.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90589769"
            },
            {
              "id": "1001376",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole10.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87905128"
            },
            {
              "id": "1001377",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole11.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89360190"
            },
            {
              "id": "1001378",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole12.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90071437"
            },
            {
              "id": "1001379",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole13.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89423518"
            },
            {
              "id": "1001380",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole14.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88855856"
            },
            {
              "id": "1001381",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole15.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88869680"
            },
            {
              "id": "1001382",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole16.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87166572"
            },
            {
              "id": "1001383",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole17.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91270946"
            },
            {
              "id": "1001384",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole18.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88729593"
            },
            {
              "id": "1001385",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole19.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88163484"
            },
            {
              "id": "1001386",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole20.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90017745"
            },
            {
              "id": "1001387",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole21.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88593570"
            },
            {
              "id": "1001388",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole22.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88007833"
            },
            {
              "id": "1001389",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole23.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87960230"
            },
            {
              "id": "1001390",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole24.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90506803"
            },
            {
              "id": "1001391",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole25.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89591050"
            },
            {
              "id": "1001392",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole26.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88082412"
            },
            {
              "id": "1001393",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole27.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89927702"
            },
            {
              "id": "1001394",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole28.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91525044"
            },
            {
              "id": "1001395",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole29.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91068131"
            },
            {
              "id": "1001396",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole30.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89431069"
            },
            {
              "id": "1001397",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole31.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87882300"
            },
            {
              "id": "1001398",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole32.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89426901"
            },
            {
              "id": "1001399",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole33.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89458592"
            },
            {
              "id": "1001400",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole34.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88281198"
            },
            {
              "id": "1001401",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole35.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87810653"
            },
            {
              "id": "1001402",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole36.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89304809"
            },
            {
              "id": "1001403",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole37.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88404675"
            },
            {
              "id": "1001404",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole38.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89326067"
            },
            {
              "id": "1001405",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole39.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88813164"
            },
            {
              "id": "1001406",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole40.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87075866"
            },
            {
              "id": "1001407",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole41.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91387688"
            },
            {
              "id": "1001408",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole42.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89519196"
            },
            {
              "id": "1001409",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole43.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88011402"
            },
            {
              "id": "1001410",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole44.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87996904"
            },
            {
              "id": "1001411",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole45.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89649673"
            },
            {
              "id": "1001412",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole46.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87591327"
            },
            {
              "id": "1001413",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole47.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87532215"
            },
            {
              "id": "1001414",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole48.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89388636"
            },
            {
              "id": "1001415",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole49.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88476603"
            },
            {
              "id": "1001416",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole50.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88788201"
            },
            {
              "id": "1001417",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole51.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87167330"
            },
            {
              "id": "1001418",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole52.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87013153"
            },
            {
              "id": "1001419",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole53.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88626850"
            },
            {
              "id": "1001420",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole54.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89253414"
            },
            {
              "id": "1001421",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole55.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90729443"
            },
            {
              "id": "1001422",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole56.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91133091"
            },
            {
              "id": "1001423",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole57.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90990205"
            },
            {
              "id": "1001424",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole58.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88765385"
            },
            {
              "id": "1001425",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole59.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88412482"
            },
            {
              "id": "1001426",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole60.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90013901"
            },
            {
              "id": "1001427",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole61.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87242952"
            },
            {
              "id": "1001428",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole62.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88061729"
            },
            {
              "id": "1001429",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole63.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91188253"
            },
            {
              "id": "1001430",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole64.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87268927"
            },
            {
              "id": "1001431",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole65.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91353581"
            },
            {
              "id": "1001432",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole66.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87317618"
            },
            {
              "id": "1001433",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole67.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87036565"
            },
            {
              "id": "1001434",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole68.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87541737"
            },
            {
              "id": "1001435",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole69.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90281519"
            },
            {
              "id": "1001436",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole70.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87297649"
            },
            {
              "id": "1001437",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole71.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89315182"
            },
            {
              "id": "1001438",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole72.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87394772"
            },
            {
              "id": "1001439",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole73.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90952401"
            },
            {
              "id": "1001440",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole74.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88654200"
            },
            {
              "id": "1001441",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole75.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88898828"
            },
            {
              "id": "1001442",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole76.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88857964"
            },
            {
              "id": "1001443",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole77.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90383075"
            },
            {
              "id": "1001444",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole78.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88408077"
            },
            {
              "id": "1001445",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole79.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88872979"
            },
            {
              "id": "1001446",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole80.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89861682"
            },
            {
              "id": "1001447",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole81.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90061709"
            },
            {
              "id": "1001448",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole82.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87901217"
            },
            {
              "id": "1001449",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole83.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91242707"
            },
            {
              "id": "1001450",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole84.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89363789"
            },
            {
              "id": "1001451",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole85.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90304044"
            },
            {
              "id": "1001452",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole86.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89042593"
            },
            {
              "id": "1001453",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole87.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90228139"
            },
            {
              "id": "1001454",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole88.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87325555"
            },
            {
              "id": "1001455",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole89.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87383427"
            },
            {
              "id": "1001456",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole90.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91608866"
            },
            {
              "id": "1001457",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole91.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88987365"
            },
            {
              "id": "1001458",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole92.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90930335"
            },
            {
              "id": "1001459",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole93.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89601213"
            },
            {
              "id": "1001460",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole94.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89310199"
            },
            {
              "id": "1001461",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole95.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88772432"
            },
            {
              "id": "1001462",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole96.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90940408"
            },
            {
              "id": "1001463",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole97.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87260216"
            },
            {
              "id": "1001464",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole98.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90120154"
            },
            {
              "id": "1001465",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole99.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89991360"
            },
            {
              "id": "1001466",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole100.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90238890"
            },
            {
              "id": "1001467",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole101.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91084624"
            },
            {
              "id": "1001468",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole102.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91436661"
            },
            {
              "id": "1001469",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole103.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88887020"
            },
            {
              "id": "1001470",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole104.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90401818"
            },
            {
              "id": "1001471",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole105.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88734101"
            },
            {
              "id": "1001472",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole106.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89703003"
            },
            {
              "id": "1001473",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole107.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88889395"
            },
            {
              "id": "1001474",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole108.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87578846"
            },
            {
              "id": "1001475",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole109.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89062246"
            },
            {
              "id": "1001476",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole110.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87137643"
            },
            {
              "id": "1001477",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole111.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89942543"
            },
            {
              "id": "1001478",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole112.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90200077"
            },
            {
              "id": "1001479",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole113.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88893810"
            },
            {
              "id": "1001480",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole114.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90491666"
            },
            {
              "id": "1001481",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole115.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88867997"
            },
            {
              "id": "1001482",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole116.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89118278"
            },
            {
              "id": "1001483",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole117.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.90571208"
            },
            {
              "id": "1001484",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole118.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89210441"
            },
            {
              "id": "1001485",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole119.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87808111"
            },
            {
              "id": "1001486",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole120.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87278416"
            },
            {
              "id": "1001487",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole121.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88513387"
            },
            {
              "id": "1001488",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole122.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88873688"
            },
            {
              "id": "1001489",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole123.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89752221"
            },
            {
              "id": "1001490",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole124.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.89750302"
            },
            {
              "id": "1001491",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole125.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.88626927"
            },
            {
              "id": "1001492",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole126.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.87805869"
            },
            {
              "id": "1001493",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole127.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91374526"
            },
            {
              "id": "1001494",
              "create_at": "                   ",
              "image": "/detect/pothole/pothole128.png",
              "status": "탐지완료",
              "classification_type": "포트홀",
              "content": "포트홀",
              "comment": "평균 탐지율 : 0.91629665"
            }
          ])
        } else if(inputText == '박스') {
          console.log("박스 검색")
          setNewsList([
            {
              "id": "1000103",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes1.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87022543"
            },
            {
              "id": "1000104",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes2.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88090493"
            },
            {
              "id": "1000105",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes3.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89632921"
            },
            {
              "id": "1000106",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes4.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90749181"
            },
            {
              "id": "1000107",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes5.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91571784"
            },
            {
              "id": "1000108",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes6.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88711660"
            },
            {
              "id": "1000109",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes7.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90388257"
            },
            {
              "id": "1000110",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes8.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89379390"
            },
            {
              "id": "1000111",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes9.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90658671"
            },
            {
              "id": "1000112",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes10.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89489295"
            },
            {
              "id": "1000113",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes11.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91068604"
            },
            {
              "id": "1000114",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes12.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87147712"
            },
            {
              "id": "1000115",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes13.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89520570"
            },
            {
              "id": "1000116",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes14.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89292813"
            },
            {
              "id": "1000117",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes15.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89696572"
            },
            {
              "id": "1000118",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes16.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89661823"
            },
            {
              "id": "1000119",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes17.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90851241"
            },
            {
              "id": "1000120",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes18.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91412878"
            },
            {
              "id": "1000121",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes19.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89221972"
            },
            {
              "id": "1000122",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes20.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87908428"
            },
            {
              "id": "1000123",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes21.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88706894"
            },
            {
              "id": "1000124",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes22.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87276427"
            },
            {
              "id": "1000125",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes23.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90212528"
            },
            {
              "id": "1000126",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes24.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87327373"
            },
            {
              "id": "1000127",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes25.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89387787"
            },
            {
              "id": "1000128",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes26.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90018428"
            },
            {
              "id": "1000129",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes27.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87616031"
            },
            {
              "id": "1000130",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes28.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88501899"
            },
            {
              "id": "1000131",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes29.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88291580"
            },
            {
              "id": "1000132",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes30.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89967934"
            },
            {
              "id": "1000133",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes31.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87741422"
            },
            {
              "id": "1000134",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes32.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89084657"
            },
            {
              "id": "1000135",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes33.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87634006"
            },
            {
              "id": "1000136",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes34.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88244525"
            },
            {
              "id": "1000137",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes35.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87041952"
            },
            {
              "id": "1000138",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes36.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87504330"
            },
            {
              "id": "1000139",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes37.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89352167"
            },
            {
              "id": "1000140",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes38.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88205693"
            },
            {
              "id": "1000141",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes39.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89265111"
            },
            {
              "id": "1000142",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes40.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88199991"
            },
            {
              "id": "1000143",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes41.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88809332"
            },
            {
              "id": "1000144",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes42.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91111309"
            },
            {
              "id": "1000145",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes43.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88621440"
            },
            {
              "id": "1000146",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes44.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88808285"
            },
            {
              "id": "1000147",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes45.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90247828"
            },
            {
              "id": "1000148",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes46.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89715080"
            },
            {
              "id": "1000149",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes47.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89300190"
            },
            {
              "id": "1000150",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes48.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90632993"
            },
            {
              "id": "1000151",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes49.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88127646"
            },
            {
              "id": "1000152",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes50.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90742095"
            },
            {
              "id": "1000153",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes51.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88885039"
            },
            {
              "id": "1000154",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes52.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88607698"
            },
            {
              "id": "1000155",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes53.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89123386"
            },
            {
              "id": "1000156",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes54.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88033036"
            },
            {
              "id": "1000157",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes55.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90113958"
            },
            {
              "id": "1000158",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes56.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87657199"
            },
            {
              "id": "1000159",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes57.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90598964"
            },
            {
              "id": "1000160",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes58.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87515921"
            },
            {
              "id": "1000161",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes59.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91624033"
            },
            {
              "id": "1000162",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes60.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89444587"
            },
            {
              "id": "1000163",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes61.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91419045"
            },
            {
              "id": "1000164",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes62.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87276368"
            },
            {
              "id": "1000165",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes63.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89605688"
            },
            {
              "id": "1000166",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes64.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89914684"
            },
            {
              "id": "1000167",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes65.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89387392"
            },
            {
              "id": "1000168",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes66.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88769560"
            },
            {
              "id": "1000169",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes67.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89275192"
            },
            {
              "id": "1000170",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes68.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89541749"
            },
            {
              "id": "1000171",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes69.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91008682"
            },
            {
              "id": "1000172",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes70.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90510938"
            },
            {
              "id": "1000173",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes71.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91568248"
            },
            {
              "id": "1000174",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes72.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90016307"
            },
            {
              "id": "1000175",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes73.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88563500"
            },
            {
              "id": "1000176",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes74.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89515316"
            },
            {
              "id": "1000177",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes75.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87109920"
            },
            {
              "id": "1000178",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes76.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87410150"
            },
            {
              "id": "1000179",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes77.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87491334"
            },
            {
              "id": "1000180",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes78.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89573785"
            },
            {
              "id": "1000181",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes79.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87001506"
            },
            {
              "id": "1000182",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes80.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88354160"
            },
            {
              "id": "1000183",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes81.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90858840"
            },
            {
              "id": "1000184",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes82.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90296221"
            },
            {
              "id": "1000185",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes83.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89080995"
            },
            {
              "id": "1000186",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes84.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88786810"
            },
            {
              "id": "1000187",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes85.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90686663"
            },
            {
              "id": "1000188",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes86.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90835159"
            },
            {
              "id": "1000189",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes87.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87704124"
            },
            {
              "id": "1000190",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes88.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90554016"
            },
            {
              "id": "1000191",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes89.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91105708"
            },
            {
              "id": "1000192",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes90.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87416587"
            },
            {
              "id": "1000193",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes91.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90814923"
            },
            {
              "id": "1000194",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes92.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90618576"
            },
            {
              "id": "1000195",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes93.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88999738"
            },
            {
              "id": "1000196",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes94.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87436697"
            },
            {
              "id": "1000197",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes95.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.89085020"
            },
            {
              "id": "1000198",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes96.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88623963"
            },
            {
              "id": "1000199",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes97.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.91028945"
            },
            {
              "id": "1000200",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes98.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.87167588"
            },
            {
              "id": "1000201",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes99.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.90567647"
            },
            {
              "id": "1000202",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes100.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88693616"
            },
            {
              "id": "1000203",
              "create_at": "                   ",
              "image": "/detect/boxes/boxes101.png",
              "status": "탐지완료",
              "classification_type": "박스",
              "content": "박스",
              "comment": "평균 탐지율 : 0.88376194"
            }
          ])
        } else if(inputText == '웅덩이') {
          console.log("웅덩이 검색")
          setNewsList([
            {
              "id": "1000624",
              "create_at": "                   ",
              "image": "/detect/pole/pole1.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89749169"
            },
            {
              "id": "1000625",
              "create_at": "                   ",
              "image": "/detect/pole/pole2.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90249008"
            },
            {
              "id": "1000626",
              "create_at": "                   ",
              "image": "/detect/pole/pole3.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87137013"
            },
            {
              "id": "1000627",
              "create_at": "                   ",
              "image": "/detect/pole/pole4.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91692050"
            },
            {
              "id": "1000628",
              "create_at": "                   ",
              "image": "/detect/pole/pole5.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89028262"
            },
            {
              "id": "1000629",
              "create_at": "                   ",
              "image": "/detect/pole/pole6.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87579466"
            },
            {
              "id": "1000630",
              "create_at": "                   ",
              "image": "/detect/pole/pole7.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91475196"
            },
            {
              "id": "1000631",
              "create_at": "                   ",
              "image": "/detect/pole/pole8.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87588099"
            },
            {
              "id": "1000632",
              "create_at": "                   ",
              "image": "/detect/pole/pole9.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90506542"
            },
            {
              "id": "1000633",
              "create_at": "                   ",
              "image": "/detect/pole/pole10.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88106982"
            },
            {
              "id": "1000634",
              "create_at": "                   ",
              "image": "/detect/pole/pole11.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88752702"
            },
            {
              "id": "1000635",
              "create_at": "                   ",
              "image": "/detect/pole/pole12.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87184398"
            },
            {
              "id": "1000636",
              "create_at": "                   ",
              "image": "/detect/pole/pole13.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90526904"
            },
            {
              "id": "1000637",
              "create_at": "                   ",
              "image": "/detect/pole/pole14.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87814007"
            },
            {
              "id": "1000638",
              "create_at": "                   ",
              "image": "/detect/pole/pole15.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89620956"
            },
            {
              "id": "1000639",
              "create_at": "                   ",
              "image": "/detect/pole/pole16.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87572492"
            },
            {
              "id": "1000640",
              "create_at": "                   ",
              "image": "/detect/pole/pole17.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89101594"
            },
            {
              "id": "1000641",
              "create_at": "                   ",
              "image": "/detect/pole/pole18.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88881378"
            },
            {
              "id": "1000642",
              "create_at": "                   ",
              "image": "/detect/pole/pole19.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88035338"
            },
            {
              "id": "1000643",
              "create_at": "                   ",
              "image": "/detect/pole/pole20.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88170236"
            },
            {
              "id": "1000644",
              "create_at": "                   ",
              "image": "/detect/pole/pole21.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89960015"
            },
            {
              "id": "1000645",
              "create_at": "                   ",
              "image": "/detect/pole/pole22.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87483302"
            },
            {
              "id": "1000646",
              "create_at": "                   ",
              "image": "/detect/pole/pole23.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89888492"
            },
            {
              "id": "1000647",
              "create_at": "                   ",
              "image": "/detect/pole/pole24.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89174014"
            },
            {
              "id": "1000648",
              "create_at": "                   ",
              "image": "/detect/pole/pole25.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90636531"
            },
            {
              "id": "1000649",
              "create_at": "                   ",
              "image": "/detect/pole/pole26.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90357720"
            },
            {
              "id": "1000650",
              "create_at": "                   ",
              "image": "/detect/pole/pole27.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88412112"
            },
            {
              "id": "1000651",
              "create_at": "                   ",
              "image": "/detect/pole/pole28.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91622337"
            },
            {
              "id": "1000652",
              "create_at": "                   ",
              "image": "/detect/pole/pole29.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91144568"
            },
            {
              "id": "1000653",
              "create_at": "                   ",
              "image": "/detect/pole/pole30.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90078345"
            },
            {
              "id": "1000654",
              "create_at": "                   ",
              "image": "/detect/pole/pole31.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87527853"
            },
            {
              "id": "1000655",
              "create_at": "                   ",
              "image": "/detect/pole/pole32.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91575237"
            },
            {
              "id": "1000656",
              "create_at": "                   ",
              "image": "/detect/pole/pole33.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91215233"
            },
            {
              "id": "1000657",
              "create_at": "                   ",
              "image": "/detect/pole/pole34.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89506741"
            },
            {
              "id": "1000658",
              "create_at": "                   ",
              "image": "/detect/pole/pole35.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89408404"
            },
            {
              "id": "1000659",
              "create_at": "                   ",
              "image": "/detect/pole/pole36.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89729948"
            },
            {
              "id": "1000660",
              "create_at": "                   ",
              "image": "/detect/pole/pole37.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89311973"
            },
            {
              "id": "1000661",
              "create_at": "                   ",
              "image": "/detect/pole/pole38.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88683647"
            },
            {
              "id": "1000662",
              "create_at": "                   ",
              "image": "/detect/pole/pole39.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88614600"
            },
            {
              "id": "1000663",
              "create_at": "                   ",
              "image": "/detect/pole/pole40.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91532150"
            },
            {
              "id": "1000664",
              "create_at": "                   ",
              "image": "/detect/pole/pole41.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87019760"
            },
            {
              "id": "1000665",
              "create_at": "                   ",
              "image": "/detect/pole/pole42.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88576637"
            },
            {
              "id": "1000666",
              "create_at": "                   ",
              "image": "/detect/pole/pole43.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91181875"
            },
            {
              "id": "1000667",
              "create_at": "                   ",
              "image": "/detect/pole/pole44.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87745704"
            },
            {
              "id": "1000668",
              "create_at": "                   ",
              "image": "/detect/pole/pole45.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91299082"
            },
            {
              "id": "1000669",
              "create_at": "                   ",
              "image": "/detect/pole/pole46.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91406487"
            },
            {
              "id": "1000670",
              "create_at": "                   ",
              "image": "/detect/pole/pole47.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91028097"
            },
            {
              "id": "1000671",
              "create_at": "                   ",
              "image": "/detect/pole/pole48.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88053698"
            },
            {
              "id": "1000672",
              "create_at": "                   ",
              "image": "/detect/pole/pole49.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87981463"
            },
            {
              "id": "1000673",
              "create_at": "                   ",
              "image": "/detect/pole/pole50.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89749771"
            },
            {
              "id": "1000674",
              "create_at": "                   ",
              "image": "/detect/pole/pole51.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89352273"
            },
            {
              "id": "1000675",
              "create_at": "                   ",
              "image": "/detect/pole/pole52.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91467643"
            },
            {
              "id": "1000676",
              "create_at": "                   ",
              "image": "/detect/pole/pole53.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87317105"
            },
            {
              "id": "1000677",
              "create_at": "                   ",
              "image": "/detect/pole/pole54.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90195341"
            },
            {
              "id": "1000678",
              "create_at": "                   ",
              "image": "/detect/pole/pole55.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91215885"
            },
            {
              "id": "1000679",
              "create_at": "                   ",
              "image": "/detect/pole/pole56.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90977833"
            },
            {
              "id": "1000680",
              "create_at": "                   ",
              "image": "/detect/pole/pole57.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89681181"
            },
            {
              "id": "1000681",
              "create_at": "                   ",
              "image": "/detect/pole/pole58.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87129718"
            },
            {
              "id": "1000682",
              "create_at": "                   ",
              "image": "/detect/pole/pole59.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89261017"
            },
            {
              "id": "1000683",
              "create_at": "                   ",
              "image": "/detect/pole/pole60.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87967370"
            },
            {
              "id": "1000684",
              "create_at": "                   ",
              "image": "/detect/pole/pole61.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91577445"
            },
            {
              "id": "1000685",
              "create_at": "                   ",
              "image": "/detect/pole/pole62.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89307762"
            },
            {
              "id": "1000686",
              "create_at": "                   ",
              "image": "/detect/pole/pole63.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89418113"
            },
            {
              "id": "1000687",
              "create_at": "                   ",
              "image": "/detect/pole/pole64.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87350297"
            },
            {
              "id": "1000688",
              "create_at": "                   ",
              "image": "/detect/pole/pole65.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88176379"
            },
            {
              "id": "1000689",
              "create_at": "                   ",
              "image": "/detect/pole/pole66.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89637717"
            },
            {
              "id": "1000690",
              "create_at": "                   ",
              "image": "/detect/pole/pole67.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89240648"
            },
            {
              "id": "1000691",
              "create_at": "                   ",
              "image": "/detect/pole/pole68.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91305283"
            },
            {
              "id": "1000692",
              "create_at": "                   ",
              "image": "/detect/pole/pole69.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88497819"
            },
            {
              "id": "1000693",
              "create_at": "                   ",
              "image": "/detect/pole/pole70.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90253697"
            },
            {
              "id": "1000694",
              "create_at": "                   ",
              "image": "/detect/pole/pole71.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87888973"
            },
            {
              "id": "1000695",
              "create_at": "                   ",
              "image": "/detect/pole/pole72.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91388573"
            },
            {
              "id": "1000696",
              "create_at": "                   ",
              "image": "/detect/pole/pole73.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91551826"
            },
            {
              "id": "1000697",
              "create_at": "                   ",
              "image": "/detect/pole/pole74.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87671375"
            },
            {
              "id": "1000698",
              "create_at": "                   ",
              "image": "/detect/pole/pole75.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89897369"
            },
            {
              "id": "1000699",
              "create_at": "                   ",
              "image": "/detect/pole/pole76.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88960550"
            },
            {
              "id": "1000700",
              "create_at": "                   ",
              "image": "/detect/pole/pole77.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89016005"
            },
            {
              "id": "1000701",
              "create_at": "                   ",
              "image": "/detect/pole/pole78.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90161564"
            },
            {
              "id": "1000702",
              "create_at": "                   ",
              "image": "/detect/pole/pole79.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87175760"
            },
            {
              "id": "1000703",
              "create_at": "                   ",
              "image": "/detect/pole/pole80.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91538669"
            },
            {
              "id": "1000704",
              "create_at": "                   ",
              "image": "/detect/pole/pole81.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88370258"
            },
            {
              "id": "1000705",
              "create_at": "                   ",
              "image": "/detect/pole/pole82.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89878277"
            },
            {
              "id": "1000706",
              "create_at": "                   ",
              "image": "/detect/pole/pole83.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87911062"
            },
            {
              "id": "1000707",
              "create_at": "                   ",
              "image": "/detect/pole/pole84.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89859509"
            },
            {
              "id": "1000708",
              "create_at": "                   ",
              "image": "/detect/pole/pole85.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89463778"
            },
            {
              "id": "1000709",
              "create_at": "                   ",
              "image": "/detect/pole/pole86.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89687180"
            },
            {
              "id": "1000710",
              "create_at": "                   ",
              "image": "/detect/pole/pole87.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91454448"
            },
            {
              "id": "1000711",
              "create_at": "                   ",
              "image": "/detect/pole/pole88.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88527298"
            },
            {
              "id": "1000712",
              "create_at": "                   ",
              "image": "/detect/pole/pole89.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87398058"
            },
            {
              "id": "1000713",
              "create_at": "                   ",
              "image": "/detect/pole/pole90.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87910726"
            },
            {
              "id": "1000714",
              "create_at": "                   ",
              "image": "/detect/pole/pole91.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91660149"
            },
            {
              "id": "1000715",
              "create_at": "                   ",
              "image": "/detect/pole/pole92.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87329760"
            },
            {
              "id": "1000716",
              "create_at": "                   ",
              "image": "/detect/pole/pole93.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89170373"
            },
            {
              "id": "1000717",
              "create_at": "                   ",
              "image": "/detect/pole/pole94.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87477377"
            },
            {
              "id": "1000718",
              "create_at": "                   ",
              "image": "/detect/pole/pole95.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87137034"
            },
            {
              "id": "1000719",
              "create_at": "                   ",
              "image": "/detect/pole/pole96.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89647256"
            },
            {
              "id": "1000720",
              "create_at": "                   ",
              "image": "/detect/pole/pole97.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88356984"
            },
            {
              "id": "1000721",
              "create_at": "                   ",
              "image": "/detect/pole/pole98.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.88184872"
            },
            {
              "id": "1000722",
              "create_at": "                   ",
              "image": "/detect/pole/pole99.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.91497703"
            },
            {
              "id": "1000723",
              "create_at": "                   ",
              "image": "/detect/pole/pole100.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.89531838"
            },
            {
              "id": "1000724",
              "create_at": "                   ",
              "image": "/detect/pole/pole101.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.87727004"
            },
            {
              "id": "1000725",
              "create_at": "                   ",
              "image": "/detect/pole/pole102.png",
              "status": "탐지완료",
              "classification_type": "웅덩이",
              "content": "웅덩이",
              "comment": "평균 탐지율 : 0.90123241"
            }
          ])
        } else if(inputText == '가전제품') {
          console.log("가전제품 검색")
          setNewsList([
            {
              "id": "1000306",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics1.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88645876"
            },
            {
              "id": "1000307",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics2.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90804645"
            },
            {
              "id": "1000308",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics3.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90258332"
            },
            {
              "id": "1000309",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics4.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87874617"
            },
            {
              "id": "1000310",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics5.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89202544"
            },
            {
              "id": "1000311",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics6.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88932736"
            },
            {
              "id": "1000312",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics7.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87152859"
            },
            {
              "id": "1000313",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics8.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88005188"
            },
            {
              "id": "1000314",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics9.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90711949"
            },
            {
              "id": "1000315",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics10.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88408399"
            },
            {
              "id": "1000316",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics11.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88271613"
            },
            {
              "id": "1000317",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics12.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89432346"
            },
            {
              "id": "1000318",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics13.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90574075"
            },
            {
              "id": "1000319",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics14.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89057139"
            },
            {
              "id": "1000320",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics15.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87196016"
            },
            {
              "id": "1000321",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics16.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91325320"
            },
            {
              "id": "1000322",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics17.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91057227"
            },
            {
              "id": "1000323",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics18.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87099114"
            },
            {
              "id": "1000324",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics19.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89672474"
            },
            {
              "id": "1000325",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics20.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90525199"
            },
            {
              "id": "1000326",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics21.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89680710"
            },
            {
              "id": "1000327",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics22.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90103332"
            },
            {
              "id": "1000328",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics23.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89070526"
            },
            {
              "id": "1000329",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics24.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88833933"
            },
            {
              "id": "1000330",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics25.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88629613"
            },
            {
              "id": "1000331",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics26.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89955141"
            },
            {
              "id": "1000332",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics27.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87602527"
            },
            {
              "id": "1000333",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics28.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87223968"
            },
            {
              "id": "1000334",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics29.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91234265"
            },
            {
              "id": "1000335",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics30.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88656339"
            },
            {
              "id": "1000336",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics31.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87543801"
            },
            {
              "id": "1000337",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics32.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90171242"
            },
            {
              "id": "1000338",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics33.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90373877"
            },
            {
              "id": "1000339",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics34.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88401905"
            },
            {
              "id": "1000340",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics35.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90360072"
            },
            {
              "id": "1000341",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics36.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87428245"
            },
            {
              "id": "1000342",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics37.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89308060"
            },
            {
              "id": "1000343",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics38.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87190663"
            },
            {
              "id": "1000344",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics39.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90888061"
            },
            {
              "id": "1000345",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics40.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87726000"
            },
            {
              "id": "1000346",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics41.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89357765"
            },
            {
              "id": "1000347",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics42.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90881067"
            },
            {
              "id": "1000348",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics43.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87680833"
            },
            {
              "id": "1000349",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics44.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88997356"
            },
            {
              "id": "1000350",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics45.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89142554"
            },
            {
              "id": "1000351",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics46.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87470971"
            },
            {
              "id": "1000352",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics47.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89420373"
            },
            {
              "id": "1000353",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics48.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88179373"
            },
            {
              "id": "1000354",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics49.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87823135"
            },
            {
              "id": "1000355",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics50.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89714971"
            },
            {
              "id": "1000356",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics51.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90160862"
            },
            {
              "id": "1000357",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics52.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88925563"
            },
            {
              "id": "1000358",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics53.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90244591"
            },
            {
              "id": "1000359",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics54.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91036957"
            },
            {
              "id": "1000360",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics55.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89663328"
            },
            {
              "id": "1000361",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics56.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91075569"
            },
            {
              "id": "1000362",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics57.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89509371"
            },
            {
              "id": "1000363",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics58.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90793513"
            },
            {
              "id": "1000364",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics59.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89936970"
            },
            {
              "id": "1000365",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics60.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91638767"
            },
            {
              "id": "1000366",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics61.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87314839"
            },
            {
              "id": "1000367",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics62.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89915757"
            },
            {
              "id": "1000368",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics63.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87648707"
            },
            {
              "id": "1000369",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics64.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90097142"
            },
            {
              "id": "1000370",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics65.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89205671"
            },
            {
              "id": "1000371",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics66.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87578361"
            },
            {
              "id": "1000372",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics67.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91016579"
            },
            {
              "id": "1000373",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics68.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88381712"
            },
            {
              "id": "1000374",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics69.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88259654"
            },
            {
              "id": "1000375",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics70.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90599354"
            },
            {
              "id": "1000376",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics71.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91311198"
            },
            {
              "id": "1000377",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics72.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87614039"
            },
            {
              "id": "1000378",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics73.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88375890"
            },
            {
              "id": "1000379",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics74.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87357449"
            },
            {
              "id": "1000380",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics75.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91717464"
            },
            {
              "id": "1000381",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics76.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88521211"
            },
            {
              "id": "1000382",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics77.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88632220"
            },
            {
              "id": "1000383",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics78.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89708503"
            },
            {
              "id": "1000384",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics79.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91551564"
            },
            {
              "id": "1000385",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics80.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87522727"
            },
            {
              "id": "1000386",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics81.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88959352"
            },
            {
              "id": "1000387",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics82.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90933033"
            },
            {
              "id": "1000388",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics83.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90976364"
            },
            {
              "id": "1000389",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics84.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88387651"
            },
            {
              "id": "1000390",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics85.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88388233"
            },
            {
              "id": "1000391",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics86.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91519051"
            },
            {
              "id": "1000392",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics87.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87152151"
            },
            {
              "id": "1000393",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics88.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88768347"
            },
            {
              "id": "1000394",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics89.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87427593"
            },
            {
              "id": "1000395",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics90.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88918023"
            },
            {
              "id": "1000396",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics91.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90526035"
            },
            {
              "id": "1000397",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics92.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90851961"
            },
            {
              "id": "1000398",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics93.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87882872"
            },
            {
              "id": "1000399",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics94.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89170224"
            },
            {
              "id": "1000400",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics95.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88371720"
            },
            {
              "id": "1000401",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics96.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88210648"
            },
            {
              "id": "1000402",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics97.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87938972"
            },
            {
              "id": "1000403",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics98.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88689196"
            },
            {
              "id": "1000404",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics99.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89217814"
            },
            {
              "id": "1000405",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics100.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90202656"
            },
            {
              "id": "1000406",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics101.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.89813944"
            },
            {
              "id": "1000407",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics102.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91256148"
            },
            {
              "id": "1000408",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics103.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.91462049"
            },
            {
              "id": "1000409",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics104.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90421022"
            },
            {
              "id": "1000410",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics105.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90761377"
            },
            {
              "id": "1000411",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics106.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.90046702"
            },
            {
              "id": "1000412",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics107.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.87731348"
            },
            {
              "id": "1000413",
              "create_at": "                   ",
              "image": "/detect/electronics/electronics108.png",
              "status": "탐지완료",
              "classification_type": "가전제품",
              "content": "가전제품",
              "comment": "평균 탐지율 : 0.88104591"
            }
          ])
        } else if(inputText == '쓰레기') {
          console.log("쓰레기 검색")
          setNewsList([
            {
              "id": "1000841",
              "create_at": "                   ",
              "image": "/detect/trash/trash1.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87424779"
            },
            {
              "id": "1000842",
              "create_at": "                   ",
              "image": "/detect/trash/trash2.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89626903"
            },
            {
              "id": "1000843",
              "create_at": "                   ",
              "image": "/detect/trash/trash3.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90142049"
            },
            {
              "id": "1000844",
              "create_at": "                   ",
              "image": "/detect/trash/trash4.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90659410"
            },
            {
              "id": "1000845",
              "create_at": "                   ",
              "image": "/detect/trash/trash5.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89551933"
            },
            {
              "id": "1000846",
              "create_at": "                   ",
              "image": "/detect/trash/trash6.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87053374"
            },
            {
              "id": "1000847",
              "create_at": "                   ",
              "image": "/detect/trash/trash7.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88006605"
            },
            {
              "id": "1000848",
              "create_at": "                   ",
              "image": "/detect/trash/trash8.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91174630"
            },
            {
              "id": "1000849",
              "create_at": "                   ",
              "image": "/detect/trash/trash9.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90339669"
            },
            {
              "id": "1000850",
              "create_at": "                   ",
              "image": "/detect/trash/trash10.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91293174"
            },
            {
              "id": "1000851",
              "create_at": "                   ",
              "image": "/detect/trash/trash11.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89758259"
            },
            {
              "id": "1000852",
              "create_at": "                   ",
              "image": "/detect/trash/trash12.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88907307"
            },
            {
              "id": "1000853",
              "create_at": "                   ",
              "image": "/detect/trash/trash13.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90649522"
            },
            {
              "id": "1000854",
              "create_at": "                   ",
              "image": "/detect/trash/trash14.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89165220"
            },
            {
              "id": "1000855",
              "create_at": "                   ",
              "image": "/detect/trash/trash15.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91284790"
            },
            {
              "id": "1000856",
              "create_at": "                   ",
              "image": "/detect/trash/trash16.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90861257"
            },
            {
              "id": "1000857",
              "create_at": "                   ",
              "image": "/detect/trash/trash17.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91008192"
            },
            {
              "id": "1000858",
              "create_at": "                   ",
              "image": "/detect/trash/trash18.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91309670"
            },
            {
              "id": "1000859",
              "create_at": "                   ",
              "image": "/detect/trash/trash19.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90000598"
            },
            {
              "id": "1000860",
              "create_at": "                   ",
              "image": "/detect/trash/trash20.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88502816"
            },
            {
              "id": "1000861",
              "create_at": "                   ",
              "image": "/detect/trash/trash21.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89087934"
            },
            {
              "id": "1000862",
              "create_at": "                   ",
              "image": "/detect/trash/trash22.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90395184"
            },
            {
              "id": "1000863",
              "create_at": "                   ",
              "image": "/detect/trash/trash23.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91351172"
            },
            {
              "id": "1000864",
              "create_at": "                   ",
              "image": "/detect/trash/trash24.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91008539"
            },
            {
              "id": "1000865",
              "create_at": "                   ",
              "image": "/detect/trash/trash25.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87160428"
            },
            {
              "id": "1000866",
              "create_at": "                   ",
              "image": "/detect/trash/trash26.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88380489"
            },
            {
              "id": "1000867",
              "create_at": "                   ",
              "image": "/detect/trash/trash27.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87834124"
            },
            {
              "id": "1000868",
              "create_at": "                   ",
              "image": "/detect/trash/trash28.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87653957"
            },
            {
              "id": "1000869",
              "create_at": "                   ",
              "image": "/detect/trash/trash29.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87673047"
            },
            {
              "id": "1000870",
              "create_at": "                   ",
              "image": "/detect/trash/trash30.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91093444"
            },
            {
              "id": "1000871",
              "create_at": "                   ",
              "image": "/detect/trash/trash31.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89850257"
            },
            {
              "id": "1000872",
              "create_at": "                   ",
              "image": "/detect/trash/trash32.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87968062"
            },
            {
              "id": "1000873",
              "create_at": "                   ",
              "image": "/detect/trash/trash33.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91529018"
            },
            {
              "id": "1000874",
              "create_at": "                   ",
              "image": "/detect/trash/trash34.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91581095"
            },
            {
              "id": "1000875",
              "create_at": "                   ",
              "image": "/detect/trash/trash35.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87884276"
            },
            {
              "id": "1000876",
              "create_at": "                   ",
              "image": "/detect/trash/trash36.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90262328"
            },
            {
              "id": "1000877",
              "create_at": "                   ",
              "image": "/detect/trash/trash37.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87356452"
            },
            {
              "id": "1000878",
              "create_at": "                   ",
              "image": "/detect/trash/trash38.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87303463"
            },
            {
              "id": "1000879",
              "create_at": "                   ",
              "image": "/detect/trash/trash39.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88991241"
            },
            {
              "id": "1000880",
              "create_at": "                   ",
              "image": "/detect/trash/trash40.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87829571"
            },
            {
              "id": "1000881",
              "create_at": "                   ",
              "image": "/detect/trash/trash41.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87777048"
            },
            {
              "id": "1000882",
              "create_at": "                   ",
              "image": "/detect/trash/trash42.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89725329"
            },
            {
              "id": "1000883",
              "create_at": "                   ",
              "image": "/detect/trash/trash43.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89158734"
            },
            {
              "id": "1000884",
              "create_at": "                   ",
              "image": "/detect/trash/trash44.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90134635"
            },
            {
              "id": "1000885",
              "create_at": "                   ",
              "image": "/detect/trash/trash45.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89300323"
            },
            {
              "id": "1000886",
              "create_at": "                   ",
              "image": "/detect/trash/trash46.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90629145"
            },
            {
              "id": "1000887",
              "create_at": "                   ",
              "image": "/detect/trash/trash47.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89515178"
            },
            {
              "id": "1000888",
              "create_at": "                   ",
              "image": "/detect/trash/trash48.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90612640"
            },
            {
              "id": "1000889",
              "create_at": "                   ",
              "image": "/detect/trash/trash49.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90257185"
            },
            {
              "id": "1000890",
              "create_at": "                   ",
              "image": "/detect/trash/trash50.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89623106"
            },
            {
              "id": "1000891",
              "create_at": "                   ",
              "image": "/detect/trash/trash51.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89246188"
            },
            {
              "id": "1000892",
              "create_at": "                   ",
              "image": "/detect/trash/trash52.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88200156"
            },
            {
              "id": "1000893",
              "create_at": "                   ",
              "image": "/detect/trash/trash53.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90662743"
            },
            {
              "id": "1000894",
              "create_at": "                   ",
              "image": "/detect/trash/trash54.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88734395"
            },
            {
              "id": "1000895",
              "create_at": "                   ",
              "image": "/detect/trash/trash55.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91519859"
            },
            {
              "id": "1000896",
              "create_at": "                   ",
              "image": "/detect/trash/trash56.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89919098"
            },
            {
              "id": "1000897",
              "create_at": "                   ",
              "image": "/detect/trash/trash57.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89289028"
            },
            {
              "id": "1000898",
              "create_at": "                   ",
              "image": "/detect/trash/trash58.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89956581"
            },
            {
              "id": "1000899",
              "create_at": "                   ",
              "image": "/detect/trash/trash59.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88081277"
            },
            {
              "id": "1000900",
              "create_at": "                   ",
              "image": "/detect/trash/trash60.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91702294"
            },
            {
              "id": "1000901",
              "create_at": "                   ",
              "image": "/detect/trash/trash61.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88664369"
            },
            {
              "id": "1000902",
              "create_at": "                   ",
              "image": "/detect/trash/trash62.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87617313"
            },
            {
              "id": "1000903",
              "create_at": "                   ",
              "image": "/detect/trash/trash63.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87396690"
            },
            {
              "id": "1000904",
              "create_at": "                   ",
              "image": "/detect/trash/trash64.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91686434"
            },
            {
              "id": "1000905",
              "create_at": "                   ",
              "image": "/detect/trash/trash65.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87062400"
            },
            {
              "id": "1000906",
              "create_at": "                   ",
              "image": "/detect/trash/trash66.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90386990"
            },
            {
              "id": "1000907",
              "create_at": "                   ",
              "image": "/detect/trash/trash67.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88081509"
            },
            {
              "id": "1000908",
              "create_at": "                   ",
              "image": "/detect/trash/trash68.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88207536"
            },
            {
              "id": "1000909",
              "create_at": "                   ",
              "image": "/detect/trash/trash69.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87236264"
            },
            {
              "id": "1000910",
              "create_at": "                   ",
              "image": "/detect/trash/trash70.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87190108"
            },
            {
              "id": "1000911",
              "create_at": "                   ",
              "image": "/detect/trash/trash71.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90697134"
            },
            {
              "id": "1000912",
              "create_at": "                   ",
              "image": "/detect/trash/trash72.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91090537"
            },
            {
              "id": "1000913",
              "create_at": "                   ",
              "image": "/detect/trash/trash73.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91619543"
            },
            {
              "id": "1000914",
              "create_at": "                   ",
              "image": "/detect/trash/trash74.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90733479"
            },
            {
              "id": "1000915",
              "create_at": "                   ",
              "image": "/detect/trash/trash75.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89517755"
            },
            {
              "id": "1000916",
              "create_at": "                   ",
              "image": "/detect/trash/trash76.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89587269"
            },
            {
              "id": "1000917",
              "create_at": "                   ",
              "image": "/detect/trash/trash77.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89735103"
            },
            {
              "id": "1000918",
              "create_at": "                   ",
              "image": "/detect/trash/trash78.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87864562"
            },
            {
              "id": "1000919",
              "create_at": "                   ",
              "image": "/detect/trash/trash79.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91729710"
            },
            {
              "id": "1000920",
              "create_at": "                   ",
              "image": "/detect/trash/trash80.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88207980"
            },
            {
              "id": "1000921",
              "create_at": "                   ",
              "image": "/detect/trash/trash81.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88699529"
            },
            {
              "id": "1000922",
              "create_at": "                   ",
              "image": "/detect/trash/trash82.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90040498"
            },
            {
              "id": "1000923",
              "create_at": "                   ",
              "image": "/detect/trash/trash83.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87942386"
            },
            {
              "id": "1000924",
              "create_at": "                   ",
              "image": "/detect/trash/trash84.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91298036"
            },
            {
              "id": "1000925",
              "create_at": "                   ",
              "image": "/detect/trash/trash85.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91664734"
            },
            {
              "id": "1000926",
              "create_at": "                   ",
              "image": "/detect/trash/trash86.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88491392"
            },
            {
              "id": "1000927",
              "create_at": "                   ",
              "image": "/detect/trash/trash87.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90091950"
            },
            {
              "id": "1000928",
              "create_at": "                   ",
              "image": "/detect/trash/trash88.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91137563"
            },
            {
              "id": "1000929",
              "create_at": "                   ",
              "image": "/detect/trash/trash89.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88278502"
            },
            {
              "id": "1000930",
              "create_at": "                   ",
              "image": "/detect/trash/trash90.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90792651"
            },
            {
              "id": "1000931",
              "create_at": "                   ",
              "image": "/detect/trash/trash91.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91522100"
            },
            {
              "id": "1000932",
              "create_at": "                   ",
              "image": "/detect/trash/trash92.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90784784"
            },
            {
              "id": "1000933",
              "create_at": "                   ",
              "image": "/detect/trash/trash93.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88320762"
            },
            {
              "id": "1000934",
              "create_at": "                   ",
              "image": "/detect/trash/trash94.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91081238"
            },
            {
              "id": "1000935",
              "create_at": "                   ",
              "image": "/detect/trash/trash95.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87782903"
            },
            {
              "id": "1000936",
              "create_at": "                   ",
              "image": "/detect/trash/trash96.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88546591"
            },
            {
              "id": "1000937",
              "create_at": "                   ",
              "image": "/detect/trash/trash97.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87837385"
            },
            {
              "id": "1000938",
              "create_at": "                   ",
              "image": "/detect/trash/trash98.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91396918"
            },
            {
              "id": "1000939",
              "create_at": "                   ",
              "image": "/detect/trash/trash99.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.91611863"
            },
            {
              "id": "1000940",
              "create_at": "                   ",
              "image": "/detect/trash/trash100.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88395535"
            },
            {
              "id": "1000941",
              "create_at": "                   ",
              "image": "/detect/trash/trash101.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90202330"
            },
            {
              "id": "1000942",
              "create_at": "                   ",
              "image": "/detect/trash/trash102.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89411728"
            },
            {
              "id": "1000943",
              "create_at": "                   ",
              "image": "/detect/trash/trash103.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90143132"
            },
            {
              "id": "1000944",
              "create_at": "                   ",
              "image": "/detect/trash/trash104.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.87026808"
            },
            {
              "id": "1000945",
              "create_at": "                   ",
              "image": "/detect/trash/trash105.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90733040"
            },
            {
              "id": "1000946",
              "create_at": "                   ",
              "image": "/detect/trash/trash106.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90390134"
            },
            {
              "id": "1000947",
              "create_at": "                   ",
              "image": "/detect/trash/trash107.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.88169796"
            },
            {
              "id": "1000948",
              "create_at": "                   ",
              "image": "/detect/trash/trash108.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.90429458"
            },
            {
              "id": "1000949",
              "create_at": "                   ",
              "image": "/detect/trash/trash109.png",
              "status": "탐지완료",
              "classification_type": "방치 쓰레기",
              "content": "방치 쓰레기",
              "comment": "평균 탐지율 : 0.89854201"
            },
            {
              "id": "1001053",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin1.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90826969"
            },
            {
              "id": "1001054",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin2.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89195223"
            },
            {
              "id": "1001055",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin3.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91068084"
            },
            {
              "id": "1001056",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin4.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88171159"
            },
            {
              "id": "1001057",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin5.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87544274"
            },
            {
              "id": "1001058",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin6.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90544237"
            },
            {
              "id": "1001059",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin7.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90216909"
            },
            {
              "id": "1001060",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin8.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87537020"
            },
            {
              "id": "1001061",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin9.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87492976"
            },
            {
              "id": "1001062",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin10.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87648682"
            },
            {
              "id": "1001063",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin11.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88076291"
            },
            {
              "id": "1001064",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin12.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88141593"
            },
            {
              "id": "1001065",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin13.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87938037"
            },
            {
              "id": "1001066",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin14.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88527518"
            },
            {
              "id": "1001067",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin15.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87891125"
            },
            {
              "id": "1001068",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin16.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89027521"
            },
            {
              "id": "1001069",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin17.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88332898"
            },
            {
              "id": "1001070",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin18.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87557713"
            },
            {
              "id": "1001071",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin19.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88924492"
            },
            {
              "id": "1001072",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin20.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87483891"
            },
            {
              "id": "1001073",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin21.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90872712"
            },
            {
              "id": "1001074",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin22.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87459012"
            },
            {
              "id": "1001075",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin23.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90915302"
            },
            {
              "id": "1001076",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin24.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88900951"
            },
            {
              "id": "1001077",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin25.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90704947"
            },
            {
              "id": "1001078",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin26.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88220305"
            },
            {
              "id": "1001079",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin27.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89433947"
            },
            {
              "id": "1001080",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin28.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88879535"
            },
            {
              "id": "1001081",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin29.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88642550"
            },
            {
              "id": "1001082",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin30.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88912303"
            },
            {
              "id": "1001083",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin31.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89761731"
            },
            {
              "id": "1001084",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin32.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91515671"
            },
            {
              "id": "1001085",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin33.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91150459"
            },
            {
              "id": "1001086",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin34.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90551206"
            },
            {
              "id": "1001087",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin35.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90099244"
            },
            {
              "id": "1001088",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin36.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90186074"
            },
            {
              "id": "1001089",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin37.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88868363"
            },
            {
              "id": "1001090",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin38.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88514299"
            },
            {
              "id": "1001091",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin39.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90979286"
            },
            {
              "id": "1001092",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin40.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88842282"
            },
            {
              "id": "1001093",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin41.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91022313"
            },
            {
              "id": "1001094",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin42.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88227590"
            },
            {
              "id": "1001095",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin43.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88513354"
            },
            {
              "id": "1001096",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin44.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87715230"
            },
            {
              "id": "1001097",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin45.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90533981"
            },
            {
              "id": "1001098",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin46.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87083328"
            },
            {
              "id": "1001099",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin47.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90304570"
            },
            {
              "id": "1001100",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin48.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88493126"
            },
            {
              "id": "1001101",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin49.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87652567"
            },
            {
              "id": "1001102",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin50.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91196819"
            },
            {
              "id": "1001103",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin51.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90236397"
            },
            {
              "id": "1001104",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin52.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90223156"
            },
            {
              "id": "1001105",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin53.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88692054"
            },
            {
              "id": "1001106",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin54.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89582572"
            },
            {
              "id": "1001107",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin55.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88008819"
            },
            {
              "id": "1001108",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin56.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90875808"
            },
            {
              "id": "1001109",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin57.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90628262"
            },
            {
              "id": "1001110",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin58.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88318989"
            },
            {
              "id": "1001111",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin59.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90772549"
            },
            {
              "id": "1001112",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin60.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89136848"
            },
            {
              "id": "1001113",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin61.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91270550"
            },
            {
              "id": "1001114",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin62.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87715721"
            },
            {
              "id": "1001115",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin63.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89855552"
            },
            {
              "id": "1001116",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin64.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90758177"
            },
            {
              "id": "1001117",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin65.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87899614"
            },
            {
              "id": "1001118",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin66.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89684340"
            },
            {
              "id": "1001119",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin67.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89100977"
            },
            {
              "id": "1001120",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin68.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87337317"
            },
            {
              "id": "1001121",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin69.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91015403"
            },
            {
              "id": "1001122",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin70.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87376048"
            },
            {
              "id": "1001123",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin71.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91586057"
            },
            {
              "id": "1001124",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin72.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89554272"
            },
            {
              "id": "1001125",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin73.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90341454"
            },
            {
              "id": "1001126",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin74.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90695466"
            },
            {
              "id": "1001127",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin75.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90958782"
            },
            {
              "id": "1001128",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin76.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88186611"
            },
            {
              "id": "1001129",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin77.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88290464"
            },
            {
              "id": "1001130",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin78.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90462321"
            },
            {
              "id": "1001131",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin79.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89521263"
            },
            {
              "id": "1001132",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin80.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88387596"
            },
            {
              "id": "1001133",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin81.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91283633"
            },
            {
              "id": "1001134",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin82.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90446675"
            },
            {
              "id": "1001135",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin83.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88350568"
            },
            {
              "id": "1001136",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin84.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91652879"
            },
            {
              "id": "1001137",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin85.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88537957"
            },
            {
              "id": "1001138",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin86.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88070148"
            },
            {
              "id": "1001139",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin87.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91695834"
            },
            {
              "id": "1001140",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin88.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91564931"
            },
            {
              "id": "1001141",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin89.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90191878"
            },
            {
              "id": "1001142",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin90.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90563461"
            },
            {
              "id": "1001143",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin91.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87529384"
            },
            {
              "id": "1001144",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin92.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88054809"
            },
            {
              "id": "1001145",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin93.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87439199"
            },
            {
              "id": "1001146",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin94.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90149910"
            },
            {
              "id": "1001147",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin95.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90987063"
            },
            {
              "id": "1001148",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin96.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87809873"
            },
            {
              "id": "1001149",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin97.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89839975"
            },
            {
              "id": "1001150",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin98.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89116379"
            },
            {
              "id": "1001151",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin99.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89019161"
            },
            {
              "id": "1001152",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin100.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87806718"
            },
            {
              "id": "1001153",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin101.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87977512"
            },
            {
              "id": "1001154",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin102.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90116619"
            }
          ])
        } else if(inputText == '광고판') {
          console.log("광고판 검색")
          setNewsList([
            {
              "id": "1000000",
              "create_at": "                   ",
              "image": "/detect/ad/ad1.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89118225"
            },
            {
              "id": "1000001",
              "create_at": "                   ",
              "image": "/detect/ad/ad2.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87695745"
            },
            {
              "id": "1000002",
              "create_at": "                   ",
              "image": "/detect/ad/ad3.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89315093"
            },
            {
              "id": "1000003",
              "create_at": "                   ",
              "image": "/detect/ad/ad4.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88044197"
            },
            {
              "id": "1000004",
              "create_at": "                   ",
              "image": "/detect/ad/ad5.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88359553"
            },
            {
              "id": "1000005",
              "create_at": "                   ",
              "image": "/detect/ad/ad6.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91173375"
            },
            {
              "id": "1000006",
              "create_at": "                   ",
              "image": "/detect/ad/ad7.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89463159"
            },
            {
              "id": "1000007",
              "create_at": "                   ",
              "image": "/detect/ad/ad8.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88704573"
            },
            {
              "id": "1000008",
              "create_at": "                   ",
              "image": "/detect/ad/ad9.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88174752"
            },
            {
              "id": "1000009",
              "create_at": "                   ",
              "image": "/detect/ad/ad10.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88757919"
            },
            {
              "id": "1000010",
              "create_at": "                   ",
              "image": "/detect/ad/ad11.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89604176"
            },
            {
              "id": "1000011",
              "create_at": "                   ",
              "image": "/detect/ad/ad12.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87391336"
            },
            {
              "id": "1000012",
              "create_at": "                   ",
              "image": "/detect/ad/ad13.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89105611"
            },
            {
              "id": "1000013",
              "create_at": "                   ",
              "image": "/detect/ad/ad14.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90440521"
            },
            {
              "id": "1000014",
              "create_at": "                   ",
              "image": "/detect/ad/ad15.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88983339"
            },
            {
              "id": "1000015",
              "create_at": "                   ",
              "image": "/detect/ad/ad16.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90690849"
            },
            {
              "id": "1000016",
              "create_at": "                   ",
              "image": "/detect/ad/ad17.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88633239"
            },
            {
              "id": "1000017",
              "create_at": "                   ",
              "image": "/detect/ad/ad18.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90502614"
            },
            {
              "id": "1000018",
              "create_at": "                   ",
              "image": "/detect/ad/ad19.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89885278"
            },
            {
              "id": "1000019",
              "create_at": "                   ",
              "image": "/detect/ad/ad20.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90193845"
            },
            {
              "id": "1000020",
              "create_at": "                   ",
              "image": "/detect/ad/ad21.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88513917"
            },
            {
              "id": "1000021",
              "create_at": "                   ",
              "image": "/detect/ad/ad22.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88394189"
            },
            {
              "id": "1000022",
              "create_at": "                   ",
              "image": "/detect/ad/ad23.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87996189"
            },
            {
              "id": "1000023",
              "create_at": "                   ",
              "image": "/detect/ad/ad24.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90665444"
            },
            {
              "id": "1000024",
              "create_at": "                   ",
              "image": "/detect/ad/ad25.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91572354"
            },
            {
              "id": "1000025",
              "create_at": "                   ",
              "image": "/detect/ad/ad26.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89125447"
            },
            {
              "id": "1000026",
              "create_at": "                   ",
              "image": "/detect/ad/ad27.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88750844"
            },
            {
              "id": "1000027",
              "create_at": "                   ",
              "image": "/detect/ad/ad28.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87989409"
            },
            {
              "id": "1000028",
              "create_at": "                   ",
              "image": "/detect/ad/ad29.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89654273"
            },
            {
              "id": "1000029",
              "create_at": "                   ",
              "image": "/detect/ad/ad30.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91730013"
            },
            {
              "id": "1000030",
              "create_at": "                   ",
              "image": "/detect/ad/ad31.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90041804"
            },
            {
              "id": "1000031",
              "create_at": "                   ",
              "image": "/detect/ad/ad32.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87248076"
            },
            {
              "id": "1000032",
              "create_at": "                   ",
              "image": "/detect/ad/ad33.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89520642"
            },
            {
              "id": "1000033",
              "create_at": "                   ",
              "image": "/detect/ad/ad34.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87089627"
            },
            {
              "id": "1000034",
              "create_at": "                   ",
              "image": "/detect/ad/ad35.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87495193"
            },
            {
              "id": "1000035",
              "create_at": "                   ",
              "image": "/detect/ad/ad36.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89608851"
            },
            {
              "id": "1000036",
              "create_at": "                   ",
              "image": "/detect/ad/ad37.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87981321"
            },
            {
              "id": "1000037",
              "create_at": "                   ",
              "image": "/detect/ad/ad38.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90280296"
            },
            {
              "id": "1000038",
              "create_at": "                   ",
              "image": "/detect/ad/ad39.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88447190"
            },
            {
              "id": "1000039",
              "create_at": "                   ",
              "image": "/detect/ad/ad40.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90178940"
            },
            {
              "id": "1000040",
              "create_at": "                   ",
              "image": "/detect/ad/ad41.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87775984"
            },
            {
              "id": "1000041",
              "create_at": "                   ",
              "image": "/detect/ad/ad42.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90956783"
            },
            {
              "id": "1000042",
              "create_at": "                   ",
              "image": "/detect/ad/ad43.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88375916"
            },
            {
              "id": "1000043",
              "create_at": "                   ",
              "image": "/detect/ad/ad44.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89023566"
            },
            {
              "id": "1000044",
              "create_at": "                   ",
              "image": "/detect/ad/ad45.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88763222"
            },
            {
              "id": "1000045",
              "create_at": "                   ",
              "image": "/detect/ad/ad46.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90997532"
            },
            {
              "id": "1000046",
              "create_at": "                   ",
              "image": "/detect/ad/ad47.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88245253"
            },
            {
              "id": "1000047",
              "create_at": "                   ",
              "image": "/detect/ad/ad48.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87049034"
            },
            {
              "id": "1000048",
              "create_at": "                   ",
              "image": "/detect/ad/ad49.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90542125"
            },
            {
              "id": "1000049",
              "create_at": "                   ",
              "image": "/detect/ad/ad50.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88470132"
            },
            {
              "id": "1000050",
              "create_at": "                   ",
              "image": "/detect/ad/ad51.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88031064"
            },
            {
              "id": "1000051",
              "create_at": "                   ",
              "image": "/detect/ad/ad52.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87989022"
            },
            {
              "id": "1000052",
              "create_at": "                   ",
              "image": "/detect/ad/ad53.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87455477"
            },
            {
              "id": "1000053",
              "create_at": "                   ",
              "image": "/detect/ad/ad54.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89562363"
            },
            {
              "id": "1000054",
              "create_at": "                   ",
              "image": "/detect/ad/ad55.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89604557"
            },
            {
              "id": "1000055",
              "create_at": "                   ",
              "image": "/detect/ad/ad56.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90195906"
            },
            {
              "id": "1000056",
              "create_at": "                   ",
              "image": "/detect/ad/ad57.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91191036"
            },
            {
              "id": "1000057",
              "create_at": "                   ",
              "image": "/detect/ad/ad58.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88589576"
            },
            {
              "id": "1000058",
              "create_at": "                   ",
              "image": "/detect/ad/ad59.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88197240"
            },
            {
              "id": "1000059",
              "create_at": "                   ",
              "image": "/detect/ad/ad60.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88643764"
            },
            {
              "id": "1000060",
              "create_at": "                   ",
              "image": "/detect/ad/ad61.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91511654"
            },
            {
              "id": "1000061",
              "create_at": "                   ",
              "image": "/detect/ad/ad62.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87787244"
            },
            {
              "id": "1000062",
              "create_at": "                   ",
              "image": "/detect/ad/ad63.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90552767"
            },
            {
              "id": "1000063",
              "create_at": "                   ",
              "image": "/detect/ad/ad64.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89873935"
            },
            {
              "id": "1000064",
              "create_at": "                   ",
              "image": "/detect/ad/ad65.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88467103"
            },
            {
              "id": "1000065",
              "create_at": "                   ",
              "image": "/detect/ad/ad66.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88015334"
            },
            {
              "id": "1000066",
              "create_at": "                   ",
              "image": "/detect/ad/ad67.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87417511"
            },
            {
              "id": "1000067",
              "create_at": "                   ",
              "image": "/detect/ad/ad68.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89755544"
            },
            {
              "id": "1000068",
              "create_at": "                   ",
              "image": "/detect/ad/ad69.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91577749"
            },
            {
              "id": "1000069",
              "create_at": "                   ",
              "image": "/detect/ad/ad70.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87719171"
            },
            {
              "id": "1000070",
              "create_at": "                   ",
              "image": "/detect/ad/ad71.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89983020"
            },
            {
              "id": "1000071",
              "create_at": "                   ",
              "image": "/detect/ad/ad72.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87933523"
            },
            {
              "id": "1000072",
              "create_at": "                   ",
              "image": "/detect/ad/ad73.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88971077"
            },
            {
              "id": "1000073",
              "create_at": "                   ",
              "image": "/detect/ad/ad74.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89897145"
            },
            {
              "id": "1000074",
              "create_at": "                   ",
              "image": "/detect/ad/ad75.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90935236"
            },
            {
              "id": "1000075",
              "create_at": "                   ",
              "image": "/detect/ad/ad76.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87103067"
            },
            {
              "id": "1000076",
              "create_at": "                   ",
              "image": "/detect/ad/ad77.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91435115"
            },
            {
              "id": "1000077",
              "create_at": "                   ",
              "image": "/detect/ad/ad78.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90964669"
            },
            {
              "id": "1000078",
              "create_at": "                   ",
              "image": "/detect/ad/ad79.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90579782"
            },
            {
              "id": "1000079",
              "create_at": "                   ",
              "image": "/detect/ad/ad80.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91106902"
            },
            {
              "id": "1000080",
              "create_at": "                   ",
              "image": "/detect/ad/ad81.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87134815"
            },
            {
              "id": "1000081",
              "create_at": "                   ",
              "image": "/detect/ad/ad82.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91052331"
            },
            {
              "id": "1000082",
              "create_at": "                   ",
              "image": "/detect/ad/ad83.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88354337"
            },
            {
              "id": "1000083",
              "create_at": "                   ",
              "image": "/detect/ad/ad84.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89434876"
            },
            {
              "id": "1000084",
              "create_at": "                   ",
              "image": "/detect/ad/ad85.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88453758"
            },
            {
              "id": "1000085",
              "create_at": "                   ",
              "image": "/detect/ad/ad86.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88547568"
            },
            {
              "id": "1000086",
              "create_at": "                   ",
              "image": "/detect/ad/ad87.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88439437"
            },
            {
              "id": "1000087",
              "create_at": "                   ",
              "image": "/detect/ad/ad88.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90797192"
            },
            {
              "id": "1000088",
              "create_at": "                   ",
              "image": "/detect/ad/ad89.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87031248"
            },
            {
              "id": "1000089",
              "create_at": "                   ",
              "image": "/detect/ad/ad90.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90656088"
            },
            {
              "id": "1000090",
              "create_at": "                   ",
              "image": "/detect/ad/ad91.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87251489"
            },
            {
              "id": "1000091",
              "create_at": "                   ",
              "image": "/detect/ad/ad92.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.89277237"
            },
            {
              "id": "1000092",
              "create_at": "                   ",
              "image": "/detect/ad/ad93.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90972141"
            },
            {
              "id": "1000093",
              "create_at": "                   ",
              "image": "/detect/ad/ad94.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87936256"
            },
            {
              "id": "1000094",
              "create_at": "                   ",
              "image": "/detect/ad/ad95.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87373591"
            },
            {
              "id": "1000095",
              "create_at": "                   ",
              "image": "/detect/ad/ad96.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90791320"
            },
            {
              "id": "1000096",
              "create_at": "                   ",
              "image": "/detect/ad/ad97.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91533719"
            },
            {
              "id": "1000097",
              "create_at": "                   ",
              "image": "/detect/ad/ad98.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87997290"
            },
            {
              "id": "1000098",
              "create_at": "                   ",
              "image": "/detect/ad/ad99.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.87345496"
            },
            {
              "id": "1000099",
              "create_at": "                   ",
              "image": "/detect/ad/ad100.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.88576116"
            },
            {
              "id": "1000100",
              "create_at": "                   ",
              "image": "/detect/ad/ad101.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.90426583"
            },
            {
              "id": "1000101",
              "create_at": "                   ",
              "image": "/detect/ad/ad102.png",
              "status": "탐지완료",
              "classification_type": "광고판",
              "content": "광고판",
              "comment": "평균 탐지율 : 0.91124534"
            }
          ])
        } else if(inputText == '전동킥보드') {
          console.log("전동킥보드 검색")
          setNewsList([
            {
              "id": "1000414",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard1.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90720420"
            },
            {
              "id": "1000415",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard2.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87745536"
            },
            {
              "id": "1000416",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard3.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91031997"
            },
            {
              "id": "1000417",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard4.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88375212"
            },
            {
              "id": "1000418",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard5.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90809675"
            },
            {
              "id": "1000419",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard6.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89142422"
            },
            {
              "id": "1000420",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard7.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87583862"
            },
            {
              "id": "1000421",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard8.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87350297"
            },
            {
              "id": "1000422",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard9.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91232494"
            },
            {
              "id": "1000423",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard10.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87610791"
            },
            {
              "id": "1000424",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard11.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89238398"
            },
            {
              "id": "1000425",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard12.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87712009"
            },
            {
              "id": "1000426",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard13.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90314068"
            },
            {
              "id": "1000427",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard14.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88677077"
            },
            {
              "id": "1000428",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard15.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88418127"
            },
            {
              "id": "1000429",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard16.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89002920"
            },
            {
              "id": "1000430",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard17.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87271244"
            },
            {
              "id": "1000431",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard18.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91314723"
            },
            {
              "id": "1000432",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard19.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91471972"
            },
            {
              "id": "1000433",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard20.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90684797"
            },
            {
              "id": "1000434",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard21.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90165709"
            },
            {
              "id": "1000435",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard22.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89718619"
            },
            {
              "id": "1000436",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard23.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87032365"
            },
            {
              "id": "1000437",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard24.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87151642"
            },
            {
              "id": "1000438",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard25.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91140293"
            },
            {
              "id": "1000439",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard26.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88652190"
            },
            {
              "id": "1000440",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard27.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87727570"
            },
            {
              "id": "1000441",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard28.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88276207"
            },
            {
              "id": "1000442",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard29.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88042819"
            },
            {
              "id": "1000443",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard30.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90509839"
            },
            {
              "id": "1000444",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard31.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89508993"
            },
            {
              "id": "1000445",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard32.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90155695"
            },
            {
              "id": "1000446",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard33.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89212496"
            },
            {
              "id": "1000447",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard34.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89591600"
            },
            {
              "id": "1000448",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard35.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91600285"
            },
            {
              "id": "1000449",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard36.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87653903"
            },
            {
              "id": "1000450",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard37.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91569900"
            },
            {
              "id": "1000451",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard38.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87922033"
            },
            {
              "id": "1000452",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard39.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88880125"
            },
            {
              "id": "1000453",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard40.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88851707"
            },
            {
              "id": "1000454",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard41.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87791235"
            },
            {
              "id": "1000455",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard42.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88759124"
            },
            {
              "id": "1000456",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard43.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87516104"
            },
            {
              "id": "1000457",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard44.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89156299"
            },
            {
              "id": "1000458",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard45.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88603535"
            },
            {
              "id": "1000459",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard46.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91597283"
            },
            {
              "id": "1000460",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard47.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89591986"
            },
            {
              "id": "1000461",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard48.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88405690"
            },
            {
              "id": "1000462",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard49.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89837021"
            },
            {
              "id": "1000463",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard50.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90122999"
            },
            {
              "id": "1000464",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard51.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87960044"
            },
            {
              "id": "1000465",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard52.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91280054"
            },
            {
              "id": "1000466",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard53.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90620416"
            },
            {
              "id": "1000467",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard54.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89624342"
            },
            {
              "id": "1000468",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard55.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89976793"
            },
            {
              "id": "1000469",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard56.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90323451"
            },
            {
              "id": "1000470",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard57.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87511065"
            },
            {
              "id": "1000471",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard58.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87481703"
            },
            {
              "id": "1000472",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard59.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89645869"
            },
            {
              "id": "1000473",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard60.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89876102"
            },
            {
              "id": "1000474",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard61.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88134659"
            },
            {
              "id": "1000475",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard62.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90900242"
            },
            {
              "id": "1000476",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard63.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87360649"
            },
            {
              "id": "1000477",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard64.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88495112"
            },
            {
              "id": "1000478",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard65.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87323117"
            },
            {
              "id": "1000479",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard66.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87163662"
            },
            {
              "id": "1000480",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard67.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90021692"
            },
            {
              "id": "1000481",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard68.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87637137"
            },
            {
              "id": "1000482",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard69.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91425044"
            },
            {
              "id": "1000483",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard70.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91490782"
            },
            {
              "id": "1000484",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard71.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90932398"
            },
            {
              "id": "1000485",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard72.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89060198"
            },
            {
              "id": "1000486",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard73.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90675100"
            },
            {
              "id": "1000487",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard74.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89344860"
            },
            {
              "id": "1000488",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard75.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88782999"
            },
            {
              "id": "1000489",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard76.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91451468"
            },
            {
              "id": "1000490",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard77.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90446414"
            },
            {
              "id": "1000491",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard78.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90179596"
            },
            {
              "id": "1000492",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard79.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90408890"
            },
            {
              "id": "1000493",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard80.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90476143"
            },
            {
              "id": "1000494",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard81.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90034285"
            },
            {
              "id": "1000495",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard82.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87158353"
            },
            {
              "id": "1000496",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard83.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88320893"
            },
            {
              "id": "1000497",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard84.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87523346"
            },
            {
              "id": "1000498",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard85.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88369951"
            },
            {
              "id": "1000499",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard86.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91668831"
            },
            {
              "id": "1000500",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard87.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91482445"
            },
            {
              "id": "1000501",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard88.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87282437"
            },
            {
              "id": "1000502",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard89.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89819634"
            },
            {
              "id": "1000503",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard90.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88919915"
            },
            {
              "id": "1000504",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard91.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90279455"
            },
            {
              "id": "1000505",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard92.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87366276"
            },
            {
              "id": "1000506",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard93.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91259376"
            },
            {
              "id": "1000507",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard94.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87290997"
            },
            {
              "id": "1000508",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard95.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90894076"
            },
            {
              "id": "1000509",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard96.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88816059"
            },
            {
              "id": "1000510",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard97.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89796663"
            },
            {
              "id": "1000511",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard98.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.91547786"
            },
            {
              "id": "1000512",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard99.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90379741"
            },
            {
              "id": "1000513",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard100.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88025466"
            },
            {
              "id": "1000514",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard101.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87987681"
            },
            {
              "id": "1000515",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard102.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.89672030"
            },
            {
              "id": "1000516",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard103.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.90232846"
            },
            {
              "id": "1000517",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard104.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.87313438"
            },
            {
              "id": "1000518",
              "create_at": "                   ",
              "image": "/detect/kickboard/kickboard105.png",
              "status": "탐지완료",
              "classification_type": "전동킥보드",
              "content": "전동킥보드",
              "comment": "평균 탐지율 : 0.88478864"
            }
          ])
        } else if(inputText == '럼블스트립') {
          console.log("럼블스트립 검색")
          setNewsList([
            {
              "id": "1000726",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble1.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87938060"
            },
            {
              "id": "1000727",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble2.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88415201"
            },
            {
              "id": "1000728",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble3.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89104247"
            },
            {
              "id": "1000729",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble4.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87643857"
            },
            {
              "id": "1000730",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble5.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90976835"
            },
            {
              "id": "1000731",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble6.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88793748"
            },
            {
              "id": "1000732",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble7.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88922605"
            },
            {
              "id": "1000733",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble8.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90322317"
            },
            {
              "id": "1000734",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble9.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90426421"
            },
            {
              "id": "1000735",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble10.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89477489"
            },
            {
              "id": "1000736",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble11.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89735345"
            },
            {
              "id": "1000737",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble12.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87738606"
            },
            {
              "id": "1000738",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble13.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90100424"
            },
            {
              "id": "1000739",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble14.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90048599"
            },
            {
              "id": "1000740",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble15.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89139047"
            },
            {
              "id": "1000741",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble16.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88717512"
            },
            {
              "id": "1000742",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble17.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91568330"
            },
            {
              "id": "1000743",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble18.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90553535"
            },
            {
              "id": "1000744",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble19.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89313266"
            },
            {
              "id": "1000745",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble20.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89612566"
            },
            {
              "id": "1000746",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble21.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88534395"
            },
            {
              "id": "1000747",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble22.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87834157"
            },
            {
              "id": "1000748",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble23.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90505384"
            },
            {
              "id": "1000749",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble24.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87992700"
            },
            {
              "id": "1000750",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble25.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87497145"
            },
            {
              "id": "1000751",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble26.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90272746"
            },
            {
              "id": "1000752",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble27.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90353884"
            },
            {
              "id": "1000753",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble28.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90762845"
            },
            {
              "id": "1000754",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble29.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90807305"
            },
            {
              "id": "1000755",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble30.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90537798"
            },
            {
              "id": "1000756",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble31.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89454089"
            },
            {
              "id": "1000757",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble32.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88585439"
            },
            {
              "id": "1000758",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble33.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87808639"
            },
            {
              "id": "1000759",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble34.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87330133"
            },
            {
              "id": "1000760",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble35.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87480135"
            },
            {
              "id": "1000761",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble36.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89988300"
            },
            {
              "id": "1000762",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble37.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90407415"
            },
            {
              "id": "1000763",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble38.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91342423"
            },
            {
              "id": "1000764",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble39.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87239799"
            },
            {
              "id": "1000765",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble40.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90243301"
            },
            {
              "id": "1000766",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble41.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91719986"
            },
            {
              "id": "1000767",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble42.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91489738"
            },
            {
              "id": "1000768",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble43.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91477686"
            },
            {
              "id": "1000769",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble44.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90731015"
            },
            {
              "id": "1000770",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble45.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91168813"
            },
            {
              "id": "1000771",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble46.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89514850"
            },
            {
              "id": "1000772",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble47.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90491451"
            },
            {
              "id": "1000773",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble48.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90827298"
            },
            {
              "id": "1000774",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble49.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90073349"
            },
            {
              "id": "1000775",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble50.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87817699"
            },
            {
              "id": "1000776",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble51.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89372398"
            },
            {
              "id": "1000777",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble52.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90061110"
            },
            {
              "id": "1000778",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble53.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90272281"
            },
            {
              "id": "1000779",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble54.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90399036"
            },
            {
              "id": "1000780",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble55.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87683734"
            },
            {
              "id": "1000781",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble56.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88446578"
            },
            {
              "id": "1000782",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble57.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88600837"
            },
            {
              "id": "1000783",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble58.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91570661"
            },
            {
              "id": "1000784",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble59.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89560456"
            },
            {
              "id": "1000785",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble60.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88558882"
            },
            {
              "id": "1000786",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble61.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89373841"
            },
            {
              "id": "1000787",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble62.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90868042"
            },
            {
              "id": "1000788",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble63.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89644063"
            },
            {
              "id": "1000789",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble64.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87396818"
            },
            {
              "id": "1000790",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble65.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90578772"
            },
            {
              "id": "1000791",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble66.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87580676"
            },
            {
              "id": "1000792",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble67.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88595744"
            },
            {
              "id": "1000793",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble68.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91140495"
            },
            {
              "id": "1000794",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble69.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89075871"
            },
            {
              "id": "1000795",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble70.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90461247"
            },
            {
              "id": "1000796",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble71.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90304648"
            },
            {
              "id": "1000797",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble72.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87804223"
            },
            {
              "id": "1000798",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble73.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91254525"
            },
            {
              "id": "1000799",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble74.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90560706"
            },
            {
              "id": "1000800",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble75.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90940340"
            },
            {
              "id": "1000801",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble76.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91703547"
            },
            {
              "id": "1000802",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble77.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91215451"
            },
            {
              "id": "1000803",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble78.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87650803"
            },
            {
              "id": "1000804",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble79.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90747552"
            },
            {
              "id": "1000805",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble80.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87007970"
            },
            {
              "id": "1000806",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble81.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89572098"
            },
            {
              "id": "1000807",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble82.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89241214"
            },
            {
              "id": "1000808",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble83.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88325363"
            },
            {
              "id": "1000809",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble84.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87494223"
            },
            {
              "id": "1000810",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble85.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87931340"
            },
            {
              "id": "1000811",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble86.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89914924"
            },
            {
              "id": "1000812",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble87.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87444808"
            },
            {
              "id": "1000813",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble88.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87802950"
            },
            {
              "id": "1000814",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble89.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87111615"
            },
            {
              "id": "1000815",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble90.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91108640"
            },
            {
              "id": "1000816",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble91.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87612882"
            },
            {
              "id": "1000817",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble92.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89403316"
            },
            {
              "id": "1000818",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble93.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87742555"
            },
            {
              "id": "1000819",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble94.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89256439"
            },
            {
              "id": "1000820",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble95.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88956820"
            },
            {
              "id": "1000821",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble96.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89620128"
            },
            {
              "id": "1000822",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble97.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90562958"
            },
            {
              "id": "1000823",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble98.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90313714"
            },
            {
              "id": "1000824",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble99.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88635937"
            },
            {
              "id": "1000825",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble100.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91691723"
            },
            {
              "id": "1000826",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble101.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88397558"
            },
            {
              "id": "1000827",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble102.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88982821"
            },
            {
              "id": "1000828",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble103.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90256438"
            },
            {
              "id": "1000829",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble104.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89593358"
            },
            {
              "id": "1000830",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble105.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87713160"
            },
            {
              "id": "1000831",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble106.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87783176"
            },
            {
              "id": "1000832",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble107.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91379116"
            },
            {
              "id": "1000833",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble108.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.87017129"
            },
            {
              "id": "1000834",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble109.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.90640817"
            },
            {
              "id": "1000835",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble110.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89920932"
            },
            {
              "id": "1000836",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble111.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88495463"
            },
            {
              "id": "1000837",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble112.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.91329085"
            },
            {
              "id": "1000838",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble113.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.89130054"
            },
            {
              "id": "1000839",
              "create_at": "                   ",
              "image": "/detect/rumble/rumble114.png",
              "status": "탐지완료",
              "classification_type": "럼블스트립",
              "content": "럼블스트립",
              "comment": "평균 탐지율 : 0.88138255"
            }
          ])
        } else if(inputText == '쓰레기통') {
          console.log("쓰레기통 검색")
          setNewsList([
            {
              "id": "1001053",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin1.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90826969"
            },
            {
              "id": "1001054",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin2.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89195223"
            },
            {
              "id": "1001055",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin3.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91068084"
            },
            {
              "id": "1001056",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin4.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88171159"
            },
            {
              "id": "1001057",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin5.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87544274"
            },
            {
              "id": "1001058",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin6.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90544237"
            },
            {
              "id": "1001059",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin7.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90216909"
            },
            {
              "id": "1001060",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin8.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87537020"
            },
            {
              "id": "1001061",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin9.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87492976"
            },
            {
              "id": "1001062",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin10.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87648682"
            },
            {
              "id": "1001063",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin11.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88076291"
            },
            {
              "id": "1001064",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin12.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88141593"
            },
            {
              "id": "1001065",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin13.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87938037"
            },
            {
              "id": "1001066",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin14.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88527518"
            },
            {
              "id": "1001067",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin15.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87891125"
            },
            {
              "id": "1001068",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin16.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89027521"
            },
            {
              "id": "1001069",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin17.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88332898"
            },
            {
              "id": "1001070",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin18.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87557713"
            },
            {
              "id": "1001071",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin19.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88924492"
            },
            {
              "id": "1001072",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin20.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87483891"
            },
            {
              "id": "1001073",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin21.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90872712"
            },
            {
              "id": "1001074",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin22.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87459012"
            },
            {
              "id": "1001075",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin23.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90915302"
            },
            {
              "id": "1001076",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin24.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88900951"
            },
            {
              "id": "1001077",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin25.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90704947"
            },
            {
              "id": "1001078",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin26.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88220305"
            },
            {
              "id": "1001079",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin27.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89433947"
            },
            {
              "id": "1001080",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin28.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88879535"
            },
            {
              "id": "1001081",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin29.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88642550"
            },
            {
              "id": "1001082",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin30.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88912303"
            },
            {
              "id": "1001083",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin31.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89761731"
            },
            {
              "id": "1001084",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin32.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91515671"
            },
            {
              "id": "1001085",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin33.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91150459"
            },
            {
              "id": "1001086",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin34.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90551206"
            },
            {
              "id": "1001087",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin35.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90099244"
            },
            {
              "id": "1001088",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin36.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90186074"
            },
            {
              "id": "1001089",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin37.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88868363"
            },
            {
              "id": "1001090",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin38.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88514299"
            },
            {
              "id": "1001091",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin39.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90979286"
            },
            {
              "id": "1001092",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin40.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88842282"
            },
            {
              "id": "1001093",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin41.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91022313"
            },
            {
              "id": "1001094",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin42.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88227590"
            },
            {
              "id": "1001095",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin43.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88513354"
            },
            {
              "id": "1001096",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin44.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87715230"
            },
            {
              "id": "1001097",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin45.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90533981"
            },
            {
              "id": "1001098",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin46.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87083328"
            },
            {
              "id": "1001099",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin47.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90304570"
            },
            {
              "id": "1001100",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin48.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88493126"
            },
            {
              "id": "1001101",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin49.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87652567"
            },
            {
              "id": "1001102",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin50.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91196819"
            },
            {
              "id": "1001103",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin51.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90236397"
            },
            {
              "id": "1001104",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin52.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90223156"
            },
            {
              "id": "1001105",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin53.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88692054"
            },
            {
              "id": "1001106",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin54.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89582572"
            },
            {
              "id": "1001107",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin55.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88008819"
            },
            {
              "id": "1001108",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin56.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90875808"
            },
            {
              "id": "1001109",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin57.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90628262"
            },
            {
              "id": "1001110",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin58.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88318989"
            },
            {
              "id": "1001111",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin59.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90772549"
            },
            {
              "id": "1001112",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin60.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89136848"
            },
            {
              "id": "1001113",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin61.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91270550"
            },
            {
              "id": "1001114",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin62.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87715721"
            },
            {
              "id": "1001115",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin63.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89855552"
            },
            {
              "id": "1001116",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin64.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90758177"
            },
            {
              "id": "1001117",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin65.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87899614"
            },
            {
              "id": "1001118",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin66.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89684340"
            },
            {
              "id": "1001119",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin67.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89100977"
            },
            {
              "id": "1001120",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin68.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87337317"
            },
            {
              "id": "1001121",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin69.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91015403"
            },
            {
              "id": "1001122",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin70.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87376048"
            },
            {
              "id": "1001123",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin71.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91586057"
            },
            {
              "id": "1001124",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin72.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89554272"
            },
            {
              "id": "1001125",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin73.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90341454"
            },
            {
              "id": "1001126",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin74.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90695466"
            },
            {
              "id": "1001127",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin75.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90958782"
            },
            {
              "id": "1001128",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin76.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88186611"
            },
            {
              "id": "1001129",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin77.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88290464"
            },
            {
              "id": "1001130",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin78.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90462321"
            },
            {
              "id": "1001131",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin79.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89521263"
            },
            {
              "id": "1001132",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin80.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88387596"
            },
            {
              "id": "1001133",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin81.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91283633"
            },
            {
              "id": "1001134",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin82.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90446675"
            },
            {
              "id": "1001135",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin83.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88350568"
            },
            {
              "id": "1001136",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin84.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91652879"
            },
            {
              "id": "1001137",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin85.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88537957"
            },
            {
              "id": "1001138",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin86.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88070148"
            },
            {
              "id": "1001139",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin87.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91695834"
            },
            {
              "id": "1001140",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin88.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.91564931"
            },
            {
              "id": "1001141",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin89.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90191878"
            },
            {
              "id": "1001142",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin90.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90563461"
            },
            {
              "id": "1001143",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin91.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87529384"
            },
            {
              "id": "1001144",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin92.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.88054809"
            },
            {
              "id": "1001145",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin93.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87439199"
            },
            {
              "id": "1001146",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin94.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90149910"
            },
            {
              "id": "1001147",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin95.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90987063"
            },
            {
              "id": "1001148",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin96.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87809873"
            },
            {
              "id": "1001149",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin97.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89839975"
            },
            {
              "id": "1001150",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin98.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89116379"
            },
            {
              "id": "1001151",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin99.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.89019161"
            },
            {
              "id": "1001152",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin100.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87806718"
            },
            {
              "id": "1001153",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin101.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.87977512"
            },
            {
              "id": "1001154",
              "create_at": "                   ",
              "image": "/detect/trashbin/trashbin102.png",
              "status": "탐지완료",
              "classification_type": "공공 쓰레기통",
              "content": "공공 쓰레기통",
              "comment": "평균 탐지율 : 0.90116619"
            }
          ])
        } else if(inputText == '폐타이어') {
          console.log("폐타이어 검색")
          setNewsList([
            {
              "id": "1001155",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire1.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89633087"
            },
            {
              "id": "1001156",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire2.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90110327"
            },
            {
              "id": "1001157",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire3.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91338000"
            },
            {
              "id": "1001158",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire4.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90346115"
            },
            {
              "id": "1001159",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire5.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88720164"
            },
            {
              "id": "1001160",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire6.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88167424"
            },
            {
              "id": "1001161",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire7.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90112307"
            },
            {
              "id": "1001162",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire8.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87712679"
            },
            {
              "id": "1001163",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire9.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89093850"
            },
            {
              "id": "1001164",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire10.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88517388"
            },
            {
              "id": "1001165",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire11.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88433649"
            },
            {
              "id": "1001166",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire12.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87511381"
            },
            {
              "id": "1001167",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire13.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89131383"
            },
            {
              "id": "1001168",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire14.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87079011"
            },
            {
              "id": "1001169",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire15.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89417804"
            },
            {
              "id": "1001170",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire16.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89346885"
            },
            {
              "id": "1001171",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire17.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88764269"
            },
            {
              "id": "1001172",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire18.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89700003"
            },
            {
              "id": "1001173",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire19.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89909260"
            },
            {
              "id": "1001174",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire20.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89987991"
            },
            {
              "id": "1001175",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire21.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90279117"
            },
            {
              "id": "1001176",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire22.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91135384"
            },
            {
              "id": "1001177",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire23.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90355468"
            },
            {
              "id": "1001178",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire24.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88808545"
            },
            {
              "id": "1001179",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire25.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87463931"
            },
            {
              "id": "1001180",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire26.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89134140"
            },
            {
              "id": "1001181",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire27.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91330610"
            },
            {
              "id": "1001182",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire28.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88613735"
            },
            {
              "id": "1001183",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire29.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91705511"
            },
            {
              "id": "1001184",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire30.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90682151"
            },
            {
              "id": "1001185",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire31.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89973961"
            },
            {
              "id": "1001186",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire32.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90404540"
            },
            {
              "id": "1001187",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire33.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91502858"
            },
            {
              "id": "1001188",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire34.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91578966"
            },
            {
              "id": "1001189",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire35.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89903843"
            },
            {
              "id": "1001190",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire36.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89307316"
            },
            {
              "id": "1001191",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire37.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87240171"
            },
            {
              "id": "1001192",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire38.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89121472"
            },
            {
              "id": "1001193",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire39.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91519210"
            },
            {
              "id": "1001194",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire40.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87468624"
            },
            {
              "id": "1001195",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire41.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90324215"
            },
            {
              "id": "1001196",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire42.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88511282"
            },
            {
              "id": "1001197",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire43.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87198332"
            },
            {
              "id": "1001198",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire44.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90490634"
            },
            {
              "id": "1001199",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire45.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91620179"
            },
            {
              "id": "1001200",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire46.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90980292"
            },
            {
              "id": "1001201",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire47.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90421993"
            },
            {
              "id": "1001202",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire48.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90944318"
            },
            {
              "id": "1001203",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire49.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90669130"
            },
            {
              "id": "1001204",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire50.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87374537"
            },
            {
              "id": "1001205",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire51.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90818314"
            },
            {
              "id": "1001206",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire52.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90623398"
            },
            {
              "id": "1001207",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire53.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88035134"
            },
            {
              "id": "1001208",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire54.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87891941"
            },
            {
              "id": "1001209",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire55.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89916433"
            },
            {
              "id": "1001210",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire56.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90707800"
            },
            {
              "id": "1001211",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire57.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91135438"
            },
            {
              "id": "1001212",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire58.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88112407"
            },
            {
              "id": "1001213",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire59.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89310387"
            },
            {
              "id": "1001214",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire60.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88548174"
            },
            {
              "id": "1001215",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire61.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88226352"
            },
            {
              "id": "1001216",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire62.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91329926"
            },
            {
              "id": "1001217",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire63.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91402376"
            },
            {
              "id": "1001218",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire64.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90441998"
            },
            {
              "id": "1001219",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire65.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91396903"
            },
            {
              "id": "1001220",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire66.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91104655"
            },
            {
              "id": "1001221",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire67.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87120095"
            },
            {
              "id": "1001222",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire68.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91645104"
            },
            {
              "id": "1001223",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire69.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88748707"
            },
            {
              "id": "1001224",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire70.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89377690"
            },
            {
              "id": "1001225",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire71.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89625867"
            },
            {
              "id": "1001226",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire72.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89533164"
            },
            {
              "id": "1001227",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire73.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90936129"
            },
            {
              "id": "1001228",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire74.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90770285"
            },
            {
              "id": "1001229",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire75.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88190490"
            },
            {
              "id": "1001230",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire76.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87724575"
            },
            {
              "id": "1001231",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire77.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90206392"
            },
            {
              "id": "1001232",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire78.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87122977"
            },
            {
              "id": "1001233",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire79.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91281534"
            },
            {
              "id": "1001234",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire80.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91320074"
            },
            {
              "id": "1001235",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire81.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87868763"
            },
            {
              "id": "1001236",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire82.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87849268"
            },
            {
              "id": "1001237",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire83.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89931354"
            },
            {
              "id": "1001238",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire84.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87018503"
            },
            {
              "id": "1001239",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire85.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88884939"
            },
            {
              "id": "1001240",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire86.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87683926"
            },
            {
              "id": "1001241",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire87.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87872130"
            },
            {
              "id": "1001242",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire88.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90653636"
            },
            {
              "id": "1001243",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire89.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90565562"
            },
            {
              "id": "1001244",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire90.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88991011"
            },
            {
              "id": "1001245",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire91.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91107932"
            },
            {
              "id": "1001246",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire92.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88945833"
            },
            {
              "id": "1001247",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire93.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87368325"
            },
            {
              "id": "1001248",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire94.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90707723"
            },
            {
              "id": "1001249",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire95.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90425873"
            },
            {
              "id": "1001250",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire96.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91622930"
            },
            {
              "id": "1001251",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire97.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.90908285"
            },
            {
              "id": "1001252",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire98.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88904825"
            },
            {
              "id": "1001253",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire99.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88957961"
            },
            {
              "id": "1001254",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire100.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87796320"
            },
            {
              "id": "1001255",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire101.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89914671"
            },
            {
              "id": "1001256",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire102.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.87872004"
            },
            {
              "id": "1001257",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire103.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89271570"
            },
            {
              "id": "1001258",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire104.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.89951410"
            },
            {
              "id": "1001259",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire105.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91624154"
            },
            {
              "id": "1001260",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire106.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.88456961"
            },
            {
              "id": "1001261",
              "create_at": "                   ",
              "image": "/detect/trashtire/trashtire107.png",
              "status": "탐지완료",
              "classification_type": "폐타이어",
              "content": "폐타이어",
              "comment": "평균 탐지율 : 0.91082607"
            }
          ])
        } else if(inputText == '물통') {
          console.log("물통 검색")
          setNewsList([
            {
              "id": "1001262",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum1.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90998638"
            },
            {
              "id": "1001263",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum2.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88037824"
            },
            {
              "id": "1001264",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum3.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91547563"
            },
            {
              "id": "1001265",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum4.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87745288"
            },
            {
              "id": "1001266",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum5.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88685137"
            },
            {
              "id": "1001267",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum6.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87520980"
            },
            {
              "id": "1001268",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum7.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91573525"
            },
            {
              "id": "1001269",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum8.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87264721"
            },
            {
              "id": "1001270",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum9.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87272073"
            },
            {
              "id": "1001271",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum10.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88937070"
            },
            {
              "id": "1001272",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum11.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90052072"
            },
            {
              "id": "1001273",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum12.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91003467"
            },
            {
              "id": "1001274",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum13.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88073191"
            },
            {
              "id": "1001275",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum14.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89592067"
            },
            {
              "id": "1001276",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum15.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88421400"
            },
            {
              "id": "1001277",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum16.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89904451"
            },
            {
              "id": "1001278",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum17.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89249945"
            },
            {
              "id": "1001279",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum18.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87680455"
            },
            {
              "id": "1001280",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum19.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90464607"
            },
            {
              "id": "1001281",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum20.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89615984"
            },
            {
              "id": "1001282",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum21.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90005554"
            },
            {
              "id": "1001283",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum22.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89587861"
            },
            {
              "id": "1001284",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum23.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87014526"
            },
            {
              "id": "1001285",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum24.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90311616"
            },
            {
              "id": "1001286",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum25.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88314871"
            },
            {
              "id": "1001287",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum26.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91671113"
            },
            {
              "id": "1001288",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum27.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91269536"
            },
            {
              "id": "1001289",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum28.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87811859"
            },
            {
              "id": "1001290",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum29.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89883780"
            },
            {
              "id": "1001291",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum30.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88520721"
            },
            {
              "id": "1001292",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum31.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87041895"
            },
            {
              "id": "1001293",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum32.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88060816"
            },
            {
              "id": "1001294",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum33.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90081672"
            },
            {
              "id": "1001295",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum34.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90357813"
            },
            {
              "id": "1001296",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum35.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89897951"
            },
            {
              "id": "1001297",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum36.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91284107"
            },
            {
              "id": "1001298",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum37.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87742812"
            },
            {
              "id": "1001299",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum38.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89185281"
            },
            {
              "id": "1001300",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum39.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90101949"
            },
            {
              "id": "1001301",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum40.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90372972"
            },
            {
              "id": "1001302",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum41.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87167845"
            },
            {
              "id": "1001303",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum42.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87881511"
            },
            {
              "id": "1001304",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum43.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87725592"
            },
            {
              "id": "1001305",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum44.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87306252"
            },
            {
              "id": "1001306",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum45.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90495658"
            },
            {
              "id": "1001307",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum46.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87639536"
            },
            {
              "id": "1001308",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum47.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87699365"
            },
            {
              "id": "1001309",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum48.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90841423"
            },
            {
              "id": "1001310",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum49.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90778219"
            },
            {
              "id": "1001311",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum50.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91672239"
            },
            {
              "id": "1001312",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum51.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87066726"
            },
            {
              "id": "1001313",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum52.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88544487"
            },
            {
              "id": "1001314",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum53.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88740877"
            },
            {
              "id": "1001315",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum54.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91160199"
            },
            {
              "id": "1001316",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum55.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89690399"
            },
            {
              "id": "1001317",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum56.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87191701"
            },
            {
              "id": "1001318",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum57.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90465747"
            },
            {
              "id": "1001319",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum58.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87052279"
            },
            {
              "id": "1001320",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum59.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87756062"
            },
            {
              "id": "1001321",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum60.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90260576"
            },
            {
              "id": "1001322",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum61.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88178221"
            },
            {
              "id": "1001323",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum62.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89998862"
            },
            {
              "id": "1001324",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum63.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90728732"
            },
            {
              "id": "1001325",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum64.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87222573"
            },
            {
              "id": "1001326",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum65.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89876365"
            },
            {
              "id": "1001327",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum66.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90520037"
            },
            {
              "id": "1001328",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum67.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90766371"
            },
            {
              "id": "1001329",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum68.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88533853"
            },
            {
              "id": "1001330",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum69.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90081470"
            },
            {
              "id": "1001331",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum70.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89327680"
            },
            {
              "id": "1001332",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum71.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89981993"
            },
            {
              "id": "1001333",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum72.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89554626"
            },
            {
              "id": "1001334",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum73.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88787973"
            },
            {
              "id": "1001335",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum74.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88209680"
            },
            {
              "id": "1001336",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum75.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88799362"
            },
            {
              "id": "1001337",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum76.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90981588"
            },
            {
              "id": "1001338",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum77.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91316086"
            },
            {
              "id": "1001339",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum78.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87125079"
            },
            {
              "id": "1001340",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum79.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91172761"
            },
            {
              "id": "1001341",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum80.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89119013"
            },
            {
              "id": "1001342",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum81.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89219284"
            },
            {
              "id": "1001343",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum82.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87438729"
            },
            {
              "id": "1001344",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum83.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91415917"
            },
            {
              "id": "1001345",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum84.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87034980"
            },
            {
              "id": "1001346",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum85.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87084673"
            },
            {
              "id": "1001347",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum86.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87568379"
            },
            {
              "id": "1001348",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum87.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89549028"
            },
            {
              "id": "1001349",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum88.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88808515"
            },
            {
              "id": "1001350",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum89.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91246214"
            },
            {
              "id": "1001351",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum90.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87435859"
            },
            {
              "id": "1001352",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum91.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87617928"
            },
            {
              "id": "1001353",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum92.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90823272"
            },
            {
              "id": "1001354",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum93.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91353737"
            },
            {
              "id": "1001355",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum94.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89281014"
            },
            {
              "id": "1001356",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum95.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91718148"
            },
            {
              "id": "1001357",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum96.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91255700"
            },
            {
              "id": "1001358",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum97.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89089612"
            },
            {
              "id": "1001359",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum98.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.91490684"
            },
            {
              "id": "1001360",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum99.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89673140"
            },
            {
              "id": "1001361",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum100.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.88497711"
            },
            {
              "id": "1001362",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum101.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.90163011"
            },
            {
              "id": "1001363",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum102.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89020302"
            },
            {
              "id": "1001364",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum103.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.87351092"
            },
            {
              "id": "1001365",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum104.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89754463"
            },
            {
              "id": "1001366",
              "create_at": "                   ",
              "image": "/detect/waterdrum/waterdrum105.png",
              "status": "탐지완료",
              "classification_type": "방치 물통",
              "content": "방치 물통",
              "comment": "평균 탐지율 : 0.89129073"
            }
          ])
        } else if(inputText == '안내표지판') {
          console.log("안내표지만 검색")
          setNewsList([
            {
              "id": "1000205",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel1.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91275796"
            },
            {
              "id": "1000206",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel2.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88022116"
            },
            {
              "id": "1000207",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel3.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88045438"
            },
            {
              "id": "1000208",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel4.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89139971"
            },
            {
              "id": "1000209",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel5.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90689807"
            },
            {
              "id": "1000210",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel6.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89327411"
            },
            {
              "id": "1000211",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel7.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91414473"
            },
            {
              "id": "1000212",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel8.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87164656"
            },
            {
              "id": "1000213",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel9.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87522251"
            },
            {
              "id": "1000214",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel10.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88176357"
            },
            {
              "id": "1000215",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel11.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88778633"
            },
            {
              "id": "1000216",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel12.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91169600"
            },
            {
              "id": "1000217",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel13.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90377284"
            },
            {
              "id": "1000218",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel14.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89927219"
            },
            {
              "id": "1000219",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel15.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89188528"
            },
            {
              "id": "1000220",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel16.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90384736"
            },
            {
              "id": "1000221",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel17.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89632138"
            },
            {
              "id": "1000222",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel18.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89512605"
            },
            {
              "id": "1000223",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel19.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89607760"
            },
            {
              "id": "1000224",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel20.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90652139"
            },
            {
              "id": "1000225",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel21.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89202373"
            },
            {
              "id": "1000226",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel22.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90794846"
            },
            {
              "id": "1000227",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel23.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87179999"
            },
            {
              "id": "1000228",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel24.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91507551"
            },
            {
              "id": "1000229",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel25.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87969921"
            },
            {
              "id": "1000230",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel26.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90301951"
            },
            {
              "id": "1000231",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel27.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88842717"
            },
            {
              "id": "1000232",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel28.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87282702"
            },
            {
              "id": "1000233",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel29.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90763292"
            },
            {
              "id": "1000234",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel30.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88954345"
            },
            {
              "id": "1000235",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel31.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89350202"
            },
            {
              "id": "1000236",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel32.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89140158"
            },
            {
              "id": "1000237",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel33.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88461331"
            },
            {
              "id": "1000238",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel34.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89665716"
            },
            {
              "id": "1000239",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel35.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91019361"
            },
            {
              "id": "1000240",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel36.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91729762"
            },
            {
              "id": "1000241",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel37.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90282321"
            },
            {
              "id": "1000242",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel38.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89058705"
            },
            {
              "id": "1000243",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel39.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87696230"
            },
            {
              "id": "1000244",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel40.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89802584"
            },
            {
              "id": "1000245",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel41.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88200390"
            },
            {
              "id": "1000246",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel42.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90122740"
            },
            {
              "id": "1000247",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel43.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90469848"
            },
            {
              "id": "1000248",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel44.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88334386"
            },
            {
              "id": "1000249",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel45.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89426923"
            },
            {
              "id": "1000250",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel46.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88494757"
            },
            {
              "id": "1000251",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel47.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88045615"
            },
            {
              "id": "1000252",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel48.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88719691"
            },
            {
              "id": "1000253",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel49.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91090153"
            },
            {
              "id": "1000254",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel50.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89855509"
            },
            {
              "id": "1000255",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel51.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89067018"
            },
            {
              "id": "1000256",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel52.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87667387"
            },
            {
              "id": "1000257",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel53.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90856799"
            },
            {
              "id": "1000258",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel54.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87333419"
            },
            {
              "id": "1000259",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel55.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89376311"
            },
            {
              "id": "1000260",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel56.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88566569"
            },
            {
              "id": "1000261",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel57.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88436900"
            },
            {
              "id": "1000262",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel58.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89575386"
            },
            {
              "id": "1000263",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel59.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90769521"
            },
            {
              "id": "1000264",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel60.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90755243"
            },
            {
              "id": "1000265",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel61.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89821599"
            },
            {
              "id": "1000266",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel62.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89955055"
            },
            {
              "id": "1000267",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel63.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87712051"
            },
            {
              "id": "1000268",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel64.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89639914"
            },
            {
              "id": "1000269",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel65.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91247557"
            },
            {
              "id": "1000270",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel66.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90652488"
            },
            {
              "id": "1000271",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel67.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88862907"
            },
            {
              "id": "1000272",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel68.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89825701"
            },
            {
              "id": "1000273",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel69.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91219394"
            },
            {
              "id": "1000274",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel70.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89517252"
            },
            {
              "id": "1000275",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel71.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88183436"
            },
            {
              "id": "1000276",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel72.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88550655"
            },
            {
              "id": "1000277",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel73.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89348756"
            },
            {
              "id": "1000278",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel74.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90759213"
            },
            {
              "id": "1000279",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel75.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90886175"
            },
            {
              "id": "1000280",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel76.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91306126"
            },
            {
              "id": "1000281",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel77.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87497989"
            },
            {
              "id": "1000282",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel78.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91625195"
            },
            {
              "id": "1000283",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel79.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87043912"
            },
            {
              "id": "1000284",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel80.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87442661"
            },
            {
              "id": "1000285",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel81.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89817757"
            },
            {
              "id": "1000286",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel82.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87917497"
            },
            {
              "id": "1000287",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel83.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89050190"
            },
            {
              "id": "1000288",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel84.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88504888"
            },
            {
              "id": "1000289",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel85.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87552171"
            },
            {
              "id": "1000290",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel86.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89454642"
            },
            {
              "id": "1000291",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel87.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91706296"
            },
            {
              "id": "1000292",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel88.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90883838"
            },
            {
              "id": "1000293",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel89.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91631139"
            },
            {
              "id": "1000294",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel90.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89199757"
            },
            {
              "id": "1000295",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel91.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.88940176"
            },
            {
              "id": "1000296",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel92.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90421112"
            },
            {
              "id": "1000297",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel93.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89274155"
            },
            {
              "id": "1000298",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel94.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89301982"
            },
            {
              "id": "1000299",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel95.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.91538903"
            },
            {
              "id": "1000300",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel96.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87726265"
            },
            {
              "id": "1000301",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel97.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90362431"
            },
            {
              "id": "1000302",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel98.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.87712397"
            },
            {
              "id": "1000303",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel99.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.89122127"
            },
            {
              "id": "1000304",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel100.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90978135"
            },
            {
              "id": "1000305",
              "create_at": "                   ",
              "image": "/detect/constructpanel/constructpanel101.png",
              "status": "탐지완료",
              "classification_type": "공사안내표지판",
              "content": "공사안내표지판",
              "comment": "평균 탐지율 : 0.90477404"
            }
          ])
        } else if(inputText == '맨홀') {
          console.log("맨홀 검색")
          setNewsList([
            {
              "id": "1000519",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole1.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87422548"
            },
            {
              "id": "1000520",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole2.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88917782"
            },
            {
              "id": "1000521",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole3.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87668242"
            },
            {
              "id": "1000522",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole4.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88942244"
            },
            {
              "id": "1000523",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole5.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91041192"
            },
            {
              "id": "1000524",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole6.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89218165"
            },
            {
              "id": "1000525",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole7.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88255046"
            },
            {
              "id": "1000526",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole8.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91064209"
            },
            {
              "id": "1000527",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole9.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87632601"
            },
            {
              "id": "1000528",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole10.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90442152"
            },
            {
              "id": "1000529",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole11.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87338888"
            },
            {
              "id": "1000530",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole12.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87864127"
            },
            {
              "id": "1000531",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole13.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90664570"
            },
            {
              "id": "1000532",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole14.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87066567"
            },
            {
              "id": "1000533",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole15.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90965516"
            },
            {
              "id": "1000534",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole16.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89870528"
            },
            {
              "id": "1000535",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole17.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88394790"
            },
            {
              "id": "1000536",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole18.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89949677"
            },
            {
              "id": "1000537",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole19.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87652453"
            },
            {
              "id": "1000538",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole20.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88433660"
            },
            {
              "id": "1000539",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole21.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89872679"
            },
            {
              "id": "1000540",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole22.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90217514"
            },
            {
              "id": "1000541",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole23.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90419059"
            },
            {
              "id": "1000542",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole24.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90442073"
            },
            {
              "id": "1000543",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole25.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88947451"
            },
            {
              "id": "1000544",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole26.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88463178"
            },
            {
              "id": "1000545",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole27.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91446590"
            },
            {
              "id": "1000546",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole28.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88745951"
            },
            {
              "id": "1000547",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole29.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89932861"
            },
            {
              "id": "1000548",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole30.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87355265"
            },
            {
              "id": "1000549",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole31.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88653270"
            },
            {
              "id": "1000550",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole32.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89746781"
            },
            {
              "id": "1000551",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole33.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89279883"
            },
            {
              "id": "1000552",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole34.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88792956"
            },
            {
              "id": "1000553",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole35.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87860168"
            },
            {
              "id": "1000554",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole36.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87251353"
            },
            {
              "id": "1000555",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole37.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89843838"
            },
            {
              "id": "1000556",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole38.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88011222"
            },
            {
              "id": "1000557",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole39.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88662094"
            },
            {
              "id": "1000558",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole40.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91496105"
            },
            {
              "id": "1000559",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole41.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89507480"
            },
            {
              "id": "1000560",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole42.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90702079"
            },
            {
              "id": "1000561",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole43.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91349224"
            },
            {
              "id": "1000562",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole44.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91612451"
            },
            {
              "id": "1000563",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole45.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88635208"
            },
            {
              "id": "1000564",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole46.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89597973"
            },
            {
              "id": "1000565",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole47.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87365231"
            },
            {
              "id": "1000566",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole48.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89418025"
            },
            {
              "id": "1000567",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole49.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90847185"
            },
            {
              "id": "1000568",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole50.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88694250"
            },
            {
              "id": "1000569",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole51.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88908607"
            },
            {
              "id": "1000570",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole52.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91405521"
            },
            {
              "id": "1000571",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole53.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88353590"
            },
            {
              "id": "1000572",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole54.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89982600"
            },
            {
              "id": "1000573",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole55.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89762636"
            },
            {
              "id": "1000574",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole56.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89288878"
            },
            {
              "id": "1000575",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole57.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91373957"
            },
            {
              "id": "1000576",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole58.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87702422"
            },
            {
              "id": "1000577",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole59.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89347271"
            },
            {
              "id": "1000578",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole60.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88783421"
            },
            {
              "id": "1000579",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole61.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90657782"
            },
            {
              "id": "1000580",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole62.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91386378"
            },
            {
              "id": "1000581",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole63.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91709230"
            },
            {
              "id": "1000582",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole64.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90314133"
            },
            {
              "id": "1000583",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole65.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89147484"
            },
            {
              "id": "1000584",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole66.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91399218"
            },
            {
              "id": "1000585",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole67.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88889999"
            },
            {
              "id": "1000586",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole68.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91242244"
            },
            {
              "id": "1000587",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole69.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90521899"
            },
            {
              "id": "1000588",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole70.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91559517"
            },
            {
              "id": "1000589",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole71.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88550742"
            },
            {
              "id": "1000590",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole72.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89969733"
            },
            {
              "id": "1000591",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole73.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88050683"
            },
            {
              "id": "1000592",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole74.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88844376"
            },
            {
              "id": "1000593",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole75.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90174311"
            },
            {
              "id": "1000594",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole76.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91276817"
            },
            {
              "id": "1000595",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole77.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87366113"
            },
            {
              "id": "1000596",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole78.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88906644"
            },
            {
              "id": "1000597",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole79.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88897433"
            },
            {
              "id": "1000598",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole80.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91613698"
            },
            {
              "id": "1000599",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole81.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88650083"
            },
            {
              "id": "1000600",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole82.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87430886"
            },
            {
              "id": "1000601",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole83.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89379645"
            },
            {
              "id": "1000602",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole84.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90484486"
            },
            {
              "id": "1000603",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole85.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90187895"
            },
            {
              "id": "1000604",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole86.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87204658"
            },
            {
              "id": "1000605",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole87.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90143227"
            },
            {
              "id": "1000606",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole88.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87820039"
            },
            {
              "id": "1000607",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole89.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88555753"
            },
            {
              "id": "1000608",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole90.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90178875"
            },
            {
              "id": "1000609",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole91.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91452600"
            },
            {
              "id": "1000610",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole92.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87801782"
            },
            {
              "id": "1000611",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole93.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88353632"
            },
            {
              "id": "1000612",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole94.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90331618"
            },
            {
              "id": "1000613",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole95.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87752667"
            },
            {
              "id": "1000614",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole96.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.89053880"
            },
            {
              "id": "1000615",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole97.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90435475"
            },
            {
              "id": "1000616",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole98.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87341955"
            },
            {
              "id": "1000617",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole99.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88632328"
            },
            {
              "id": "1000618",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole100.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.91704165"
            },
            {
              "id": "1000619",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole101.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90011346"
            },
            {
              "id": "1000620",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole102.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.87847387"
            },
            {
              "id": "1000621",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole103.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88443721"
            },
            {
              "id": "1000622",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole104.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.90405660"
            },
            {
              "id": "1000623",
              "create_at": "                   ",
              "image": "/detect/manhole/manhole105.png",
              "status": "탐지완료",
              "classification_type": "맨홀",
              "content": "맨홀",
              "comment": "평균 탐지율 : 0.88084316"
            }
          ])
        } else if(inputText == '마대자루') {
          console.log("마대자루 검색")
          setNewsList([
            {
              "id": "1000950",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag1.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89308557"
            },
            {
              "id": "1000951",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag2.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91693928"
            },
            {
              "id": "1000952",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag3.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89791261"
            },
            {
              "id": "1000953",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag4.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88101327"
            },
            {
              "id": "1000954",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag5.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90999295"
            },
            {
              "id": "1000955",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag6.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89882079"
            },
            {
              "id": "1000956",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag7.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91217356"
            },
            {
              "id": "1000957",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag8.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91046137"
            },
            {
              "id": "1000958",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag9.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89614083"
            },
            {
              "id": "1000959",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag10.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87999375"
            },
            {
              "id": "1000960",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag11.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87823312"
            },
            {
              "id": "1000961",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag12.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90648055"
            },
            {
              "id": "1000962",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag13.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88995735"
            },
            {
              "id": "1000963",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag14.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90105262"
            },
            {
              "id": "1000964",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag15.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89910245"
            },
            {
              "id": "1000965",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag16.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88379240"
            },
            {
              "id": "1000966",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag17.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88185142"
            },
            {
              "id": "1000967",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag18.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88853824"
            },
            {
              "id": "1000968",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag19.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87265138"
            },
            {
              "id": "1000969",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag20.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89888443"
            },
            {
              "id": "1000970",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag21.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88340904"
            },
            {
              "id": "1000971",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag22.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90536316"
            },
            {
              "id": "1000972",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag23.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87501474"
            },
            {
              "id": "1000973",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag24.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88464967"
            },
            {
              "id": "1000974",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag25.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87216323"
            },
            {
              "id": "1000975",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag26.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89752795"
            },
            {
              "id": "1000976",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag27.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91270099"
            },
            {
              "id": "1000977",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag28.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90995882"
            },
            {
              "id": "1000978",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag29.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87425553"
            },
            {
              "id": "1000979",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag30.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91440428"
            },
            {
              "id": "1000980",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag31.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90206735"
            },
            {
              "id": "1000981",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag32.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89412641"
            },
            {
              "id": "1000982",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag33.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90913038"
            },
            {
              "id": "1000983",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag34.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90658118"
            },
            {
              "id": "1000984",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag35.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90085304"
            },
            {
              "id": "1000985",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag36.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88059085"
            },
            {
              "id": "1000986",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag37.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88145965"
            },
            {
              "id": "1000987",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag38.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87254057"
            },
            {
              "id": "1000988",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag39.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90592693"
            },
            {
              "id": "1000989",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag40.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89556295"
            },
            {
              "id": "1000990",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag41.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90375568"
            },
            {
              "id": "1000991",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag42.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90277226"
            },
            {
              "id": "1000992",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag43.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88693158"
            },
            {
              "id": "1000993",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag44.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88941962"
            },
            {
              "id": "1000994",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag45.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90438823"
            },
            {
              "id": "1000995",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag46.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91448400"
            },
            {
              "id": "1000996",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag47.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89340818"
            },
            {
              "id": "1000997",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag48.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87094660"
            },
            {
              "id": "1000998",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag49.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87846602"
            },
            {
              "id": "1000999",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag50.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87572078"
            },
            {
              "id": "1001000",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag51.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89741168"
            },
            {
              "id": "1001001",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag52.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91378092"
            },
            {
              "id": "1001002",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag53.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91658901"
            },
            {
              "id": "1001003",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag54.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88595790"
            },
            {
              "id": "1001004",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag55.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87742532"
            },
            {
              "id": "1001005",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag56.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87966674"
            },
            {
              "id": "1001006",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag57.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87833470"
            },
            {
              "id": "1001007",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag58.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91626505"
            },
            {
              "id": "1001008",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag59.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87915976"
            },
            {
              "id": "1001009",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag60.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90540496"
            },
            {
              "id": "1001010",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag61.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88141865"
            },
            {
              "id": "1001011",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag62.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88112845"
            },
            {
              "id": "1001012",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag63.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87480870"
            },
            {
              "id": "1001013",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag64.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87827179"
            },
            {
              "id": "1001014",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag65.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87807358"
            },
            {
              "id": "1001015",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag66.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87868442"
            },
            {
              "id": "1001016",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag67.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87450842"
            },
            {
              "id": "1001017",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag68.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90035062"
            },
            {
              "id": "1001018",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag69.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90369136"
            },
            {
              "id": "1001019",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag70.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91166485"
            },
            {
              "id": "1001020",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag71.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89308230"
            },
            {
              "id": "1001021",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag72.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88772997"
            },
            {
              "id": "1001022",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag73.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91477898"
            },
            {
              "id": "1001023",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag74.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90327205"
            },
            {
              "id": "1001024",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag75.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87036363"
            },
            {
              "id": "1001025",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag76.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87606464"
            },
            {
              "id": "1001026",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag77.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89430931"
            },
            {
              "id": "1001027",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag78.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89642974"
            },
            {
              "id": "1001028",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag79.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87789805"
            },
            {
              "id": "1001029",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag80.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90128884"
            },
            {
              "id": "1001030",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag81.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87311609"
            },
            {
              "id": "1001031",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag82.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87328271"
            },
            {
              "id": "1001032",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag83.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89233959"
            },
            {
              "id": "1001033",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag84.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87242097"
            },
            {
              "id": "1001034",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag85.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88450732"
            },
            {
              "id": "1001035",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag86.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87436749"
            },
            {
              "id": "1001036",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag87.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87901828"
            },
            {
              "id": "1001037",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag88.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89353759"
            },
            {
              "id": "1001038",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag89.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88151932"
            },
            {
              "id": "1001039",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag90.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.87591494"
            },
            {
              "id": "1001040",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag91.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88500323"
            },
            {
              "id": "1001041",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag92.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.88426457"
            },
            {
              "id": "1001042",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag93.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89295998"
            },
            {
              "id": "1001043",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag94.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91047945"
            },
            {
              "id": "1001044",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag95.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89554991"
            },
            {
              "id": "1001045",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag96.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91426646"
            },
            {
              "id": "1001046",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag97.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91114289"
            },
            {
              "id": "1001047",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag98.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90530754"
            },
            {
              "id": "1001048",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag99.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91482811"
            },
            {
              "id": "1001049",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag100.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89102180"
            },
            {
              "id": "1001050",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag101.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.91690371"
            },
            {
              "id": "1001051",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag102.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.89440536"
            },
            {
              "id": "1001052",
              "create_at": "                   ",
              "image": "/detect/trashbag/trashbag103.png",
              "status": "탐지완료",
              "classification_type": "마대자루",
              "content": "마대자루",
              "comment": "평균 탐지율 : 0.90545034"
            }
          ])
        } else {
          console.log(inputText+" 검색")
          setNewsList([])
        }
      }} style={{backgroundColor: '#5916C2', borderRadius: '5px', width:'80px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px', color:'#FFF'}}>검색</a>
    </div>

    {/* 여기까지 테스트 */}
    <div style={{marginBottom: '50px'}}>

      <a style={{backgroundColor: '#cbf796', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("포트홀")
        setNewsList([
          {
            "id": "1001367",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole1.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88267715"
          },
          {
            "id": "1001368",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole2.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88645328"
          },
          {
            "id": "1001369",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole3.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91335314"
          },
          {
            "id": "1001370",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole4.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87565577"
          },
          {
            "id": "1001371",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole5.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88824755"
          },
          {
            "id": "1001372",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole6.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87032122"
          },
          {
            "id": "1001373",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole7.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89585175"
          },
          {
            "id": "1001374",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole8.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90352230"
          },
          {
            "id": "1001375",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole9.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90589769"
          },
          {
            "id": "1001376",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole10.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87905128"
          },
          {
            "id": "1001377",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole11.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89360190"
          },
          {
            "id": "1001378",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole12.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90071437"
          },
          {
            "id": "1001379",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole13.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89423518"
          },
          {
            "id": "1001380",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole14.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88855856"
          },
          {
            "id": "1001381",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole15.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88869680"
          },
          {
            "id": "1001382",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole16.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87166572"
          },
          {
            "id": "1001383",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole17.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91270946"
          },
          {
            "id": "1001384",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole18.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88729593"
          },
          {
            "id": "1001385",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole19.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88163484"
          },
          {
            "id": "1001386",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole20.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90017745"
          },
          {
            "id": "1001387",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole21.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88593570"
          },
          {
            "id": "1001388",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole22.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88007833"
          },
          {
            "id": "1001389",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole23.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87960230"
          },
          {
            "id": "1001390",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole24.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90506803"
          },
          {
            "id": "1001391",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole25.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89591050"
          },
          {
            "id": "1001392",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole26.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88082412"
          },
          {
            "id": "1001393",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole27.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89927702"
          },
          {
            "id": "1001394",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole28.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91525044"
          },
          {
            "id": "1001395",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole29.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91068131"
          },
          {
            "id": "1001396",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole30.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89431069"
          },
          {
            "id": "1001397",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole31.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87882300"
          },
          {
            "id": "1001398",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole32.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89426901"
          },
          {
            "id": "1001399",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole33.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89458592"
          },
          {
            "id": "1001400",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole34.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88281198"
          },
          {
            "id": "1001401",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole35.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87810653"
          },
          {
            "id": "1001402",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole36.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89304809"
          },
          {
            "id": "1001403",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole37.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88404675"
          },
          {
            "id": "1001404",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole38.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89326067"
          },
          {
            "id": "1001405",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole39.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88813164"
          },
          {
            "id": "1001406",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole40.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87075866"
          },
          {
            "id": "1001407",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole41.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91387688"
          },
          {
            "id": "1001408",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole42.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89519196"
          },
          {
            "id": "1001409",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole43.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88011402"
          },
          {
            "id": "1001410",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole44.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87996904"
          },
          {
            "id": "1001411",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole45.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89649673"
          },
          {
            "id": "1001412",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole46.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87591327"
          },
          {
            "id": "1001413",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole47.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87532215"
          },
          {
            "id": "1001414",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole48.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89388636"
          },
          {
            "id": "1001415",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole49.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88476603"
          },
          {
            "id": "1001416",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole50.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88788201"
          },
          {
            "id": "1001417",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole51.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87167330"
          },
          {
            "id": "1001418",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole52.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87013153"
          },
          {
            "id": "1001419",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole53.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88626850"
          },
          {
            "id": "1001420",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole54.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89253414"
          },
          {
            "id": "1001421",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole55.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90729443"
          },
          {
            "id": "1001422",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole56.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91133091"
          },
          {
            "id": "1001423",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole57.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90990205"
          },
          {
            "id": "1001424",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole58.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88765385"
          },
          {
            "id": "1001425",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole59.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88412482"
          },
          {
            "id": "1001426",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole60.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90013901"
          },
          {
            "id": "1001427",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole61.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87242952"
          },
          {
            "id": "1001428",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole62.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88061729"
          },
          {
            "id": "1001429",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole63.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91188253"
          },
          {
            "id": "1001430",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole64.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87268927"
          },
          {
            "id": "1001431",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole65.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91353581"
          },
          {
            "id": "1001432",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole66.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87317618"
          },
          {
            "id": "1001433",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole67.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87036565"
          },
          {
            "id": "1001434",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole68.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87541737"
          },
          {
            "id": "1001435",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole69.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90281519"
          },
          {
            "id": "1001436",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole70.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87297649"
          },
          {
            "id": "1001437",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole71.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89315182"
          },
          {
            "id": "1001438",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole72.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87394772"
          },
          {
            "id": "1001439",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole73.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90952401"
          },
          {
            "id": "1001440",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole74.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88654200"
          },
          {
            "id": "1001441",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole75.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88898828"
          },
          {
            "id": "1001442",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole76.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88857964"
          },
          {
            "id": "1001443",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole77.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90383075"
          },
          {
            "id": "1001444",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole78.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88408077"
          },
          {
            "id": "1001445",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole79.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88872979"
          },
          {
            "id": "1001446",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole80.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89861682"
          },
          {
            "id": "1001447",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole81.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90061709"
          },
          {
            "id": "1001448",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole82.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87901217"
          },
          {
            "id": "1001449",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole83.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91242707"
          },
          {
            "id": "1001450",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole84.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89363789"
          },
          {
            "id": "1001451",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole85.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90304044"
          },
          {
            "id": "1001452",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole86.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89042593"
          },
          {
            "id": "1001453",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole87.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90228139"
          },
          {
            "id": "1001454",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole88.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87325555"
          },
          {
            "id": "1001455",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole89.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87383427"
          },
          {
            "id": "1001456",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole90.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91608866"
          },
          {
            "id": "1001457",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole91.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88987365"
          },
          {
            "id": "1001458",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole92.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90930335"
          },
          {
            "id": "1001459",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole93.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89601213"
          },
          {
            "id": "1001460",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole94.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89310199"
          },
          {
            "id": "1001461",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole95.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88772432"
          },
          {
            "id": "1001462",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole96.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90940408"
          },
          {
            "id": "1001463",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole97.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87260216"
          },
          {
            "id": "1001464",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole98.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90120154"
          },
          {
            "id": "1001465",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole99.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89991360"
          },
          {
            "id": "1001466",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole100.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90238890"
          },
          {
            "id": "1001467",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole101.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91084624"
          },
          {
            "id": "1001468",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole102.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91436661"
          },
          {
            "id": "1001469",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole103.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88887020"
          },
          {
            "id": "1001470",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole104.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90401818"
          },
          {
            "id": "1001471",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole105.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88734101"
          },
          {
            "id": "1001472",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole106.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89703003"
          },
          {
            "id": "1001473",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole107.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88889395"
          },
          {
            "id": "1001474",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole108.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87578846"
          },
          {
            "id": "1001475",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole109.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89062246"
          },
          {
            "id": "1001476",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole110.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87137643"
          },
          {
            "id": "1001477",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole111.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89942543"
          },
          {
            "id": "1001478",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole112.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90200077"
          },
          {
            "id": "1001479",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole113.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88893810"
          },
          {
            "id": "1001480",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole114.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90491666"
          },
          {
            "id": "1001481",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole115.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88867997"
          },
          {
            "id": "1001482",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole116.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89118278"
          },
          {
            "id": "1001483",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole117.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.90571208"
          },
          {
            "id": "1001484",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole118.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89210441"
          },
          {
            "id": "1001485",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole119.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87808111"
          },
          {
            "id": "1001486",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole120.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87278416"
          },
          {
            "id": "1001487",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole121.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88513387"
          },
          {
            "id": "1001488",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole122.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88873688"
          },
          {
            "id": "1001489",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole123.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89752221"
          },
          {
            "id": "1001490",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole124.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.89750302"
          },
          {
            "id": "1001491",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole125.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.88626927"
          },
          {
            "id": "1001492",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole126.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.87805869"
          },
          {
            "id": "1001493",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole127.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91374526"
          },
          {
            "id": "1001494",
            "create_at": "                   ",
            "image": "/detect/pothole/pothole128.png",
            "status": "탐지완료",
            "classification_type": "포트홀",
            "content": "포트홀",
            "comment": "평균 탐지율 : 0.91629665"
          }
        ])
     }}>#포트홀 </a>
      {/* <a onClick={() => { console.log("가로균열") }}>가로균열 </a> */}
      {/* <a onClick={() => { console.log("세로균열") }}>세로균열 </a> */}

      <a style={{backgroundColor: '#edff89', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("박스")
        setNewsList([
          {
            "id": "1000103",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes1.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87022543"
          },
          {
            "id": "1000104",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes2.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88090493"
          },
          {
            "id": "1000105",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes3.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89632921"
          },
          {
            "id": "1000106",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes4.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90749181"
          },
          {
            "id": "1000107",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes5.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91571784"
          },
          {
            "id": "1000108",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes6.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88711660"
          },
          {
            "id": "1000109",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes7.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90388257"
          },
          {
            "id": "1000110",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes8.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89379390"
          },
          {
            "id": "1000111",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes9.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90658671"
          },
          {
            "id": "1000112",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes10.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89489295"
          },
          {
            "id": "1000113",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes11.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91068604"
          },
          {
            "id": "1000114",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes12.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87147712"
          },
          {
            "id": "1000115",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes13.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89520570"
          },
          {
            "id": "1000116",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes14.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89292813"
          },
          {
            "id": "1000117",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes15.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89696572"
          },
          {
            "id": "1000118",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes16.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89661823"
          },
          {
            "id": "1000119",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes17.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90851241"
          },
          {
            "id": "1000120",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes18.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91412878"
          },
          {
            "id": "1000121",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes19.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89221972"
          },
          {
            "id": "1000122",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes20.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87908428"
          },
          {
            "id": "1000123",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes21.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88706894"
          },
          {
            "id": "1000124",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes22.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87276427"
          },
          {
            "id": "1000125",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes23.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90212528"
          },
          {
            "id": "1000126",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes24.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87327373"
          },
          {
            "id": "1000127",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes25.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89387787"
          },
          {
            "id": "1000128",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes26.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90018428"
          },
          {
            "id": "1000129",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes27.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87616031"
          },
          {
            "id": "1000130",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes28.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88501899"
          },
          {
            "id": "1000131",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes29.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88291580"
          },
          {
            "id": "1000132",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes30.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89967934"
          },
          {
            "id": "1000133",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes31.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87741422"
          },
          {
            "id": "1000134",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes32.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89084657"
          },
          {
            "id": "1000135",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes33.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87634006"
          },
          {
            "id": "1000136",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes34.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88244525"
          },
          {
            "id": "1000137",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes35.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87041952"
          },
          {
            "id": "1000138",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes36.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87504330"
          },
          {
            "id": "1000139",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes37.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89352167"
          },
          {
            "id": "1000140",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes38.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88205693"
          },
          {
            "id": "1000141",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes39.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89265111"
          },
          {
            "id": "1000142",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes40.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88199991"
          },
          {
            "id": "1000143",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes41.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88809332"
          },
          {
            "id": "1000144",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes42.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91111309"
          },
          {
            "id": "1000145",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes43.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88621440"
          },
          {
            "id": "1000146",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes44.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88808285"
          },
          {
            "id": "1000147",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes45.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90247828"
          },
          {
            "id": "1000148",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes46.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89715080"
          },
          {
            "id": "1000149",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes47.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89300190"
          },
          {
            "id": "1000150",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes48.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90632993"
          },
          {
            "id": "1000151",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes49.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88127646"
          },
          {
            "id": "1000152",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes50.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90742095"
          },
          {
            "id": "1000153",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes51.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88885039"
          },
          {
            "id": "1000154",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes52.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88607698"
          },
          {
            "id": "1000155",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes53.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89123386"
          },
          {
            "id": "1000156",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes54.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88033036"
          },
          {
            "id": "1000157",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes55.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90113958"
          },
          {
            "id": "1000158",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes56.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87657199"
          },
          {
            "id": "1000159",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes57.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90598964"
          },
          {
            "id": "1000160",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes58.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87515921"
          },
          {
            "id": "1000161",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes59.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91624033"
          },
          {
            "id": "1000162",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes60.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89444587"
          },
          {
            "id": "1000163",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes61.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91419045"
          },
          {
            "id": "1000164",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes62.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87276368"
          },
          {
            "id": "1000165",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes63.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89605688"
          },
          {
            "id": "1000166",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes64.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89914684"
          },
          {
            "id": "1000167",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes65.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89387392"
          },
          {
            "id": "1000168",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes66.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88769560"
          },
          {
            "id": "1000169",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes67.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89275192"
          },
          {
            "id": "1000170",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes68.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89541749"
          },
          {
            "id": "1000171",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes69.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91008682"
          },
          {
            "id": "1000172",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes70.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90510938"
          },
          {
            "id": "1000173",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes71.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91568248"
          },
          {
            "id": "1000174",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes72.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90016307"
          },
          {
            "id": "1000175",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes73.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88563500"
          },
          {
            "id": "1000176",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes74.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89515316"
          },
          {
            "id": "1000177",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes75.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87109920"
          },
          {
            "id": "1000178",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes76.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87410150"
          },
          {
            "id": "1000179",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes77.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87491334"
          },
          {
            "id": "1000180",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes78.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89573785"
          },
          {
            "id": "1000181",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes79.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87001506"
          },
          {
            "id": "1000182",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes80.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88354160"
          },
          {
            "id": "1000183",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes81.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90858840"
          },
          {
            "id": "1000184",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes82.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90296221"
          },
          {
            "id": "1000185",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes83.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89080995"
          },
          {
            "id": "1000186",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes84.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88786810"
          },
          {
            "id": "1000187",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes85.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90686663"
          },
          {
            "id": "1000188",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes86.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90835159"
          },
          {
            "id": "1000189",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes87.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87704124"
          },
          {
            "id": "1000190",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes88.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90554016"
          },
          {
            "id": "1000191",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes89.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91105708"
          },
          {
            "id": "1000192",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes90.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87416587"
          },
          {
            "id": "1000193",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes91.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90814923"
          },
          {
            "id": "1000194",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes92.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90618576"
          },
          {
            "id": "1000195",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes93.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88999738"
          },
          {
            "id": "1000196",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes94.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87436697"
          },
          {
            "id": "1000197",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes95.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.89085020"
          },
          {
            "id": "1000198",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes96.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88623963"
          },
          {
            "id": "1000199",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes97.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.91028945"
          },
          {
            "id": "1000200",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes98.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.87167588"
          },
          {
            "id": "1000201",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes99.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.90567647"
          },
          {
            "id": "1000202",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes100.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88693616"
          },
          {
            "id": "1000203",
            "create_at": "                   ",
            "image": "/detect/boxes/boxes101.png",
            "status": "탐지완료",
            "classification_type": "박스",
            "content": "박스",
            "comment": "평균 탐지율 : 0.88376194"
          }
        ])
     }}>#박스 </a>

      <a style={{backgroundColor: '#a9e7f9', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("웅덩이")
        setNewsList([
          {
            "id": "1000624",
            "create_at": "                   ",
            "image": "/detect/pole/pole1.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89749169"
          },
          {
            "id": "1000625",
            "create_at": "                   ",
            "image": "/detect/pole/pole2.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90249008"
          },
          {
            "id": "1000626",
            "create_at": "                   ",
            "image": "/detect/pole/pole3.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87137013"
          },
          {
            "id": "1000627",
            "create_at": "                   ",
            "image": "/detect/pole/pole4.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91692050"
          },
          {
            "id": "1000628",
            "create_at": "                   ",
            "image": "/detect/pole/pole5.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89028262"
          },
          {
            "id": "1000629",
            "create_at": "                   ",
            "image": "/detect/pole/pole6.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87579466"
          },
          {
            "id": "1000630",
            "create_at": "                   ",
            "image": "/detect/pole/pole7.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91475196"
          },
          {
            "id": "1000631",
            "create_at": "                   ",
            "image": "/detect/pole/pole8.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87588099"
          },
          {
            "id": "1000632",
            "create_at": "                   ",
            "image": "/detect/pole/pole9.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90506542"
          },
          {
            "id": "1000633",
            "create_at": "                   ",
            "image": "/detect/pole/pole10.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88106982"
          },
          {
            "id": "1000634",
            "create_at": "                   ",
            "image": "/detect/pole/pole11.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88752702"
          },
          {
            "id": "1000635",
            "create_at": "                   ",
            "image": "/detect/pole/pole12.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87184398"
          },
          {
            "id": "1000636",
            "create_at": "                   ",
            "image": "/detect/pole/pole13.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90526904"
          },
          {
            "id": "1000637",
            "create_at": "                   ",
            "image": "/detect/pole/pole14.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87814007"
          },
          {
            "id": "1000638",
            "create_at": "                   ",
            "image": "/detect/pole/pole15.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89620956"
          },
          {
            "id": "1000639",
            "create_at": "                   ",
            "image": "/detect/pole/pole16.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87572492"
          },
          {
            "id": "1000640",
            "create_at": "                   ",
            "image": "/detect/pole/pole17.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89101594"
          },
          {
            "id": "1000641",
            "create_at": "                   ",
            "image": "/detect/pole/pole18.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88881378"
          },
          {
            "id": "1000642",
            "create_at": "                   ",
            "image": "/detect/pole/pole19.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88035338"
          },
          {
            "id": "1000643",
            "create_at": "                   ",
            "image": "/detect/pole/pole20.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88170236"
          },
          {
            "id": "1000644",
            "create_at": "                   ",
            "image": "/detect/pole/pole21.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89960015"
          },
          {
            "id": "1000645",
            "create_at": "                   ",
            "image": "/detect/pole/pole22.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87483302"
          },
          {
            "id": "1000646",
            "create_at": "                   ",
            "image": "/detect/pole/pole23.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89888492"
          },
          {
            "id": "1000647",
            "create_at": "                   ",
            "image": "/detect/pole/pole24.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89174014"
          },
          {
            "id": "1000648",
            "create_at": "                   ",
            "image": "/detect/pole/pole25.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90636531"
          },
          {
            "id": "1000649",
            "create_at": "                   ",
            "image": "/detect/pole/pole26.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90357720"
          },
          {
            "id": "1000650",
            "create_at": "                   ",
            "image": "/detect/pole/pole27.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88412112"
          },
          {
            "id": "1000651",
            "create_at": "                   ",
            "image": "/detect/pole/pole28.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91622337"
          },
          {
            "id": "1000652",
            "create_at": "                   ",
            "image": "/detect/pole/pole29.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91144568"
          },
          {
            "id": "1000653",
            "create_at": "                   ",
            "image": "/detect/pole/pole30.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90078345"
          },
          {
            "id": "1000654",
            "create_at": "                   ",
            "image": "/detect/pole/pole31.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87527853"
          },
          {
            "id": "1000655",
            "create_at": "                   ",
            "image": "/detect/pole/pole32.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91575237"
          },
          {
            "id": "1000656",
            "create_at": "                   ",
            "image": "/detect/pole/pole33.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91215233"
          },
          {
            "id": "1000657",
            "create_at": "                   ",
            "image": "/detect/pole/pole34.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89506741"
          },
          {
            "id": "1000658",
            "create_at": "                   ",
            "image": "/detect/pole/pole35.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89408404"
          },
          {
            "id": "1000659",
            "create_at": "                   ",
            "image": "/detect/pole/pole36.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89729948"
          },
          {
            "id": "1000660",
            "create_at": "                   ",
            "image": "/detect/pole/pole37.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89311973"
          },
          {
            "id": "1000661",
            "create_at": "                   ",
            "image": "/detect/pole/pole38.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88683647"
          },
          {
            "id": "1000662",
            "create_at": "                   ",
            "image": "/detect/pole/pole39.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88614600"
          },
          {
            "id": "1000663",
            "create_at": "                   ",
            "image": "/detect/pole/pole40.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91532150"
          },
          {
            "id": "1000664",
            "create_at": "                   ",
            "image": "/detect/pole/pole41.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87019760"
          },
          {
            "id": "1000665",
            "create_at": "                   ",
            "image": "/detect/pole/pole42.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88576637"
          },
          {
            "id": "1000666",
            "create_at": "                   ",
            "image": "/detect/pole/pole43.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91181875"
          },
          {
            "id": "1000667",
            "create_at": "                   ",
            "image": "/detect/pole/pole44.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87745704"
          },
          {
            "id": "1000668",
            "create_at": "                   ",
            "image": "/detect/pole/pole45.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91299082"
          },
          {
            "id": "1000669",
            "create_at": "                   ",
            "image": "/detect/pole/pole46.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91406487"
          },
          {
            "id": "1000670",
            "create_at": "                   ",
            "image": "/detect/pole/pole47.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91028097"
          },
          {
            "id": "1000671",
            "create_at": "                   ",
            "image": "/detect/pole/pole48.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88053698"
          },
          {
            "id": "1000672",
            "create_at": "                   ",
            "image": "/detect/pole/pole49.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87981463"
          },
          {
            "id": "1000673",
            "create_at": "                   ",
            "image": "/detect/pole/pole50.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89749771"
          },
          {
            "id": "1000674",
            "create_at": "                   ",
            "image": "/detect/pole/pole51.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89352273"
          },
          {
            "id": "1000675",
            "create_at": "                   ",
            "image": "/detect/pole/pole52.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91467643"
          },
          {
            "id": "1000676",
            "create_at": "                   ",
            "image": "/detect/pole/pole53.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87317105"
          },
          {
            "id": "1000677",
            "create_at": "                   ",
            "image": "/detect/pole/pole54.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90195341"
          },
          {
            "id": "1000678",
            "create_at": "                   ",
            "image": "/detect/pole/pole55.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91215885"
          },
          {
            "id": "1000679",
            "create_at": "                   ",
            "image": "/detect/pole/pole56.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90977833"
          },
          {
            "id": "1000680",
            "create_at": "                   ",
            "image": "/detect/pole/pole57.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89681181"
          },
          {
            "id": "1000681",
            "create_at": "                   ",
            "image": "/detect/pole/pole58.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87129718"
          },
          {
            "id": "1000682",
            "create_at": "                   ",
            "image": "/detect/pole/pole59.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89261017"
          },
          {
            "id": "1000683",
            "create_at": "                   ",
            "image": "/detect/pole/pole60.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87967370"
          },
          {
            "id": "1000684",
            "create_at": "                   ",
            "image": "/detect/pole/pole61.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91577445"
          },
          {
            "id": "1000685",
            "create_at": "                   ",
            "image": "/detect/pole/pole62.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89307762"
          },
          {
            "id": "1000686",
            "create_at": "                   ",
            "image": "/detect/pole/pole63.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89418113"
          },
          {
            "id": "1000687",
            "create_at": "                   ",
            "image": "/detect/pole/pole64.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87350297"
          },
          {
            "id": "1000688",
            "create_at": "                   ",
            "image": "/detect/pole/pole65.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88176379"
          },
          {
            "id": "1000689",
            "create_at": "                   ",
            "image": "/detect/pole/pole66.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89637717"
          },
          {
            "id": "1000690",
            "create_at": "                   ",
            "image": "/detect/pole/pole67.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89240648"
          },
          {
            "id": "1000691",
            "create_at": "                   ",
            "image": "/detect/pole/pole68.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91305283"
          },
          {
            "id": "1000692",
            "create_at": "                   ",
            "image": "/detect/pole/pole69.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88497819"
          },
          {
            "id": "1000693",
            "create_at": "                   ",
            "image": "/detect/pole/pole70.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90253697"
          },
          {
            "id": "1000694",
            "create_at": "                   ",
            "image": "/detect/pole/pole71.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87888973"
          },
          {
            "id": "1000695",
            "create_at": "                   ",
            "image": "/detect/pole/pole72.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91388573"
          },
          {
            "id": "1000696",
            "create_at": "                   ",
            "image": "/detect/pole/pole73.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91551826"
          },
          {
            "id": "1000697",
            "create_at": "                   ",
            "image": "/detect/pole/pole74.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87671375"
          },
          {
            "id": "1000698",
            "create_at": "                   ",
            "image": "/detect/pole/pole75.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89897369"
          },
          {
            "id": "1000699",
            "create_at": "                   ",
            "image": "/detect/pole/pole76.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88960550"
          },
          {
            "id": "1000700",
            "create_at": "                   ",
            "image": "/detect/pole/pole77.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89016005"
          },
          {
            "id": "1000701",
            "create_at": "                   ",
            "image": "/detect/pole/pole78.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90161564"
          },
          {
            "id": "1000702",
            "create_at": "                   ",
            "image": "/detect/pole/pole79.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87175760"
          },
          {
            "id": "1000703",
            "create_at": "                   ",
            "image": "/detect/pole/pole80.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91538669"
          },
          {
            "id": "1000704",
            "create_at": "                   ",
            "image": "/detect/pole/pole81.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88370258"
          },
          {
            "id": "1000705",
            "create_at": "                   ",
            "image": "/detect/pole/pole82.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89878277"
          },
          {
            "id": "1000706",
            "create_at": "                   ",
            "image": "/detect/pole/pole83.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87911062"
          },
          {
            "id": "1000707",
            "create_at": "                   ",
            "image": "/detect/pole/pole84.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89859509"
          },
          {
            "id": "1000708",
            "create_at": "                   ",
            "image": "/detect/pole/pole85.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89463778"
          },
          {
            "id": "1000709",
            "create_at": "                   ",
            "image": "/detect/pole/pole86.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89687180"
          },
          {
            "id": "1000710",
            "create_at": "                   ",
            "image": "/detect/pole/pole87.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91454448"
          },
          {
            "id": "1000711",
            "create_at": "                   ",
            "image": "/detect/pole/pole88.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88527298"
          },
          {
            "id": "1000712",
            "create_at": "                   ",
            "image": "/detect/pole/pole89.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87398058"
          },
          {
            "id": "1000713",
            "create_at": "                   ",
            "image": "/detect/pole/pole90.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87910726"
          },
          {
            "id": "1000714",
            "create_at": "                   ",
            "image": "/detect/pole/pole91.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91660149"
          },
          {
            "id": "1000715",
            "create_at": "                   ",
            "image": "/detect/pole/pole92.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87329760"
          },
          {
            "id": "1000716",
            "create_at": "                   ",
            "image": "/detect/pole/pole93.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89170373"
          },
          {
            "id": "1000717",
            "create_at": "                   ",
            "image": "/detect/pole/pole94.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87477377"
          },
          {
            "id": "1000718",
            "create_at": "                   ",
            "image": "/detect/pole/pole95.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87137034"
          },
          {
            "id": "1000719",
            "create_at": "                   ",
            "image": "/detect/pole/pole96.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89647256"
          },
          {
            "id": "1000720",
            "create_at": "                   ",
            "image": "/detect/pole/pole97.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88356984"
          },
          {
            "id": "1000721",
            "create_at": "                   ",
            "image": "/detect/pole/pole98.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.88184872"
          },
          {
            "id": "1000722",
            "create_at": "                   ",
            "image": "/detect/pole/pole99.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.91497703"
          },
          {
            "id": "1000723",
            "create_at": "                   ",
            "image": "/detect/pole/pole100.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.89531838"
          },
          {
            "id": "1000724",
            "create_at": "                   ",
            "image": "/detect/pole/pole101.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.87727004"
          },
          {
            "id": "1000725",
            "create_at": "                   ",
            "image": "/detect/pole/pole102.png",
            "status": "탐지완료",
            "classification_type": "웅덩이",
            "content": "웅덩이",
            "comment": "평균 탐지율 : 0.90123241"
          }
        ])
     }}>#웅덩이 </a>

      <a style={{backgroundColor: '#f4b0ef', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("가전제품")
        setNewsList([
          {
            "id": "1000306",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics1.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88645876"
          },
          {
            "id": "1000307",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics2.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90804645"
          },
          {
            "id": "1000308",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics3.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90258332"
          },
          {
            "id": "1000309",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics4.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87874617"
          },
          {
            "id": "1000310",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics5.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89202544"
          },
          {
            "id": "1000311",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics6.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88932736"
          },
          {
            "id": "1000312",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics7.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87152859"
          },
          {
            "id": "1000313",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics8.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88005188"
          },
          {
            "id": "1000314",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics9.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90711949"
          },
          {
            "id": "1000315",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics10.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88408399"
          },
          {
            "id": "1000316",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics11.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88271613"
          },
          {
            "id": "1000317",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics12.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89432346"
          },
          {
            "id": "1000318",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics13.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90574075"
          },
          {
            "id": "1000319",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics14.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89057139"
          },
          {
            "id": "1000320",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics15.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87196016"
          },
          {
            "id": "1000321",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics16.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91325320"
          },
          {
            "id": "1000322",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics17.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91057227"
          },
          {
            "id": "1000323",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics18.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87099114"
          },
          {
            "id": "1000324",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics19.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89672474"
          },
          {
            "id": "1000325",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics20.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90525199"
          },
          {
            "id": "1000326",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics21.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89680710"
          },
          {
            "id": "1000327",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics22.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90103332"
          },
          {
            "id": "1000328",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics23.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89070526"
          },
          {
            "id": "1000329",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics24.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88833933"
          },
          {
            "id": "1000330",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics25.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88629613"
          },
          {
            "id": "1000331",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics26.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89955141"
          },
          {
            "id": "1000332",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics27.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87602527"
          },
          {
            "id": "1000333",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics28.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87223968"
          },
          {
            "id": "1000334",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics29.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91234265"
          },
          {
            "id": "1000335",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics30.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88656339"
          },
          {
            "id": "1000336",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics31.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87543801"
          },
          {
            "id": "1000337",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics32.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90171242"
          },
          {
            "id": "1000338",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics33.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90373877"
          },
          {
            "id": "1000339",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics34.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88401905"
          },
          {
            "id": "1000340",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics35.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90360072"
          },
          {
            "id": "1000341",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics36.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87428245"
          },
          {
            "id": "1000342",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics37.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89308060"
          },
          {
            "id": "1000343",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics38.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87190663"
          },
          {
            "id": "1000344",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics39.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90888061"
          },
          {
            "id": "1000345",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics40.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87726000"
          },
          {
            "id": "1000346",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics41.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89357765"
          },
          {
            "id": "1000347",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics42.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90881067"
          },
          {
            "id": "1000348",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics43.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87680833"
          },
          {
            "id": "1000349",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics44.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88997356"
          },
          {
            "id": "1000350",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics45.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89142554"
          },
          {
            "id": "1000351",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics46.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87470971"
          },
          {
            "id": "1000352",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics47.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89420373"
          },
          {
            "id": "1000353",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics48.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88179373"
          },
          {
            "id": "1000354",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics49.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87823135"
          },
          {
            "id": "1000355",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics50.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89714971"
          },
          {
            "id": "1000356",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics51.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90160862"
          },
          {
            "id": "1000357",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics52.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88925563"
          },
          {
            "id": "1000358",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics53.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90244591"
          },
          {
            "id": "1000359",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics54.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91036957"
          },
          {
            "id": "1000360",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics55.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89663328"
          },
          {
            "id": "1000361",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics56.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91075569"
          },
          {
            "id": "1000362",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics57.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89509371"
          },
          {
            "id": "1000363",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics58.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90793513"
          },
          {
            "id": "1000364",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics59.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89936970"
          },
          {
            "id": "1000365",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics60.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91638767"
          },
          {
            "id": "1000366",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics61.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87314839"
          },
          {
            "id": "1000367",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics62.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89915757"
          },
          {
            "id": "1000368",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics63.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87648707"
          },
          {
            "id": "1000369",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics64.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90097142"
          },
          {
            "id": "1000370",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics65.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89205671"
          },
          {
            "id": "1000371",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics66.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87578361"
          },
          {
            "id": "1000372",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics67.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91016579"
          },
          {
            "id": "1000373",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics68.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88381712"
          },
          {
            "id": "1000374",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics69.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88259654"
          },
          {
            "id": "1000375",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics70.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90599354"
          },
          {
            "id": "1000376",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics71.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91311198"
          },
          {
            "id": "1000377",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics72.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87614039"
          },
          {
            "id": "1000378",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics73.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88375890"
          },
          {
            "id": "1000379",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics74.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87357449"
          },
          {
            "id": "1000380",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics75.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91717464"
          },
          {
            "id": "1000381",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics76.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88521211"
          },
          {
            "id": "1000382",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics77.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88632220"
          },
          {
            "id": "1000383",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics78.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89708503"
          },
          {
            "id": "1000384",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics79.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91551564"
          },
          {
            "id": "1000385",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics80.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87522727"
          },
          {
            "id": "1000386",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics81.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88959352"
          },
          {
            "id": "1000387",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics82.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90933033"
          },
          {
            "id": "1000388",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics83.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90976364"
          },
          {
            "id": "1000389",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics84.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88387651"
          },
          {
            "id": "1000390",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics85.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88388233"
          },
          {
            "id": "1000391",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics86.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91519051"
          },
          {
            "id": "1000392",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics87.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87152151"
          },
          {
            "id": "1000393",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics88.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88768347"
          },
          {
            "id": "1000394",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics89.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87427593"
          },
          {
            "id": "1000395",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics90.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88918023"
          },
          {
            "id": "1000396",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics91.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90526035"
          },
          {
            "id": "1000397",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics92.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90851961"
          },
          {
            "id": "1000398",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics93.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87882872"
          },
          {
            "id": "1000399",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics94.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89170224"
          },
          {
            "id": "1000400",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics95.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88371720"
          },
          {
            "id": "1000401",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics96.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88210648"
          },
          {
            "id": "1000402",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics97.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87938972"
          },
          {
            "id": "1000403",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics98.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88689196"
          },
          {
            "id": "1000404",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics99.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89217814"
          },
          {
            "id": "1000405",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics100.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90202656"
          },
          {
            "id": "1000406",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics101.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.89813944"
          },
          {
            "id": "1000407",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics102.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91256148"
          },
          {
            "id": "1000408",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics103.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.91462049"
          },
          {
            "id": "1000409",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics104.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90421022"
          },
          {
            "id": "1000410",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics105.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90761377"
          },
          {
            "id": "1000411",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics106.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.90046702"
          },
          {
            "id": "1000412",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics107.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.87731348"
          },
          {
            "id": "1000413",
            "create_at": "                   ",
            "image": "/detect/electronics/electronics108.png",
            "status": "탐지완료",
            "classification_type": "가전제품",
            "content": "가전제품",
            "comment": "평균 탐지율 : 0.88104591"
          }
        ])
     }}>#가전제품 </a>

      <a style={{backgroundColor: '#f7ec9b', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("쓰레기")
        setNewsList([
          {
            "id": "1000841",
            "create_at": "                   ",
            "image": "/detect/trash/trash1.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87424779"
          },
          {
            "id": "1000842",
            "create_at": "                   ",
            "image": "/detect/trash/trash2.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89626903"
          },
          {
            "id": "1000843",
            "create_at": "                   ",
            "image": "/detect/trash/trash3.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90142049"
          },
          {
            "id": "1000844",
            "create_at": "                   ",
            "image": "/detect/trash/trash4.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90659410"
          },
          {
            "id": "1000845",
            "create_at": "                   ",
            "image": "/detect/trash/trash5.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89551933"
          },
          {
            "id": "1000846",
            "create_at": "                   ",
            "image": "/detect/trash/trash6.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87053374"
          },
          {
            "id": "1000847",
            "create_at": "                   ",
            "image": "/detect/trash/trash7.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88006605"
          },
          {
            "id": "1000848",
            "create_at": "                   ",
            "image": "/detect/trash/trash8.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91174630"
          },
          {
            "id": "1000849",
            "create_at": "                   ",
            "image": "/detect/trash/trash9.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90339669"
          },
          {
            "id": "1000850",
            "create_at": "                   ",
            "image": "/detect/trash/trash10.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91293174"
          },
          {
            "id": "1000851",
            "create_at": "                   ",
            "image": "/detect/trash/trash11.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89758259"
          },
          {
            "id": "1000852",
            "create_at": "                   ",
            "image": "/detect/trash/trash12.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88907307"
          },
          {
            "id": "1000853",
            "create_at": "                   ",
            "image": "/detect/trash/trash13.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90649522"
          },
          {
            "id": "1000854",
            "create_at": "                   ",
            "image": "/detect/trash/trash14.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89165220"
          },
          {
            "id": "1000855",
            "create_at": "                   ",
            "image": "/detect/trash/trash15.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91284790"
          },
          {
            "id": "1000856",
            "create_at": "                   ",
            "image": "/detect/trash/trash16.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90861257"
          },
          {
            "id": "1000857",
            "create_at": "                   ",
            "image": "/detect/trash/trash17.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91008192"
          },
          {
            "id": "1000858",
            "create_at": "                   ",
            "image": "/detect/trash/trash18.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91309670"
          },
          {
            "id": "1000859",
            "create_at": "                   ",
            "image": "/detect/trash/trash19.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90000598"
          },
          {
            "id": "1000860",
            "create_at": "                   ",
            "image": "/detect/trash/trash20.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88502816"
          },
          {
            "id": "1000861",
            "create_at": "                   ",
            "image": "/detect/trash/trash21.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89087934"
          },
          {
            "id": "1000862",
            "create_at": "                   ",
            "image": "/detect/trash/trash22.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90395184"
          },
          {
            "id": "1000863",
            "create_at": "                   ",
            "image": "/detect/trash/trash23.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91351172"
          },
          {
            "id": "1000864",
            "create_at": "                   ",
            "image": "/detect/trash/trash24.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91008539"
          },
          {
            "id": "1000865",
            "create_at": "                   ",
            "image": "/detect/trash/trash25.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87160428"
          },
          {
            "id": "1000866",
            "create_at": "                   ",
            "image": "/detect/trash/trash26.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88380489"
          },
          {
            "id": "1000867",
            "create_at": "                   ",
            "image": "/detect/trash/trash27.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87834124"
          },
          {
            "id": "1000868",
            "create_at": "                   ",
            "image": "/detect/trash/trash28.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87653957"
          },
          {
            "id": "1000869",
            "create_at": "                   ",
            "image": "/detect/trash/trash29.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87673047"
          },
          {
            "id": "1000870",
            "create_at": "                   ",
            "image": "/detect/trash/trash30.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91093444"
          },
          {
            "id": "1000871",
            "create_at": "                   ",
            "image": "/detect/trash/trash31.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89850257"
          },
          {
            "id": "1000872",
            "create_at": "                   ",
            "image": "/detect/trash/trash32.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87968062"
          },
          {
            "id": "1000873",
            "create_at": "                   ",
            "image": "/detect/trash/trash33.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91529018"
          },
          {
            "id": "1000874",
            "create_at": "                   ",
            "image": "/detect/trash/trash34.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91581095"
          },
          {
            "id": "1000875",
            "create_at": "                   ",
            "image": "/detect/trash/trash35.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87884276"
          },
          {
            "id": "1000876",
            "create_at": "                   ",
            "image": "/detect/trash/trash36.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90262328"
          },
          {
            "id": "1000877",
            "create_at": "                   ",
            "image": "/detect/trash/trash37.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87356452"
          },
          {
            "id": "1000878",
            "create_at": "                   ",
            "image": "/detect/trash/trash38.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87303463"
          },
          {
            "id": "1000879",
            "create_at": "                   ",
            "image": "/detect/trash/trash39.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88991241"
          },
          {
            "id": "1000880",
            "create_at": "                   ",
            "image": "/detect/trash/trash40.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87829571"
          },
          {
            "id": "1000881",
            "create_at": "                   ",
            "image": "/detect/trash/trash41.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87777048"
          },
          {
            "id": "1000882",
            "create_at": "                   ",
            "image": "/detect/trash/trash42.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89725329"
          },
          {
            "id": "1000883",
            "create_at": "                   ",
            "image": "/detect/trash/trash43.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89158734"
          },
          {
            "id": "1000884",
            "create_at": "                   ",
            "image": "/detect/trash/trash44.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90134635"
          },
          {
            "id": "1000885",
            "create_at": "                   ",
            "image": "/detect/trash/trash45.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89300323"
          },
          {
            "id": "1000886",
            "create_at": "                   ",
            "image": "/detect/trash/trash46.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90629145"
          },
          {
            "id": "1000887",
            "create_at": "                   ",
            "image": "/detect/trash/trash47.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89515178"
          },
          {
            "id": "1000888",
            "create_at": "                   ",
            "image": "/detect/trash/trash48.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90612640"
          },
          {
            "id": "1000889",
            "create_at": "                   ",
            "image": "/detect/trash/trash49.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90257185"
          },
          {
            "id": "1000890",
            "create_at": "                   ",
            "image": "/detect/trash/trash50.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89623106"
          },
          {
            "id": "1000891",
            "create_at": "                   ",
            "image": "/detect/trash/trash51.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89246188"
          },
          {
            "id": "1000892",
            "create_at": "                   ",
            "image": "/detect/trash/trash52.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88200156"
          },
          {
            "id": "1000893",
            "create_at": "                   ",
            "image": "/detect/trash/trash53.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90662743"
          },
          {
            "id": "1000894",
            "create_at": "                   ",
            "image": "/detect/trash/trash54.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88734395"
          },
          {
            "id": "1000895",
            "create_at": "                   ",
            "image": "/detect/trash/trash55.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91519859"
          },
          {
            "id": "1000896",
            "create_at": "                   ",
            "image": "/detect/trash/trash56.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89919098"
          },
          {
            "id": "1000897",
            "create_at": "                   ",
            "image": "/detect/trash/trash57.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89289028"
          },
          {
            "id": "1000898",
            "create_at": "                   ",
            "image": "/detect/trash/trash58.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89956581"
          },
          {
            "id": "1000899",
            "create_at": "                   ",
            "image": "/detect/trash/trash59.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88081277"
          },
          {
            "id": "1000900",
            "create_at": "                   ",
            "image": "/detect/trash/trash60.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91702294"
          },
          {
            "id": "1000901",
            "create_at": "                   ",
            "image": "/detect/trash/trash61.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88664369"
          },
          {
            "id": "1000902",
            "create_at": "                   ",
            "image": "/detect/trash/trash62.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87617313"
          },
          {
            "id": "1000903",
            "create_at": "                   ",
            "image": "/detect/trash/trash63.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87396690"
          },
          {
            "id": "1000904",
            "create_at": "                   ",
            "image": "/detect/trash/trash64.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91686434"
          },
          {
            "id": "1000905",
            "create_at": "                   ",
            "image": "/detect/trash/trash65.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87062400"
          },
          {
            "id": "1000906",
            "create_at": "                   ",
            "image": "/detect/trash/trash66.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90386990"
          },
          {
            "id": "1000907",
            "create_at": "                   ",
            "image": "/detect/trash/trash67.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88081509"
          },
          {
            "id": "1000908",
            "create_at": "                   ",
            "image": "/detect/trash/trash68.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88207536"
          },
          {
            "id": "1000909",
            "create_at": "                   ",
            "image": "/detect/trash/trash69.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87236264"
          },
          {
            "id": "1000910",
            "create_at": "                   ",
            "image": "/detect/trash/trash70.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87190108"
          },
          {
            "id": "1000911",
            "create_at": "                   ",
            "image": "/detect/trash/trash71.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90697134"
          },
          {
            "id": "1000912",
            "create_at": "                   ",
            "image": "/detect/trash/trash72.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91090537"
          },
          {
            "id": "1000913",
            "create_at": "                   ",
            "image": "/detect/trash/trash73.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91619543"
          },
          {
            "id": "1000914",
            "create_at": "                   ",
            "image": "/detect/trash/trash74.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90733479"
          },
          {
            "id": "1000915",
            "create_at": "                   ",
            "image": "/detect/trash/trash75.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89517755"
          },
          {
            "id": "1000916",
            "create_at": "                   ",
            "image": "/detect/trash/trash76.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89587269"
          },
          {
            "id": "1000917",
            "create_at": "                   ",
            "image": "/detect/trash/trash77.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89735103"
          },
          {
            "id": "1000918",
            "create_at": "                   ",
            "image": "/detect/trash/trash78.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87864562"
          },
          {
            "id": "1000919",
            "create_at": "                   ",
            "image": "/detect/trash/trash79.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91729710"
          },
          {
            "id": "1000920",
            "create_at": "                   ",
            "image": "/detect/trash/trash80.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88207980"
          },
          {
            "id": "1000921",
            "create_at": "                   ",
            "image": "/detect/trash/trash81.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88699529"
          },
          {
            "id": "1000922",
            "create_at": "                   ",
            "image": "/detect/trash/trash82.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90040498"
          },
          {
            "id": "1000923",
            "create_at": "                   ",
            "image": "/detect/trash/trash83.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87942386"
          },
          {
            "id": "1000924",
            "create_at": "                   ",
            "image": "/detect/trash/trash84.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91298036"
          },
          {
            "id": "1000925",
            "create_at": "                   ",
            "image": "/detect/trash/trash85.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91664734"
          },
          {
            "id": "1000926",
            "create_at": "                   ",
            "image": "/detect/trash/trash86.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88491392"
          },
          {
            "id": "1000927",
            "create_at": "                   ",
            "image": "/detect/trash/trash87.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90091950"
          },
          {
            "id": "1000928",
            "create_at": "                   ",
            "image": "/detect/trash/trash88.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91137563"
          },
          {
            "id": "1000929",
            "create_at": "                   ",
            "image": "/detect/trash/trash89.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88278502"
          },
          {
            "id": "1000930",
            "create_at": "                   ",
            "image": "/detect/trash/trash90.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90792651"
          },
          {
            "id": "1000931",
            "create_at": "                   ",
            "image": "/detect/trash/trash91.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91522100"
          },
          {
            "id": "1000932",
            "create_at": "                   ",
            "image": "/detect/trash/trash92.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90784784"
          },
          {
            "id": "1000933",
            "create_at": "                   ",
            "image": "/detect/trash/trash93.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88320762"
          },
          {
            "id": "1000934",
            "create_at": "                   ",
            "image": "/detect/trash/trash94.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91081238"
          },
          {
            "id": "1000935",
            "create_at": "                   ",
            "image": "/detect/trash/trash95.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87782903"
          },
          {
            "id": "1000936",
            "create_at": "                   ",
            "image": "/detect/trash/trash96.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88546591"
          },
          {
            "id": "1000937",
            "create_at": "                   ",
            "image": "/detect/trash/trash97.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87837385"
          },
          {
            "id": "1000938",
            "create_at": "                   ",
            "image": "/detect/trash/trash98.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91396918"
          },
          {
            "id": "1000939",
            "create_at": "                   ",
            "image": "/detect/trash/trash99.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.91611863"
          },
          {
            "id": "1000940",
            "create_at": "                   ",
            "image": "/detect/trash/trash100.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88395535"
          },
          {
            "id": "1000941",
            "create_at": "                   ",
            "image": "/detect/trash/trash101.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90202330"
          },
          {
            "id": "1000942",
            "create_at": "                   ",
            "image": "/detect/trash/trash102.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89411728"
          },
          {
            "id": "1000943",
            "create_at": "                   ",
            "image": "/detect/trash/trash103.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90143132"
          },
          {
            "id": "1000944",
            "create_at": "                   ",
            "image": "/detect/trash/trash104.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.87026808"
          },
          {
            "id": "1000945",
            "create_at": "                   ",
            "image": "/detect/trash/trash105.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90733040"
          },
          {
            "id": "1000946",
            "create_at": "                   ",
            "image": "/detect/trash/trash106.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90390134"
          },
          {
            "id": "1000947",
            "create_at": "                   ",
            "image": "/detect/trash/trash107.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.88169796"
          },
          {
            "id": "1000948",
            "create_at": "                   ",
            "image": "/detect/trash/trash108.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.90429458"
          },
          {
            "id": "1000949",
            "create_at": "                   ",
            "image": "/detect/trash/trash109.png",
            "status": "탐지완료",
            "classification_type": "방치 쓰레기",
            "content": "방치 쓰레기",
            "comment": "평균 탐지율 : 0.89854201"
          }
        ])
     }}>#쓰레기 </a>

      <a style={{backgroundColor: '#f7b7d3', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("광고판")
        setNewsList([
          {
            "id": "1000000",
            "create_at": "                   ",
            "image": "/detect/ad/ad1.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89118225"
          },
          {
            "id": "1000001",
            "create_at": "                   ",
            "image": "/detect/ad/ad2.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87695745"
          },
          {
            "id": "1000002",
            "create_at": "                   ",
            "image": "/detect/ad/ad3.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89315093"
          },
          {
            "id": "1000003",
            "create_at": "                   ",
            "image": "/detect/ad/ad4.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88044197"
          },
          {
            "id": "1000004",
            "create_at": "                   ",
            "image": "/detect/ad/ad5.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88359553"
          },
          {
            "id": "1000005",
            "create_at": "                   ",
            "image": "/detect/ad/ad6.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91173375"
          },
          {
            "id": "1000006",
            "create_at": "                   ",
            "image": "/detect/ad/ad7.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89463159"
          },
          {
            "id": "1000007",
            "create_at": "                   ",
            "image": "/detect/ad/ad8.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88704573"
          },
          {
            "id": "1000008",
            "create_at": "                   ",
            "image": "/detect/ad/ad9.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88174752"
          },
          {
            "id": "1000009",
            "create_at": "                   ",
            "image": "/detect/ad/ad10.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88757919"
          },
          {
            "id": "1000010",
            "create_at": "                   ",
            "image": "/detect/ad/ad11.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89604176"
          },
          {
            "id": "1000011",
            "create_at": "                   ",
            "image": "/detect/ad/ad12.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87391336"
          },
          {
            "id": "1000012",
            "create_at": "                   ",
            "image": "/detect/ad/ad13.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89105611"
          },
          {
            "id": "1000013",
            "create_at": "                   ",
            "image": "/detect/ad/ad14.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90440521"
          },
          {
            "id": "1000014",
            "create_at": "                   ",
            "image": "/detect/ad/ad15.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88983339"
          },
          {
            "id": "1000015",
            "create_at": "                   ",
            "image": "/detect/ad/ad16.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90690849"
          },
          {
            "id": "1000016",
            "create_at": "                   ",
            "image": "/detect/ad/ad17.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88633239"
          },
          {
            "id": "1000017",
            "create_at": "                   ",
            "image": "/detect/ad/ad18.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90502614"
          },
          {
            "id": "1000018",
            "create_at": "                   ",
            "image": "/detect/ad/ad19.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89885278"
          },
          {
            "id": "1000019",
            "create_at": "                   ",
            "image": "/detect/ad/ad20.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90193845"
          },
          {
            "id": "1000020",
            "create_at": "                   ",
            "image": "/detect/ad/ad21.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88513917"
          },
          {
            "id": "1000021",
            "create_at": "                   ",
            "image": "/detect/ad/ad22.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88394189"
          },
          {
            "id": "1000022",
            "create_at": "                   ",
            "image": "/detect/ad/ad23.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87996189"
          },
          {
            "id": "1000023",
            "create_at": "                   ",
            "image": "/detect/ad/ad24.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90665444"
          },
          {
            "id": "1000024",
            "create_at": "                   ",
            "image": "/detect/ad/ad25.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91572354"
          },
          {
            "id": "1000025",
            "create_at": "                   ",
            "image": "/detect/ad/ad26.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89125447"
          },
          {
            "id": "1000026",
            "create_at": "                   ",
            "image": "/detect/ad/ad27.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88750844"
          },
          {
            "id": "1000027",
            "create_at": "                   ",
            "image": "/detect/ad/ad28.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87989409"
          },
          {
            "id": "1000028",
            "create_at": "                   ",
            "image": "/detect/ad/ad29.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89654273"
          },
          {
            "id": "1000029",
            "create_at": "                   ",
            "image": "/detect/ad/ad30.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91730013"
          },
          {
            "id": "1000030",
            "create_at": "                   ",
            "image": "/detect/ad/ad31.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90041804"
          },
          {
            "id": "1000031",
            "create_at": "                   ",
            "image": "/detect/ad/ad32.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87248076"
          },
          {
            "id": "1000032",
            "create_at": "                   ",
            "image": "/detect/ad/ad33.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89520642"
          },
          {
            "id": "1000033",
            "create_at": "                   ",
            "image": "/detect/ad/ad34.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87089627"
          },
          {
            "id": "1000034",
            "create_at": "                   ",
            "image": "/detect/ad/ad35.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87495193"
          },
          {
            "id": "1000035",
            "create_at": "                   ",
            "image": "/detect/ad/ad36.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89608851"
          },
          {
            "id": "1000036",
            "create_at": "                   ",
            "image": "/detect/ad/ad37.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87981321"
          },
          {
            "id": "1000037",
            "create_at": "                   ",
            "image": "/detect/ad/ad38.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90280296"
          },
          {
            "id": "1000038",
            "create_at": "                   ",
            "image": "/detect/ad/ad39.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88447190"
          },
          {
            "id": "1000039",
            "create_at": "                   ",
            "image": "/detect/ad/ad40.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90178940"
          },
          {
            "id": "1000040",
            "create_at": "                   ",
            "image": "/detect/ad/ad41.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87775984"
          },
          {
            "id": "1000041",
            "create_at": "                   ",
            "image": "/detect/ad/ad42.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90956783"
          },
          {
            "id": "1000042",
            "create_at": "                   ",
            "image": "/detect/ad/ad43.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88375916"
          },
          {
            "id": "1000043",
            "create_at": "                   ",
            "image": "/detect/ad/ad44.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89023566"
          },
          {
            "id": "1000044",
            "create_at": "                   ",
            "image": "/detect/ad/ad45.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88763222"
          },
          {
            "id": "1000045",
            "create_at": "                   ",
            "image": "/detect/ad/ad46.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90997532"
          },
          {
            "id": "1000046",
            "create_at": "                   ",
            "image": "/detect/ad/ad47.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88245253"
          },
          {
            "id": "1000047",
            "create_at": "                   ",
            "image": "/detect/ad/ad48.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87049034"
          },
          {
            "id": "1000048",
            "create_at": "                   ",
            "image": "/detect/ad/ad49.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90542125"
          },
          {
            "id": "1000049",
            "create_at": "                   ",
            "image": "/detect/ad/ad50.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88470132"
          },
          {
            "id": "1000050",
            "create_at": "                   ",
            "image": "/detect/ad/ad51.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88031064"
          },
          {
            "id": "1000051",
            "create_at": "                   ",
            "image": "/detect/ad/ad52.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87989022"
          },
          {
            "id": "1000052",
            "create_at": "                   ",
            "image": "/detect/ad/ad53.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87455477"
          },
          {
            "id": "1000053",
            "create_at": "                   ",
            "image": "/detect/ad/ad54.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89562363"
          },
          {
            "id": "1000054",
            "create_at": "                   ",
            "image": "/detect/ad/ad55.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89604557"
          },
          {
            "id": "1000055",
            "create_at": "                   ",
            "image": "/detect/ad/ad56.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90195906"
          },
          {
            "id": "1000056",
            "create_at": "                   ",
            "image": "/detect/ad/ad57.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91191036"
          },
          {
            "id": "1000057",
            "create_at": "                   ",
            "image": "/detect/ad/ad58.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88589576"
          },
          {
            "id": "1000058",
            "create_at": "                   ",
            "image": "/detect/ad/ad59.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88197240"
          },
          {
            "id": "1000059",
            "create_at": "                   ",
            "image": "/detect/ad/ad60.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88643764"
          },
          {
            "id": "1000060",
            "create_at": "                   ",
            "image": "/detect/ad/ad61.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91511654"
          },
          {
            "id": "1000061",
            "create_at": "                   ",
            "image": "/detect/ad/ad62.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87787244"
          },
          {
            "id": "1000062",
            "create_at": "                   ",
            "image": "/detect/ad/ad63.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90552767"
          },
          {
            "id": "1000063",
            "create_at": "                   ",
            "image": "/detect/ad/ad64.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89873935"
          },
          {
            "id": "1000064",
            "create_at": "                   ",
            "image": "/detect/ad/ad65.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88467103"
          },
          {
            "id": "1000065",
            "create_at": "                   ",
            "image": "/detect/ad/ad66.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88015334"
          },
          {
            "id": "1000066",
            "create_at": "                   ",
            "image": "/detect/ad/ad67.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87417511"
          },
          {
            "id": "1000067",
            "create_at": "                   ",
            "image": "/detect/ad/ad68.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89755544"
          },
          {
            "id": "1000068",
            "create_at": "                   ",
            "image": "/detect/ad/ad69.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91577749"
          },
          {
            "id": "1000069",
            "create_at": "                   ",
            "image": "/detect/ad/ad70.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87719171"
          },
          {
            "id": "1000070",
            "create_at": "                   ",
            "image": "/detect/ad/ad71.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89983020"
          },
          {
            "id": "1000071",
            "create_at": "                   ",
            "image": "/detect/ad/ad72.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87933523"
          },
          {
            "id": "1000072",
            "create_at": "                   ",
            "image": "/detect/ad/ad73.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88971077"
          },
          {
            "id": "1000073",
            "create_at": "                   ",
            "image": "/detect/ad/ad74.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89897145"
          },
          {
            "id": "1000074",
            "create_at": "                   ",
            "image": "/detect/ad/ad75.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90935236"
          },
          {
            "id": "1000075",
            "create_at": "                   ",
            "image": "/detect/ad/ad76.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87103067"
          },
          {
            "id": "1000076",
            "create_at": "                   ",
            "image": "/detect/ad/ad77.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91435115"
          },
          {
            "id": "1000077",
            "create_at": "                   ",
            "image": "/detect/ad/ad78.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90964669"
          },
          {
            "id": "1000078",
            "create_at": "                   ",
            "image": "/detect/ad/ad79.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90579782"
          },
          {
            "id": "1000079",
            "create_at": "                   ",
            "image": "/detect/ad/ad80.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91106902"
          },
          {
            "id": "1000080",
            "create_at": "                   ",
            "image": "/detect/ad/ad81.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87134815"
          },
          {
            "id": "1000081",
            "create_at": "                   ",
            "image": "/detect/ad/ad82.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91052331"
          },
          {
            "id": "1000082",
            "create_at": "                   ",
            "image": "/detect/ad/ad83.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88354337"
          },
          {
            "id": "1000083",
            "create_at": "                   ",
            "image": "/detect/ad/ad84.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89434876"
          },
          {
            "id": "1000084",
            "create_at": "                   ",
            "image": "/detect/ad/ad85.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88453758"
          },
          {
            "id": "1000085",
            "create_at": "                   ",
            "image": "/detect/ad/ad86.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88547568"
          },
          {
            "id": "1000086",
            "create_at": "                   ",
            "image": "/detect/ad/ad87.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88439437"
          },
          {
            "id": "1000087",
            "create_at": "                   ",
            "image": "/detect/ad/ad88.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90797192"
          },
          {
            "id": "1000088",
            "create_at": "                   ",
            "image": "/detect/ad/ad89.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87031248"
          },
          {
            "id": "1000089",
            "create_at": "                   ",
            "image": "/detect/ad/ad90.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90656088"
          },
          {
            "id": "1000090",
            "create_at": "                   ",
            "image": "/detect/ad/ad91.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87251489"
          },
          {
            "id": "1000091",
            "create_at": "                   ",
            "image": "/detect/ad/ad92.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.89277237"
          },
          {
            "id": "1000092",
            "create_at": "                   ",
            "image": "/detect/ad/ad93.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90972141"
          },
          {
            "id": "1000093",
            "create_at": "                   ",
            "image": "/detect/ad/ad94.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87936256"
          },
          {
            "id": "1000094",
            "create_at": "                   ",
            "image": "/detect/ad/ad95.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87373591"
          },
          {
            "id": "1000095",
            "create_at": "                   ",
            "image": "/detect/ad/ad96.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90791320"
          },
          {
            "id": "1000096",
            "create_at": "                   ",
            "image": "/detect/ad/ad97.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91533719"
          },
          {
            "id": "1000097",
            "create_at": "                   ",
            "image": "/detect/ad/ad98.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87997290"
          },
          {
            "id": "1000098",
            "create_at": "                   ",
            "image": "/detect/ad/ad99.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.87345496"
          },
          {
            "id": "1000099",
            "create_at": "                   ",
            "image": "/detect/ad/ad100.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.88576116"
          },
          {
            "id": "1000100",
            "create_at": "                   ",
            "image": "/detect/ad/ad101.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.90426583"
          },
          {
            "id": "1000101",
            "create_at": "                   ",
            "image": "/detect/ad/ad102.png",
            "status": "탐지완료",
            "classification_type": "광고판",
            "content": "광고판",
            "comment": "평균 탐지율 : 0.91124534"
          }
        ])
     }}>#광고판 </a>

      <a style={{backgroundColor: '#85f796', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("킥보드")
        setNewsList([
          {
            "id": "1000414",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard1.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90720420"
          },
          {
            "id": "1000415",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard2.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87745536"
          },
          {
            "id": "1000416",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard3.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91031997"
          },
          {
            "id": "1000417",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard4.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88375212"
          },
          {
            "id": "1000418",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard5.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90809675"
          },
          {
            "id": "1000419",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard6.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89142422"
          },
          {
            "id": "1000420",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard7.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87583862"
          },
          {
            "id": "1000421",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard8.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87350297"
          },
          {
            "id": "1000422",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard9.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91232494"
          },
          {
            "id": "1000423",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard10.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87610791"
          },
          {
            "id": "1000424",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard11.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89238398"
          },
          {
            "id": "1000425",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard12.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87712009"
          },
          {
            "id": "1000426",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard13.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90314068"
          },
          {
            "id": "1000427",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard14.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88677077"
          },
          {
            "id": "1000428",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard15.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88418127"
          },
          {
            "id": "1000429",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard16.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89002920"
          },
          {
            "id": "1000430",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard17.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87271244"
          },
          {
            "id": "1000431",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard18.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91314723"
          },
          {
            "id": "1000432",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard19.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91471972"
          },
          {
            "id": "1000433",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard20.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90684797"
          },
          {
            "id": "1000434",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard21.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90165709"
          },
          {
            "id": "1000435",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard22.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89718619"
          },
          {
            "id": "1000436",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard23.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87032365"
          },
          {
            "id": "1000437",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard24.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87151642"
          },
          {
            "id": "1000438",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard25.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91140293"
          },
          {
            "id": "1000439",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard26.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88652190"
          },
          {
            "id": "1000440",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard27.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87727570"
          },
          {
            "id": "1000441",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard28.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88276207"
          },
          {
            "id": "1000442",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard29.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88042819"
          },
          {
            "id": "1000443",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard30.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90509839"
          },
          {
            "id": "1000444",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard31.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89508993"
          },
          {
            "id": "1000445",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard32.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90155695"
          },
          {
            "id": "1000446",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard33.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89212496"
          },
          {
            "id": "1000447",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard34.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89591600"
          },
          {
            "id": "1000448",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard35.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91600285"
          },
          {
            "id": "1000449",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard36.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87653903"
          },
          {
            "id": "1000450",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard37.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91569900"
          },
          {
            "id": "1000451",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard38.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87922033"
          },
          {
            "id": "1000452",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard39.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88880125"
          },
          {
            "id": "1000453",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard40.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88851707"
          },
          {
            "id": "1000454",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard41.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87791235"
          },
          {
            "id": "1000455",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard42.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88759124"
          },
          {
            "id": "1000456",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard43.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87516104"
          },
          {
            "id": "1000457",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard44.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89156299"
          },
          {
            "id": "1000458",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard45.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88603535"
          },
          {
            "id": "1000459",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard46.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91597283"
          },
          {
            "id": "1000460",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard47.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89591986"
          },
          {
            "id": "1000461",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard48.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88405690"
          },
          {
            "id": "1000462",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard49.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89837021"
          },
          {
            "id": "1000463",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard50.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90122999"
          },
          {
            "id": "1000464",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard51.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87960044"
          },
          {
            "id": "1000465",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard52.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91280054"
          },
          {
            "id": "1000466",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard53.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90620416"
          },
          {
            "id": "1000467",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard54.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89624342"
          },
          {
            "id": "1000468",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard55.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89976793"
          },
          {
            "id": "1000469",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard56.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90323451"
          },
          {
            "id": "1000470",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard57.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87511065"
          },
          {
            "id": "1000471",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard58.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87481703"
          },
          {
            "id": "1000472",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard59.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89645869"
          },
          {
            "id": "1000473",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard60.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89876102"
          },
          {
            "id": "1000474",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard61.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88134659"
          },
          {
            "id": "1000475",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard62.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90900242"
          },
          {
            "id": "1000476",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard63.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87360649"
          },
          {
            "id": "1000477",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard64.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88495112"
          },
          {
            "id": "1000478",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard65.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87323117"
          },
          {
            "id": "1000479",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard66.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87163662"
          },
          {
            "id": "1000480",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard67.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90021692"
          },
          {
            "id": "1000481",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard68.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87637137"
          },
          {
            "id": "1000482",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard69.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91425044"
          },
          {
            "id": "1000483",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard70.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91490782"
          },
          {
            "id": "1000484",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard71.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90932398"
          },
          {
            "id": "1000485",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard72.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89060198"
          },
          {
            "id": "1000486",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard73.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90675100"
          },
          {
            "id": "1000487",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard74.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89344860"
          },
          {
            "id": "1000488",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard75.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88782999"
          },
          {
            "id": "1000489",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard76.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91451468"
          },
          {
            "id": "1000490",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard77.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90446414"
          },
          {
            "id": "1000491",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard78.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90179596"
          },
          {
            "id": "1000492",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard79.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90408890"
          },
          {
            "id": "1000493",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard80.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90476143"
          },
          {
            "id": "1000494",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard81.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90034285"
          },
          {
            "id": "1000495",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard82.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87158353"
          },
          {
            "id": "1000496",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard83.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88320893"
          },
          {
            "id": "1000497",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard84.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87523346"
          },
          {
            "id": "1000498",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard85.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88369951"
          },
          {
            "id": "1000499",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard86.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91668831"
          },
          {
            "id": "1000500",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard87.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91482445"
          },
          {
            "id": "1000501",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard88.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87282437"
          },
          {
            "id": "1000502",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard89.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89819634"
          },
          {
            "id": "1000503",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard90.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88919915"
          },
          {
            "id": "1000504",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard91.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90279455"
          },
          {
            "id": "1000505",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard92.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87366276"
          },
          {
            "id": "1000506",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard93.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91259376"
          },
          {
            "id": "1000507",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard94.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87290997"
          },
          {
            "id": "1000508",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard95.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90894076"
          },
          {
            "id": "1000509",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard96.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88816059"
          },
          {
            "id": "1000510",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard97.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89796663"
          },
          {
            "id": "1000511",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard98.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.91547786"
          },
          {
            "id": "1000512",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard99.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90379741"
          },
          {
            "id": "1000513",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard100.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88025466"
          },
          {
            "id": "1000514",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard101.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87987681"
          },
          {
            "id": "1000515",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard102.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.89672030"
          },
          {
            "id": "1000516",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard103.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.90232846"
          },
          {
            "id": "1000517",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard104.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.87313438"
          },
          {
            "id": "1000518",
            "create_at": "                   ",
            "image": "/detect/kickboard/kickboard105.png",
            "status": "탐지완료",
            "classification_type": "전동킥보드",
            "content": "전동킥보드",
            "comment": "평균 탐지율 : 0.88478864"
          }
        ])
     }}>#전동킥보드 </a>

      <a style={{backgroundColor: '#96dbf2', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("럼블스트립")
        setNewsList([
          {
            "id": "1000726",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble1.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87938060"
          },
          {
            "id": "1000727",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble2.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88415201"
          },
          {
            "id": "1000728",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble3.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89104247"
          },
          {
            "id": "1000729",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble4.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87643857"
          },
          {
            "id": "1000730",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble5.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90976835"
          },
          {
            "id": "1000731",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble6.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88793748"
          },
          {
            "id": "1000732",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble7.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88922605"
          },
          {
            "id": "1000733",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble8.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90322317"
          },
          {
            "id": "1000734",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble9.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90426421"
          },
          {
            "id": "1000735",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble10.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89477489"
          },
          {
            "id": "1000736",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble11.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89735345"
          },
          {
            "id": "1000737",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble12.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87738606"
          },
          {
            "id": "1000738",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble13.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90100424"
          },
          {
            "id": "1000739",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble14.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90048599"
          },
          {
            "id": "1000740",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble15.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89139047"
          },
          {
            "id": "1000741",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble16.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88717512"
          },
          {
            "id": "1000742",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble17.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91568330"
          },
          {
            "id": "1000743",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble18.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90553535"
          },
          {
            "id": "1000744",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble19.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89313266"
          },
          {
            "id": "1000745",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble20.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89612566"
          },
          {
            "id": "1000746",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble21.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88534395"
          },
          {
            "id": "1000747",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble22.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87834157"
          },
          {
            "id": "1000748",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble23.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90505384"
          },
          {
            "id": "1000749",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble24.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87992700"
          },
          {
            "id": "1000750",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble25.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87497145"
          },
          {
            "id": "1000751",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble26.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90272746"
          },
          {
            "id": "1000752",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble27.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90353884"
          },
          {
            "id": "1000753",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble28.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90762845"
          },
          {
            "id": "1000754",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble29.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90807305"
          },
          {
            "id": "1000755",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble30.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90537798"
          },
          {
            "id": "1000756",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble31.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89454089"
          },
          {
            "id": "1000757",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble32.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88585439"
          },
          {
            "id": "1000758",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble33.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87808639"
          },
          {
            "id": "1000759",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble34.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87330133"
          },
          {
            "id": "1000760",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble35.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87480135"
          },
          {
            "id": "1000761",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble36.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89988300"
          },
          {
            "id": "1000762",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble37.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90407415"
          },
          {
            "id": "1000763",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble38.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91342423"
          },
          {
            "id": "1000764",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble39.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87239799"
          },
          {
            "id": "1000765",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble40.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90243301"
          },
          {
            "id": "1000766",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble41.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91719986"
          },
          {
            "id": "1000767",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble42.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91489738"
          },
          {
            "id": "1000768",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble43.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91477686"
          },
          {
            "id": "1000769",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble44.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90731015"
          },
          {
            "id": "1000770",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble45.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91168813"
          },
          {
            "id": "1000771",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble46.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89514850"
          },
          {
            "id": "1000772",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble47.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90491451"
          },
          {
            "id": "1000773",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble48.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90827298"
          },
          {
            "id": "1000774",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble49.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90073349"
          },
          {
            "id": "1000775",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble50.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87817699"
          },
          {
            "id": "1000776",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble51.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89372398"
          },
          {
            "id": "1000777",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble52.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90061110"
          },
          {
            "id": "1000778",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble53.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90272281"
          },
          {
            "id": "1000779",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble54.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90399036"
          },
          {
            "id": "1000780",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble55.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87683734"
          },
          {
            "id": "1000781",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble56.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88446578"
          },
          {
            "id": "1000782",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble57.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88600837"
          },
          {
            "id": "1000783",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble58.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91570661"
          },
          {
            "id": "1000784",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble59.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89560456"
          },
          {
            "id": "1000785",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble60.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88558882"
          },
          {
            "id": "1000786",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble61.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89373841"
          },
          {
            "id": "1000787",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble62.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90868042"
          },
          {
            "id": "1000788",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble63.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89644063"
          },
          {
            "id": "1000789",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble64.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87396818"
          },
          {
            "id": "1000790",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble65.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90578772"
          },
          {
            "id": "1000791",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble66.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87580676"
          },
          {
            "id": "1000792",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble67.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88595744"
          },
          {
            "id": "1000793",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble68.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91140495"
          },
          {
            "id": "1000794",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble69.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89075871"
          },
          {
            "id": "1000795",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble70.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90461247"
          },
          {
            "id": "1000796",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble71.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90304648"
          },
          {
            "id": "1000797",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble72.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87804223"
          },
          {
            "id": "1000798",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble73.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91254525"
          },
          {
            "id": "1000799",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble74.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90560706"
          },
          {
            "id": "1000800",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble75.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90940340"
          },
          {
            "id": "1000801",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble76.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91703547"
          },
          {
            "id": "1000802",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble77.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91215451"
          },
          {
            "id": "1000803",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble78.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87650803"
          },
          {
            "id": "1000804",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble79.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90747552"
          },
          {
            "id": "1000805",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble80.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87007970"
          },
          {
            "id": "1000806",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble81.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89572098"
          },
          {
            "id": "1000807",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble82.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89241214"
          },
          {
            "id": "1000808",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble83.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88325363"
          },
          {
            "id": "1000809",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble84.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87494223"
          },
          {
            "id": "1000810",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble85.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87931340"
          },
          {
            "id": "1000811",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble86.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89914924"
          },
          {
            "id": "1000812",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble87.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87444808"
          },
          {
            "id": "1000813",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble88.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87802950"
          },
          {
            "id": "1000814",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble89.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87111615"
          },
          {
            "id": "1000815",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble90.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91108640"
          },
          {
            "id": "1000816",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble91.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87612882"
          },
          {
            "id": "1000817",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble92.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89403316"
          },
          {
            "id": "1000818",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble93.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87742555"
          },
          {
            "id": "1000819",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble94.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89256439"
          },
          {
            "id": "1000820",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble95.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88956820"
          },
          {
            "id": "1000821",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble96.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89620128"
          },
          {
            "id": "1000822",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble97.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90562958"
          },
          {
            "id": "1000823",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble98.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90313714"
          },
          {
            "id": "1000824",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble99.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88635937"
          },
          {
            "id": "1000825",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble100.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91691723"
          },
          {
            "id": "1000826",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble101.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88397558"
          },
          {
            "id": "1000827",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble102.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88982821"
          },
          {
            "id": "1000828",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble103.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90256438"
          },
          {
            "id": "1000829",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble104.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89593358"
          },
          {
            "id": "1000830",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble105.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87713160"
          },
          {
            "id": "1000831",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble106.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87783176"
          },
          {
            "id": "1000832",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble107.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91379116"
          },
          {
            "id": "1000833",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble108.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.87017129"
          },
          {
            "id": "1000834",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble109.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.90640817"
          },
          {
            "id": "1000835",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble110.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89920932"
          },
          {
            "id": "1000836",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble111.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88495463"
          },
          {
            "id": "1000837",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble112.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.91329085"
          },
          {
            "id": "1000838",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble113.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.89130054"
          },
          {
            "id": "1000839",
            "create_at": "                   ",
            "image": "/detect/rumble/rumble114.png",
            "status": "탐지완료",
            "classification_type": "럼블스트립",
            "content": "럼블스트립",
            "comment": "평균 탐지율 : 0.88138255"
          }
        ])
     }}>#럼블스트립 </a>

      <a style={{backgroundColor: '#f7b976', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("쓰레기통")
        setNewsList([
          {
            "id": "1001053",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin1.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90826969"
          },
          {
            "id": "1001054",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin2.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89195223"
          },
          {
            "id": "1001055",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin3.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91068084"
          },
          {
            "id": "1001056",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin4.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88171159"
          },
          {
            "id": "1001057",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin5.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87544274"
          },
          {
            "id": "1001058",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin6.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90544237"
          },
          {
            "id": "1001059",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin7.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90216909"
          },
          {
            "id": "1001060",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin8.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87537020"
          },
          {
            "id": "1001061",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin9.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87492976"
          },
          {
            "id": "1001062",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin10.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87648682"
          },
          {
            "id": "1001063",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin11.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88076291"
          },
          {
            "id": "1001064",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin12.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88141593"
          },
          {
            "id": "1001065",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin13.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87938037"
          },
          {
            "id": "1001066",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin14.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88527518"
          },
          {
            "id": "1001067",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin15.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87891125"
          },
          {
            "id": "1001068",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin16.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89027521"
          },
          {
            "id": "1001069",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin17.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88332898"
          },
          {
            "id": "1001070",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin18.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87557713"
          },
          {
            "id": "1001071",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin19.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88924492"
          },
          {
            "id": "1001072",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin20.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87483891"
          },
          {
            "id": "1001073",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin21.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90872712"
          },
          {
            "id": "1001074",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin22.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87459012"
          },
          {
            "id": "1001075",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin23.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90915302"
          },
          {
            "id": "1001076",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin24.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88900951"
          },
          {
            "id": "1001077",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin25.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90704947"
          },
          {
            "id": "1001078",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin26.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88220305"
          },
          {
            "id": "1001079",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin27.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89433947"
          },
          {
            "id": "1001080",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin28.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88879535"
          },
          {
            "id": "1001081",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin29.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88642550"
          },
          {
            "id": "1001082",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin30.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88912303"
          },
          {
            "id": "1001083",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin31.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89761731"
          },
          {
            "id": "1001084",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin32.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91515671"
          },
          {
            "id": "1001085",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin33.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91150459"
          },
          {
            "id": "1001086",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin34.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90551206"
          },
          {
            "id": "1001087",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin35.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90099244"
          },
          {
            "id": "1001088",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin36.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90186074"
          },
          {
            "id": "1001089",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin37.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88868363"
          },
          {
            "id": "1001090",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin38.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88514299"
          },
          {
            "id": "1001091",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin39.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90979286"
          },
          {
            "id": "1001092",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin40.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88842282"
          },
          {
            "id": "1001093",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin41.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91022313"
          },
          {
            "id": "1001094",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin42.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88227590"
          },
          {
            "id": "1001095",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin43.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88513354"
          },
          {
            "id": "1001096",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin44.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87715230"
          },
          {
            "id": "1001097",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin45.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90533981"
          },
          {
            "id": "1001098",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin46.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87083328"
          },
          {
            "id": "1001099",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin47.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90304570"
          },
          {
            "id": "1001100",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin48.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88493126"
          },
          {
            "id": "1001101",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin49.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87652567"
          },
          {
            "id": "1001102",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin50.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91196819"
          },
          {
            "id": "1001103",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin51.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90236397"
          },
          {
            "id": "1001104",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin52.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90223156"
          },
          {
            "id": "1001105",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin53.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88692054"
          },
          {
            "id": "1001106",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin54.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89582572"
          },
          {
            "id": "1001107",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin55.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88008819"
          },
          {
            "id": "1001108",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin56.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90875808"
          },
          {
            "id": "1001109",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin57.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90628262"
          },
          {
            "id": "1001110",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin58.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88318989"
          },
          {
            "id": "1001111",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin59.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90772549"
          },
          {
            "id": "1001112",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin60.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89136848"
          },
          {
            "id": "1001113",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin61.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91270550"
          },
          {
            "id": "1001114",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin62.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87715721"
          },
          {
            "id": "1001115",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin63.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89855552"
          },
          {
            "id": "1001116",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin64.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90758177"
          },
          {
            "id": "1001117",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin65.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87899614"
          },
          {
            "id": "1001118",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin66.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89684340"
          },
          {
            "id": "1001119",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin67.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89100977"
          },
          {
            "id": "1001120",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin68.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87337317"
          },
          {
            "id": "1001121",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin69.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91015403"
          },
          {
            "id": "1001122",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin70.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87376048"
          },
          {
            "id": "1001123",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin71.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91586057"
          },
          {
            "id": "1001124",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin72.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89554272"
          },
          {
            "id": "1001125",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin73.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90341454"
          },
          {
            "id": "1001126",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin74.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90695466"
          },
          {
            "id": "1001127",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin75.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90958782"
          },
          {
            "id": "1001128",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin76.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88186611"
          },
          {
            "id": "1001129",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin77.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88290464"
          },
          {
            "id": "1001130",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin78.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90462321"
          },
          {
            "id": "1001131",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin79.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89521263"
          },
          {
            "id": "1001132",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin80.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88387596"
          },
          {
            "id": "1001133",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin81.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91283633"
          },
          {
            "id": "1001134",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin82.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90446675"
          },
          {
            "id": "1001135",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin83.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88350568"
          },
          {
            "id": "1001136",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin84.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91652879"
          },
          {
            "id": "1001137",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin85.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88537957"
          },
          {
            "id": "1001138",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin86.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88070148"
          },
          {
            "id": "1001139",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin87.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91695834"
          },
          {
            "id": "1001140",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin88.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.91564931"
          },
          {
            "id": "1001141",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin89.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90191878"
          },
          {
            "id": "1001142",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin90.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90563461"
          },
          {
            "id": "1001143",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin91.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87529384"
          },
          {
            "id": "1001144",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin92.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.88054809"
          },
          {
            "id": "1001145",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin93.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87439199"
          },
          {
            "id": "1001146",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin94.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90149910"
          },
          {
            "id": "1001147",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin95.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90987063"
          },
          {
            "id": "1001148",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin96.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87809873"
          },
          {
            "id": "1001149",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin97.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89839975"
          },
          {
            "id": "1001150",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin98.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89116379"
          },
          {
            "id": "1001151",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin99.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.89019161"
          },
          {
            "id": "1001152",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin100.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87806718"
          },
          {
            "id": "1001153",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin101.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.87977512"
          },
          {
            "id": "1001154",
            "create_at": "                   ",
            "image": "/detect/trashbin/trashbin102.png",
            "status": "탐지완료",
            "classification_type": "공공 쓰레기통",
            "content": "공공 쓰레기통",
            "comment": "평균 탐지율 : 0.90116619"
          }
        ])
     }}>#쓰레기통 </a>

      <a style={{backgroundColor: '#7ecdea', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("폐타이어")
        setNewsList([
          {
            "id": "1001155",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire1.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89633087"
          },
          {
            "id": "1001156",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire2.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90110327"
          },
          {
            "id": "1001157",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire3.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91338000"
          },
          {
            "id": "1001158",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire4.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90346115"
          },
          {
            "id": "1001159",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire5.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88720164"
          },
          {
            "id": "1001160",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire6.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88167424"
          },
          {
            "id": "1001161",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire7.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90112307"
          },
          {
            "id": "1001162",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire8.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87712679"
          },
          {
            "id": "1001163",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire9.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89093850"
          },
          {
            "id": "1001164",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire10.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88517388"
          },
          {
            "id": "1001165",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire11.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88433649"
          },
          {
            "id": "1001166",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire12.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87511381"
          },
          {
            "id": "1001167",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire13.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89131383"
          },
          {
            "id": "1001168",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire14.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87079011"
          },
          {
            "id": "1001169",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire15.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89417804"
          },
          {
            "id": "1001170",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire16.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89346885"
          },
          {
            "id": "1001171",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire17.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88764269"
          },
          {
            "id": "1001172",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire18.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89700003"
          },
          {
            "id": "1001173",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire19.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89909260"
          },
          {
            "id": "1001174",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire20.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89987991"
          },
          {
            "id": "1001175",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire21.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90279117"
          },
          {
            "id": "1001176",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire22.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91135384"
          },
          {
            "id": "1001177",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire23.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90355468"
          },
          {
            "id": "1001178",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire24.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88808545"
          },
          {
            "id": "1001179",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire25.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87463931"
          },
          {
            "id": "1001180",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire26.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89134140"
          },
          {
            "id": "1001181",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire27.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91330610"
          },
          {
            "id": "1001182",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire28.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88613735"
          },
          {
            "id": "1001183",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire29.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91705511"
          },
          {
            "id": "1001184",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire30.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90682151"
          },
          {
            "id": "1001185",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire31.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89973961"
          },
          {
            "id": "1001186",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire32.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90404540"
          },
          {
            "id": "1001187",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire33.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91502858"
          },
          {
            "id": "1001188",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire34.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91578966"
          },
          {
            "id": "1001189",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire35.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89903843"
          },
          {
            "id": "1001190",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire36.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89307316"
          },
          {
            "id": "1001191",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire37.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87240171"
          },
          {
            "id": "1001192",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire38.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89121472"
          },
          {
            "id": "1001193",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire39.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91519210"
          },
          {
            "id": "1001194",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire40.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87468624"
          },
          {
            "id": "1001195",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire41.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90324215"
          },
          {
            "id": "1001196",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire42.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88511282"
          },
          {
            "id": "1001197",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire43.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87198332"
          },
          {
            "id": "1001198",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire44.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90490634"
          },
          {
            "id": "1001199",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire45.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91620179"
          },
          {
            "id": "1001200",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire46.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90980292"
          },
          {
            "id": "1001201",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire47.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90421993"
          },
          {
            "id": "1001202",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire48.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90944318"
          },
          {
            "id": "1001203",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire49.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90669130"
          },
          {
            "id": "1001204",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire50.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87374537"
          },
          {
            "id": "1001205",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire51.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90818314"
          },
          {
            "id": "1001206",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire52.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90623398"
          },
          {
            "id": "1001207",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire53.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88035134"
          },
          {
            "id": "1001208",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire54.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87891941"
          },
          {
            "id": "1001209",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire55.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89916433"
          },
          {
            "id": "1001210",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire56.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90707800"
          },
          {
            "id": "1001211",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire57.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91135438"
          },
          {
            "id": "1001212",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire58.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88112407"
          },
          {
            "id": "1001213",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire59.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89310387"
          },
          {
            "id": "1001214",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire60.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88548174"
          },
          {
            "id": "1001215",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire61.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88226352"
          },
          {
            "id": "1001216",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire62.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91329926"
          },
          {
            "id": "1001217",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire63.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91402376"
          },
          {
            "id": "1001218",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire64.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90441998"
          },
          {
            "id": "1001219",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire65.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91396903"
          },
          {
            "id": "1001220",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire66.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91104655"
          },
          {
            "id": "1001221",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire67.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87120095"
          },
          {
            "id": "1001222",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire68.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91645104"
          },
          {
            "id": "1001223",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire69.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88748707"
          },
          {
            "id": "1001224",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire70.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89377690"
          },
          {
            "id": "1001225",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire71.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89625867"
          },
          {
            "id": "1001226",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire72.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89533164"
          },
          {
            "id": "1001227",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire73.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90936129"
          },
          {
            "id": "1001228",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire74.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90770285"
          },
          {
            "id": "1001229",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire75.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88190490"
          },
          {
            "id": "1001230",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire76.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87724575"
          },
          {
            "id": "1001231",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire77.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90206392"
          },
          {
            "id": "1001232",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire78.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87122977"
          },
          {
            "id": "1001233",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire79.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91281534"
          },
          {
            "id": "1001234",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire80.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91320074"
          },
          {
            "id": "1001235",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire81.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87868763"
          },
          {
            "id": "1001236",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire82.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87849268"
          },
          {
            "id": "1001237",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire83.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89931354"
          },
          {
            "id": "1001238",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire84.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87018503"
          },
          {
            "id": "1001239",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire85.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88884939"
          },
          {
            "id": "1001240",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire86.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87683926"
          },
          {
            "id": "1001241",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire87.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87872130"
          },
          {
            "id": "1001242",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire88.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90653636"
          },
          {
            "id": "1001243",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire89.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90565562"
          },
          {
            "id": "1001244",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire90.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88991011"
          },
          {
            "id": "1001245",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire91.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91107932"
          },
          {
            "id": "1001246",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire92.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88945833"
          },
          {
            "id": "1001247",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire93.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87368325"
          },
          {
            "id": "1001248",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire94.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90707723"
          },
          {
            "id": "1001249",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire95.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90425873"
          },
          {
            "id": "1001250",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire96.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91622930"
          },
          {
            "id": "1001251",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire97.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.90908285"
          },
          {
            "id": "1001252",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire98.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88904825"
          },
          {
            "id": "1001253",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire99.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88957961"
          },
          {
            "id": "1001254",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire100.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87796320"
          },
          {
            "id": "1001255",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire101.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89914671"
          },
          {
            "id": "1001256",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire102.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.87872004"
          },
          {
            "id": "1001257",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire103.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89271570"
          },
          {
            "id": "1001258",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire104.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.89951410"
          },
          {
            "id": "1001259",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire105.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91624154"
          },
          {
            "id": "1001260",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire106.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.88456961"
          },
          {
            "id": "1001261",
            "create_at": "                   ",
            "image": "/detect/trashtire/trashtire107.png",
            "status": "탐지완료",
            "classification_type": "폐타이어",
            "content": "폐타이어",
            "comment": "평균 탐지율 : 0.91082607"
          }
        ])
     }}>#폐타이어 </a>

      <a style={{backgroundColor: '#f9b87a', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("물통")
        setNewsList([
          {
            "id": "1001262",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum1.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90998638"
          },
          {
            "id": "1001263",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum2.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88037824"
          },
          {
            "id": "1001264",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum3.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91547563"
          },
          {
            "id": "1001265",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum4.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87745288"
          },
          {
            "id": "1001266",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum5.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88685137"
          },
          {
            "id": "1001267",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum6.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87520980"
          },
          {
            "id": "1001268",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum7.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91573525"
          },
          {
            "id": "1001269",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum8.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87264721"
          },
          {
            "id": "1001270",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum9.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87272073"
          },
          {
            "id": "1001271",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum10.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88937070"
          },
          {
            "id": "1001272",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum11.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90052072"
          },
          {
            "id": "1001273",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum12.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91003467"
          },
          {
            "id": "1001274",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum13.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88073191"
          },
          {
            "id": "1001275",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum14.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89592067"
          },
          {
            "id": "1001276",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum15.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88421400"
          },
          {
            "id": "1001277",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum16.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89904451"
          },
          {
            "id": "1001278",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum17.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89249945"
          },
          {
            "id": "1001279",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum18.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87680455"
          },
          {
            "id": "1001280",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum19.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90464607"
          },
          {
            "id": "1001281",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum20.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89615984"
          },
          {
            "id": "1001282",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum21.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90005554"
          },
          {
            "id": "1001283",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum22.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89587861"
          },
          {
            "id": "1001284",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum23.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87014526"
          },
          {
            "id": "1001285",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum24.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90311616"
          },
          {
            "id": "1001286",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum25.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88314871"
          },
          {
            "id": "1001287",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum26.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91671113"
          },
          {
            "id": "1001288",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum27.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91269536"
          },
          {
            "id": "1001289",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum28.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87811859"
          },
          {
            "id": "1001290",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum29.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89883780"
          },
          {
            "id": "1001291",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum30.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88520721"
          },
          {
            "id": "1001292",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum31.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87041895"
          },
          {
            "id": "1001293",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum32.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88060816"
          },
          {
            "id": "1001294",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum33.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90081672"
          },
          {
            "id": "1001295",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum34.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90357813"
          },
          {
            "id": "1001296",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum35.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89897951"
          },
          {
            "id": "1001297",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum36.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91284107"
          },
          {
            "id": "1001298",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum37.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87742812"
          },
          {
            "id": "1001299",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum38.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89185281"
          },
          {
            "id": "1001300",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum39.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90101949"
          },
          {
            "id": "1001301",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum40.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90372972"
          },
          {
            "id": "1001302",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum41.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87167845"
          },
          {
            "id": "1001303",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum42.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87881511"
          },
          {
            "id": "1001304",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum43.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87725592"
          },
          {
            "id": "1001305",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum44.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87306252"
          },
          {
            "id": "1001306",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum45.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90495658"
          },
          {
            "id": "1001307",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum46.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87639536"
          },
          {
            "id": "1001308",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum47.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87699365"
          },
          {
            "id": "1001309",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum48.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90841423"
          },
          {
            "id": "1001310",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum49.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90778219"
          },
          {
            "id": "1001311",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum50.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91672239"
          },
          {
            "id": "1001312",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum51.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87066726"
          },
          {
            "id": "1001313",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum52.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88544487"
          },
          {
            "id": "1001314",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum53.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88740877"
          },
          {
            "id": "1001315",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum54.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91160199"
          },
          {
            "id": "1001316",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum55.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89690399"
          },
          {
            "id": "1001317",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum56.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87191701"
          },
          {
            "id": "1001318",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum57.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90465747"
          },
          {
            "id": "1001319",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum58.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87052279"
          },
          {
            "id": "1001320",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum59.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87756062"
          },
          {
            "id": "1001321",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum60.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90260576"
          },
          {
            "id": "1001322",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum61.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88178221"
          },
          {
            "id": "1001323",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum62.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89998862"
          },
          {
            "id": "1001324",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum63.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90728732"
          },
          {
            "id": "1001325",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum64.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87222573"
          },
          {
            "id": "1001326",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum65.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89876365"
          },
          {
            "id": "1001327",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum66.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90520037"
          },
          {
            "id": "1001328",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum67.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90766371"
          },
          {
            "id": "1001329",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum68.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88533853"
          },
          {
            "id": "1001330",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum69.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90081470"
          },
          {
            "id": "1001331",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum70.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89327680"
          },
          {
            "id": "1001332",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum71.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89981993"
          },
          {
            "id": "1001333",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum72.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89554626"
          },
          {
            "id": "1001334",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum73.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88787973"
          },
          {
            "id": "1001335",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum74.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88209680"
          },
          {
            "id": "1001336",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum75.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88799362"
          },
          {
            "id": "1001337",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum76.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90981588"
          },
          {
            "id": "1001338",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum77.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91316086"
          },
          {
            "id": "1001339",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum78.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87125079"
          },
          {
            "id": "1001340",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum79.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91172761"
          },
          {
            "id": "1001341",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum80.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89119013"
          },
          {
            "id": "1001342",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum81.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89219284"
          },
          {
            "id": "1001343",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum82.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87438729"
          },
          {
            "id": "1001344",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum83.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91415917"
          },
          {
            "id": "1001345",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum84.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87034980"
          },
          {
            "id": "1001346",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum85.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87084673"
          },
          {
            "id": "1001347",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum86.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87568379"
          },
          {
            "id": "1001348",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum87.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89549028"
          },
          {
            "id": "1001349",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum88.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88808515"
          },
          {
            "id": "1001350",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum89.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91246214"
          },
          {
            "id": "1001351",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum90.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87435859"
          },
          {
            "id": "1001352",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum91.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87617928"
          },
          {
            "id": "1001353",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum92.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90823272"
          },
          {
            "id": "1001354",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum93.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91353737"
          },
          {
            "id": "1001355",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum94.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89281014"
          },
          {
            "id": "1001356",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum95.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91718148"
          },
          {
            "id": "1001357",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum96.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91255700"
          },
          {
            "id": "1001358",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum97.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89089612"
          },
          {
            "id": "1001359",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum98.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.91490684"
          },
          {
            "id": "1001360",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum99.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89673140"
          },
          {
            "id": "1001361",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum100.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.88497711"
          },
          {
            "id": "1001362",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum101.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.90163011"
          },
          {
            "id": "1001363",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum102.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89020302"
          },
          {
            "id": "1001364",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum103.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.87351092"
          },
          {
            "id": "1001365",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum104.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89754463"
          },
          {
            "id": "1001366",
            "create_at": "                   ",
            "image": "/detect/waterdrum/waterdrum105.png",
            "status": "탐지완료",
            "classification_type": "방치 물통",
            "content": "방치 물통",
            "comment": "평균 탐지율 : 0.89129073"
          }
        ])
     }}>#물통 </a>

      <a style={{backgroundColor: '#edfca9', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("공사안내표지판")
        setNewsList([
          {
            "id": "1000205",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel1.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91275796"
          },
          {
            "id": "1000206",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel2.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88022116"
          },
          {
            "id": "1000207",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel3.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88045438"
          },
          {
            "id": "1000208",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel4.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89139971"
          },
          {
            "id": "1000209",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel5.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90689807"
          },
          {
            "id": "1000210",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel6.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89327411"
          },
          {
            "id": "1000211",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel7.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91414473"
          },
          {
            "id": "1000212",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel8.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87164656"
          },
          {
            "id": "1000213",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel9.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87522251"
          },
          {
            "id": "1000214",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel10.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88176357"
          },
          {
            "id": "1000215",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel11.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88778633"
          },
          {
            "id": "1000216",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel12.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91169600"
          },
          {
            "id": "1000217",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel13.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90377284"
          },
          {
            "id": "1000218",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel14.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89927219"
          },
          {
            "id": "1000219",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel15.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89188528"
          },
          {
            "id": "1000220",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel16.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90384736"
          },
          {
            "id": "1000221",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel17.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89632138"
          },
          {
            "id": "1000222",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel18.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89512605"
          },
          {
            "id": "1000223",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel19.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89607760"
          },
          {
            "id": "1000224",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel20.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90652139"
          },
          {
            "id": "1000225",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel21.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89202373"
          },
          {
            "id": "1000226",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel22.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90794846"
          },
          {
            "id": "1000227",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel23.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87179999"
          },
          {
            "id": "1000228",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel24.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91507551"
          },
          {
            "id": "1000229",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel25.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87969921"
          },
          {
            "id": "1000230",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel26.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90301951"
          },
          {
            "id": "1000231",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel27.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88842717"
          },
          {
            "id": "1000232",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel28.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87282702"
          },
          {
            "id": "1000233",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel29.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90763292"
          },
          {
            "id": "1000234",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel30.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88954345"
          },
          {
            "id": "1000235",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel31.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89350202"
          },
          {
            "id": "1000236",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel32.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89140158"
          },
          {
            "id": "1000237",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel33.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88461331"
          },
          {
            "id": "1000238",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel34.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89665716"
          },
          {
            "id": "1000239",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel35.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91019361"
          },
          {
            "id": "1000240",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel36.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91729762"
          },
          {
            "id": "1000241",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel37.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90282321"
          },
          {
            "id": "1000242",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel38.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89058705"
          },
          {
            "id": "1000243",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel39.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87696230"
          },
          {
            "id": "1000244",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel40.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89802584"
          },
          {
            "id": "1000245",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel41.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88200390"
          },
          {
            "id": "1000246",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel42.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90122740"
          },
          {
            "id": "1000247",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel43.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90469848"
          },
          {
            "id": "1000248",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel44.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88334386"
          },
          {
            "id": "1000249",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel45.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89426923"
          },
          {
            "id": "1000250",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel46.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88494757"
          },
          {
            "id": "1000251",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel47.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88045615"
          },
          {
            "id": "1000252",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel48.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88719691"
          },
          {
            "id": "1000253",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel49.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91090153"
          },
          {
            "id": "1000254",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel50.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89855509"
          },
          {
            "id": "1000255",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel51.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89067018"
          },
          {
            "id": "1000256",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel52.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87667387"
          },
          {
            "id": "1000257",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel53.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90856799"
          },
          {
            "id": "1000258",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel54.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87333419"
          },
          {
            "id": "1000259",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel55.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89376311"
          },
          {
            "id": "1000260",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel56.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88566569"
          },
          {
            "id": "1000261",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel57.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88436900"
          },
          {
            "id": "1000262",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel58.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89575386"
          },
          {
            "id": "1000263",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel59.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90769521"
          },
          {
            "id": "1000264",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel60.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90755243"
          },
          {
            "id": "1000265",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel61.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89821599"
          },
          {
            "id": "1000266",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel62.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89955055"
          },
          {
            "id": "1000267",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel63.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87712051"
          },
          {
            "id": "1000268",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel64.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89639914"
          },
          {
            "id": "1000269",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel65.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91247557"
          },
          {
            "id": "1000270",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel66.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90652488"
          },
          {
            "id": "1000271",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel67.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88862907"
          },
          {
            "id": "1000272",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel68.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89825701"
          },
          {
            "id": "1000273",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel69.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91219394"
          },
          {
            "id": "1000274",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel70.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89517252"
          },
          {
            "id": "1000275",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel71.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88183436"
          },
          {
            "id": "1000276",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel72.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88550655"
          },
          {
            "id": "1000277",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel73.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89348756"
          },
          {
            "id": "1000278",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel74.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90759213"
          },
          {
            "id": "1000279",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel75.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90886175"
          },
          {
            "id": "1000280",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel76.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91306126"
          },
          {
            "id": "1000281",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel77.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87497989"
          },
          {
            "id": "1000282",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel78.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91625195"
          },
          {
            "id": "1000283",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel79.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87043912"
          },
          {
            "id": "1000284",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel80.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87442661"
          },
          {
            "id": "1000285",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel81.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89817757"
          },
          {
            "id": "1000286",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel82.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87917497"
          },
          {
            "id": "1000287",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel83.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89050190"
          },
          {
            "id": "1000288",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel84.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88504888"
          },
          {
            "id": "1000289",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel85.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87552171"
          },
          {
            "id": "1000290",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel86.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89454642"
          },
          {
            "id": "1000291",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel87.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91706296"
          },
          {
            "id": "1000292",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel88.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90883838"
          },
          {
            "id": "1000293",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel89.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91631139"
          },
          {
            "id": "1000294",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel90.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89199757"
          },
          {
            "id": "1000295",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel91.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.88940176"
          },
          {
            "id": "1000296",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel92.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90421112"
          },
          {
            "id": "1000297",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel93.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89274155"
          },
          {
            "id": "1000298",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel94.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89301982"
          },
          {
            "id": "1000299",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel95.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.91538903"
          },
          {
            "id": "1000300",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel96.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87726265"
          },
          {
            "id": "1000301",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel97.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90362431"
          },
          {
            "id": "1000302",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel98.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.87712397"
          },
          {
            "id": "1000303",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel99.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.89122127"
          },
          {
            "id": "1000304",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel100.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90978135"
          },
          {
            "id": "1000305",
            "create_at": "                   ",
            "image": "/detect/constructpanel/constructpanel101.png",
            "status": "탐지완료",
            "classification_type": "공사안내표지판",
            "content": "공사안내표지판",
            "comment": "평균 탐지율 : 0.90477404"
          }
        ])
     }}>#안내표지판 </a>

      <a style={{backgroundColor: '#a4c8f2', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("맨홀")
        setNewsList([
          {
            "id": "1000519",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole1.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87422548"
          },
          {
            "id": "1000520",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole2.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88917782"
          },
          {
            "id": "1000521",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole3.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87668242"
          },
          {
            "id": "1000522",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole4.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88942244"
          },
          {
            "id": "1000523",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole5.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91041192"
          },
          {
            "id": "1000524",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole6.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89218165"
          },
          {
            "id": "1000525",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole7.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88255046"
          },
          {
            "id": "1000526",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole8.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91064209"
          },
          {
            "id": "1000527",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole9.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87632601"
          },
          {
            "id": "1000528",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole10.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90442152"
          },
          {
            "id": "1000529",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole11.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87338888"
          },
          {
            "id": "1000530",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole12.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87864127"
          },
          {
            "id": "1000531",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole13.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90664570"
          },
          {
            "id": "1000532",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole14.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87066567"
          },
          {
            "id": "1000533",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole15.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90965516"
          },
          {
            "id": "1000534",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole16.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89870528"
          },
          {
            "id": "1000535",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole17.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88394790"
          },
          {
            "id": "1000536",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole18.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89949677"
          },
          {
            "id": "1000537",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole19.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87652453"
          },
          {
            "id": "1000538",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole20.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88433660"
          },
          {
            "id": "1000539",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole21.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89872679"
          },
          {
            "id": "1000540",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole22.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90217514"
          },
          {
            "id": "1000541",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole23.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90419059"
          },
          {
            "id": "1000542",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole24.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90442073"
          },
          {
            "id": "1000543",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole25.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88947451"
          },
          {
            "id": "1000544",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole26.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88463178"
          },
          {
            "id": "1000545",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole27.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91446590"
          },
          {
            "id": "1000546",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole28.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88745951"
          },
          {
            "id": "1000547",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole29.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89932861"
          },
          {
            "id": "1000548",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole30.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87355265"
          },
          {
            "id": "1000549",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole31.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88653270"
          },
          {
            "id": "1000550",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole32.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89746781"
          },
          {
            "id": "1000551",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole33.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89279883"
          },
          {
            "id": "1000552",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole34.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88792956"
          },
          {
            "id": "1000553",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole35.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87860168"
          },
          {
            "id": "1000554",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole36.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87251353"
          },
          {
            "id": "1000555",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole37.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89843838"
          },
          {
            "id": "1000556",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole38.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88011222"
          },
          {
            "id": "1000557",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole39.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88662094"
          },
          {
            "id": "1000558",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole40.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91496105"
          },
          {
            "id": "1000559",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole41.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89507480"
          },
          {
            "id": "1000560",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole42.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90702079"
          },
          {
            "id": "1000561",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole43.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91349224"
          },
          {
            "id": "1000562",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole44.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91612451"
          },
          {
            "id": "1000563",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole45.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88635208"
          },
          {
            "id": "1000564",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole46.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89597973"
          },
          {
            "id": "1000565",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole47.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87365231"
          },
          {
            "id": "1000566",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole48.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89418025"
          },
          {
            "id": "1000567",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole49.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90847185"
          },
          {
            "id": "1000568",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole50.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88694250"
          },
          {
            "id": "1000569",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole51.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88908607"
          },
          {
            "id": "1000570",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole52.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91405521"
          },
          {
            "id": "1000571",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole53.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88353590"
          },
          {
            "id": "1000572",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole54.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89982600"
          },
          {
            "id": "1000573",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole55.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89762636"
          },
          {
            "id": "1000574",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole56.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89288878"
          },
          {
            "id": "1000575",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole57.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91373957"
          },
          {
            "id": "1000576",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole58.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87702422"
          },
          {
            "id": "1000577",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole59.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89347271"
          },
          {
            "id": "1000578",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole60.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88783421"
          },
          {
            "id": "1000579",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole61.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90657782"
          },
          {
            "id": "1000580",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole62.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91386378"
          },
          {
            "id": "1000581",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole63.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91709230"
          },
          {
            "id": "1000582",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole64.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90314133"
          },
          {
            "id": "1000583",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole65.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89147484"
          },
          {
            "id": "1000584",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole66.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91399218"
          },
          {
            "id": "1000585",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole67.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88889999"
          },
          {
            "id": "1000586",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole68.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91242244"
          },
          {
            "id": "1000587",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole69.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90521899"
          },
          {
            "id": "1000588",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole70.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91559517"
          },
          {
            "id": "1000589",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole71.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88550742"
          },
          {
            "id": "1000590",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole72.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89969733"
          },
          {
            "id": "1000591",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole73.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88050683"
          },
          {
            "id": "1000592",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole74.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88844376"
          },
          {
            "id": "1000593",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole75.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90174311"
          },
          {
            "id": "1000594",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole76.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91276817"
          },
          {
            "id": "1000595",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole77.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87366113"
          },
          {
            "id": "1000596",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole78.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88906644"
          },
          {
            "id": "1000597",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole79.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88897433"
          },
          {
            "id": "1000598",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole80.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91613698"
          },
          {
            "id": "1000599",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole81.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88650083"
          },
          {
            "id": "1000600",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole82.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87430886"
          },
          {
            "id": "1000601",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole83.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89379645"
          },
          {
            "id": "1000602",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole84.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90484486"
          },
          {
            "id": "1000603",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole85.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90187895"
          },
          {
            "id": "1000604",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole86.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87204658"
          },
          {
            "id": "1000605",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole87.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90143227"
          },
          {
            "id": "1000606",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole88.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87820039"
          },
          {
            "id": "1000607",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole89.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88555753"
          },
          {
            "id": "1000608",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole90.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90178875"
          },
          {
            "id": "1000609",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole91.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91452600"
          },
          {
            "id": "1000610",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole92.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87801782"
          },
          {
            "id": "1000611",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole93.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88353632"
          },
          {
            "id": "1000612",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole94.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90331618"
          },
          {
            "id": "1000613",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole95.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87752667"
          },
          {
            "id": "1000614",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole96.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.89053880"
          },
          {
            "id": "1000615",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole97.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90435475"
          },
          {
            "id": "1000616",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole98.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87341955"
          },
          {
            "id": "1000617",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole99.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88632328"
          },
          {
            "id": "1000618",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole100.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.91704165"
          },
          {
            "id": "1000619",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole101.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90011346"
          },
          {
            "id": "1000620",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole102.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.87847387"
          },
          {
            "id": "1000621",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole103.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88443721"
          },
          {
            "id": "1000622",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole104.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.90405660"
          },
          {
            "id": "1000623",
            "create_at": "                   ",
            "image": "/detect/manhole/manhole105.png",
            "status": "탐지완료",
            "classification_type": "맨홀",
            "content": "맨홀",
            "comment": "평균 탐지율 : 0.88084316"
          }
        ])
     }}>#맨홀 </a>

      <a style={{backgroundColor: '#c0f99f', borderRadius: '5px', width:'120px', height:'40px', display:'inline-grid', textAlign: 'center', alignContent: 'center',margin: '10px',fontSize: '14px'}} onClick={() => { 
        console.log("마대자루")
        setNewsList([
          {
            "id": "1000950",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag1.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89308557"
          },
          {
            "id": "1000951",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag2.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91693928"
          },
          {
            "id": "1000952",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag3.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89791261"
          },
          {
            "id": "1000953",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag4.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88101327"
          },
          {
            "id": "1000954",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag5.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90999295"
          },
          {
            "id": "1000955",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag6.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89882079"
          },
          {
            "id": "1000956",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag7.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91217356"
          },
          {
            "id": "1000957",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag8.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91046137"
          },
          {
            "id": "1000958",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag9.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89614083"
          },
          {
            "id": "1000959",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag10.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87999375"
          },
          {
            "id": "1000960",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag11.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87823312"
          },
          {
            "id": "1000961",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag12.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90648055"
          },
          {
            "id": "1000962",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag13.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88995735"
          },
          {
            "id": "1000963",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag14.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90105262"
          },
          {
            "id": "1000964",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag15.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89910245"
          },
          {
            "id": "1000965",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag16.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88379240"
          },
          {
            "id": "1000966",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag17.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88185142"
          },
          {
            "id": "1000967",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag18.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88853824"
          },
          {
            "id": "1000968",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag19.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87265138"
          },
          {
            "id": "1000969",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag20.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89888443"
          },
          {
            "id": "1000970",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag21.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88340904"
          },
          {
            "id": "1000971",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag22.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90536316"
          },
          {
            "id": "1000972",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag23.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87501474"
          },
          {
            "id": "1000973",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag24.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88464967"
          },
          {
            "id": "1000974",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag25.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87216323"
          },
          {
            "id": "1000975",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag26.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89752795"
          },
          {
            "id": "1000976",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag27.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91270099"
          },
          {
            "id": "1000977",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag28.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90995882"
          },
          {
            "id": "1000978",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag29.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87425553"
          },
          {
            "id": "1000979",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag30.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91440428"
          },
          {
            "id": "1000980",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag31.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90206735"
          },
          {
            "id": "1000981",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag32.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89412641"
          },
          {
            "id": "1000982",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag33.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90913038"
          },
          {
            "id": "1000983",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag34.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90658118"
          },
          {
            "id": "1000984",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag35.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90085304"
          },
          {
            "id": "1000985",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag36.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88059085"
          },
          {
            "id": "1000986",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag37.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88145965"
          },
          {
            "id": "1000987",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag38.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87254057"
          },
          {
            "id": "1000988",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag39.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90592693"
          },
          {
            "id": "1000989",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag40.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89556295"
          },
          {
            "id": "1000990",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag41.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90375568"
          },
          {
            "id": "1000991",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag42.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90277226"
          },
          {
            "id": "1000992",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag43.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88693158"
          },
          {
            "id": "1000993",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag44.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88941962"
          },
          {
            "id": "1000994",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag45.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90438823"
          },
          {
            "id": "1000995",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag46.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91448400"
          },
          {
            "id": "1000996",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag47.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89340818"
          },
          {
            "id": "1000997",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag48.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87094660"
          },
          {
            "id": "1000998",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag49.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87846602"
          },
          {
            "id": "1000999",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag50.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87572078"
          },
          {
            "id": "1001000",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag51.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89741168"
          },
          {
            "id": "1001001",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag52.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91378092"
          },
          {
            "id": "1001002",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag53.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91658901"
          },
          {
            "id": "1001003",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag54.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88595790"
          },
          {
            "id": "1001004",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag55.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87742532"
          },
          {
            "id": "1001005",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag56.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87966674"
          },
          {
            "id": "1001006",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag57.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87833470"
          },
          {
            "id": "1001007",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag58.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91626505"
          },
          {
            "id": "1001008",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag59.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87915976"
          },
          {
            "id": "1001009",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag60.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90540496"
          },
          {
            "id": "1001010",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag61.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88141865"
          },
          {
            "id": "1001011",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag62.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88112845"
          },
          {
            "id": "1001012",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag63.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87480870"
          },
          {
            "id": "1001013",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag64.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87827179"
          },
          {
            "id": "1001014",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag65.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87807358"
          },
          {
            "id": "1001015",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag66.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87868442"
          },
          {
            "id": "1001016",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag67.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87450842"
          },
          {
            "id": "1001017",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag68.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90035062"
          },
          {
            "id": "1001018",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag69.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90369136"
          },
          {
            "id": "1001019",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag70.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91166485"
          },
          {
            "id": "1001020",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag71.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89308230"
          },
          {
            "id": "1001021",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag72.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88772997"
          },
          {
            "id": "1001022",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag73.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91477898"
          },
          {
            "id": "1001023",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag74.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90327205"
          },
          {
            "id": "1001024",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag75.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87036363"
          },
          {
            "id": "1001025",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag76.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87606464"
          },
          {
            "id": "1001026",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag77.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89430931"
          },
          {
            "id": "1001027",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag78.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89642974"
          },
          {
            "id": "1001028",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag79.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87789805"
          },
          {
            "id": "1001029",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag80.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90128884"
          },
          {
            "id": "1001030",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag81.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87311609"
          },
          {
            "id": "1001031",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag82.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87328271"
          },
          {
            "id": "1001032",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag83.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89233959"
          },
          {
            "id": "1001033",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag84.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87242097"
          },
          {
            "id": "1001034",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag85.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88450732"
          },
          {
            "id": "1001035",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag86.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87436749"
          },
          {
            "id": "1001036",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag87.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87901828"
          },
          {
            "id": "1001037",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag88.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89353759"
          },
          {
            "id": "1001038",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag89.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88151932"
          },
          {
            "id": "1001039",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag90.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.87591494"
          },
          {
            "id": "1001040",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag91.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88500323"
          },
          {
            "id": "1001041",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag92.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.88426457"
          },
          {
            "id": "1001042",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag93.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89295998"
          },
          {
            "id": "1001043",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag94.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91047945"
          },
          {
            "id": "1001044",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag95.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89554991"
          },
          {
            "id": "1001045",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag96.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91426646"
          },
          {
            "id": "1001046",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag97.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91114289"
          },
          {
            "id": "1001047",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag98.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90530754"
          },
          {
            "id": "1001048",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag99.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91482811"
          },
          {
            "id": "1001049",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag100.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89102180"
          },
          {
            "id": "1001050",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag101.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.91690371"
          },
          {
            "id": "1001051",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag102.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.89440536"
          },
          {
            "id": "1001052",
            "create_at": "                   ",
            "image": "/detect/trashbag/trashbag103.png",
            "status": "탐지완료",
            "classification_type": "마대자루",
            "content": "마대자루",
            "comment": "평균 탐지율 : 0.90545034"
          }
        ])
     }}>#마대자루 </a>
    </div>
    <FadeIn transitionDuration={2000}>
          <Row>
            {newsList && newsList.map((item, index) => { 
                // console.log("item....",item)

                // if(index > 54) {
                //   return (
                //     <Col xl={4} md={6} key={index}>
                //       <a onClick={()=>{handleClick('detail',item.id)}} style={{color: '#5b626b'}}>
                //       <Card className="directory-card">
                //         <CardBody>
                //           <div className="d-flex">
                //             <img
                //               src={item.image}
                //               alt="사진"
                //               className="img-fluid img-thumbnail avatar-lg"
                //               style={{width: 6.5 + 'rem'}}
                //               onError={(e) => e.target.src = defaultImg}
                //             />
                //             <div className="flex-1 ms-3">
                //               <h5 className="font-size-18 mt-0 mb-1" >
                //               {item.status}
                //               </h5>
                //               <p className="mb-0">{item.create_at.substr(0, 10)} {item.create_at.substr(11, 8)}</p>
                //               <p className="font-size-12 mb-2">{item.classification_type}</p>
                              
                //             </div>
                //           </div>
                //           <hr />
                //           <p className="mb-0">
                //             <b>{item.content}</b>{" "}
                //             <p className="font-size-12 mb-2">{item.comment}</p>
                //           </p>
                //         </CardBody>
                //       </Card>
                //       </a>
                //     </Col>
                //   )
                // } else {
                //   return 
                // }

                return (
                  <Col xl={4} md={6} key={index}>
                    <a onClick={()=>{handleClick('detail',item.id, item.image, item.classification_type, item.comment)}} style={{color: '#5b626b'}}>
                    <Card className="directory-card">
                      <CardBody>
                        <div className="d-flex">
                          <img
                            src={item.image}
                            alt="사진"
                            className="img-fluid img-thumbnail avatar-lg"
                            style={{width: 6.5 + 'rem'}}
                            onError={(e) => e.target.src = defaultImg}
                          />
                          <div className="flex-1 ms-3">
                            <h5 className="font-size-18 mt-0 mb-1" >
                            {item.status}
                            </h5>
                            <p className="mb-0">{item.create_at.substr(0, 10)} {item.create_at.substr(11, 8)}</p>
                            <p className="font-size-12 mb-2">{item.classification_type}</p>
                            
                          </div>
                        </div>
                        <hr />
                        <p className="mb-0">
                          <b>{item.content}</b>{" "}
                          <p className="font-size-12 mb-2">{item.comment}</p>
                        </p>
                      </CardBody>
                    </Card>
                    </a>
                  </Col>
                )
              })
            }
          </Row>
          </FadeIn>
        </>
    //     </Container>
    //   </div>

    // </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)

// import PropTypes from 'prop-types'
// import React,{useState} from "react"
// import MetaTags from 'react-meta-tags';
// import {
//   Container,
//   Row,
//   Col,
//   Button,
//   Card,
//   CardBody,
//   Input,
//   Dropdown,
//   DropdownToggle,
//   DropdownItem,
//   DropdownMenu
// } from "reactstrap"
// import { Link } from "react-router-dom"
// import  Util  from "./Common/Util"

// import moment from 'moment';

// // import images
// import client from "../../assets/images/client.png";
// import transactionAmount from "../../assets/images/transactionAmount.png";
// import transactionPrice from "../../assets/images/transactionPrice.png";
// import trade-history from "../../assets/images/거래내역.jpg";
// import news from "../../assets/images/news.jpg";

// // Charts
// import LineAreaChart from "../AllCharts/apex/lineareachart";
// import ChartistGraph from 'react-chartist';

// import "chartist/dist/scss/chartist.scss";

// import { useSelector } from 'react-redux';

// //i18n
// import { withTranslation } from "react-i18next"
// import { dashboardResponse, mainResponse, newsResponse } from 'crud/auth.crud';
// import { useEffect } from 'react';

// const Dashboard = props => {
//   const selector = useSelector(state => state)
//   const newDate = new Date()

//   const [isFetching, setIsFetching] = useState(false)
//   const [isListEnd, setIsListEnd] = useState(false)
//   const [newYear, setNewYear] = useState(moment(newDate).format("YYYY"))
//   const [fixedYear, setfixedYear] = useState(moment(newDate).format("YYYY")) //최신으로 고정되는 년도값
//   const [newDateText, setNewDateText] = useState(moment(newDate).format('DD'))
//   const [newMonth, setNewMonth] = useState(moment(newDate).format("MM"))
//   const [page, setPage] = useState(1)
//   const [companyCount, setCompanyCount] = useState();
//   const [collectSum, setCollectSum] = useState();
//   const [collectMoneySum, setCollectMoneySum] = useState();
//   const [newsList, setNewsList] = useState();
//   const [isRefresh, setIsRefresh] = useState(false)
//   const [dataList, setDataList] = useState([])
//   const [monthDateText, setMonthDateText] = useState(moment(newDate).format("MM/DD")+'일자')
//   const [maxDate, setMaxDate] = useState(new Date(moment(newDate).format("YYYY"), moment(newDate).format("MM"),0).getDate())
//   const [thisMonth, setThisMonth] = useState() //이달의 배출량
//   const [prevMonth, setPrevMonth] = useState() //저번달의 배출량

//   // Select박스 map용
//   const yearSelect = [
//     moment(newDate).format("YYYY") - 3, moment(newDate).format("YYYY") - 2, moment(newDate).format("YYYY") - 1, moment(newDate).format("YYYY")
//   ]

//   // 그래프 데이터 가져오는 용
//   const getDataList = async () => {                
//     if (isFetching || isListEnd) {            
//         return;
//     }        
//     let startYm = '';
//     let endYm = '';
//     let maxY = 1;
    
//     if(newMonth && newDateText){            
//       let month = parseInt(newMonth) < 10 ? '0' + parseInt(newMonth) : newMonth;
//       let dateText = parseInt(newDateText) < 10 ? '0' + parseInt(newDateText) : newDateText;            
//       startYm = newYear +'-'+ month +'-'+ dateText + ' 00:00:00';
//       let date = new Date(startYm);
//       endYm = moment(date.setDate(date.getDate()+1)).format('YYYY-MM-DD') + ' 00:00:00';
//     }else if(newMonth){
//       let month = parseInt(newMonth) < 10 ? '0' + parseInt(newMonth) : newMonth;
//       let dateText = '01';
//       startYm = newYear +'-'+ month +'-'+ dateText + ' 00:00:00';
//       let date = new Date(startYm);
//       maxY =  new Date(newYear, month, 0).getDate()
//       endYm = moment(date.setMonth(date.getMonth()+1)).format('YYYY-MM-DD') + ' 00:00:00';
//     }else{
//       startYm = newYear +'-01-01 00:00:00';
//       endYm = parseInt(newYear) + 1 +'-01-01 00:00:00';
//       maxY =  new Date(newYear, 2, 0).getDate() === 29 ? 366 : 365
//     }

//     console.log("startYm ::", startYm)
//     console.log("endYm ::", endYm)

//     let params={            
//         page : page,            
//         company : selector.auth?.user?.member_group?.[0]?.company_group.id,
//         end_ym : endYm,
//         start_ym : startYm,
//         sum : 1
//     }

//     setIsFetching(true)
    
//     const response = await mainResponse(params);
//     console.log(params)       
//     const item = [
//       {
//           label : '쓰레기량',
//           x : 1,
//           y : response.data?.data || 0,           
//       },
//       {
//           label : '최대 쓰레기량',
//           x : 2,
//           y : maxY * 300 - (response.data?.sum || 0),           
//       }
//     ]
//     console.log("item ::", item)
//     setDataList(item)
//     setIsFetching(false)
//   }

//   // 이달의 배출량, 저번달의 배출량 가져오는 용
//   const getFixedData = async () => {

//     let startYm = parseInt(fixedYear) - 1 +'-01-01 00:00:00';
//     let endYm = parseInt(fixedYear) + 1 +'-01-01 00:00:00';

//     let params={            
//       page : page,            
//       company : selector.auth?.user?.member_group?.[0]?.company_group.id,   
//       end_ym : endYm,
//       start_ym : startYm,
//       sum : 1
//     }

//     const fixedResponse = await mainResponse(params)
//     const fixedItem = fixedResponse.data?.data || 0
//     console.log("fixedItem ::", fixedItem)

//     setThisMonth(fixedItem[0]?.qty)
//     setPrevMonth(fixedItem[1]?.qty)
//   }

//   // 지금 이 시간 웨버는? 가져오는 용
//   const getDashboardData = async () => {
//     const response = await dashboardResponse();    
//     console.log("response.data ::", response.data)    
//     if(response.data?.result === 'success'){
//       const item = response.data?.data
//       setCompanyCount(item.company_count);
//       setCollectSum(item.sum_collect);
//       setCollectMoneySum(item.sum_collect_money);
//     }
//   }

//   // 웨버뉴스! 가져오는 용
//   const getNewsList = async () => {
//     try {
//       const response = await newsResponse();
//       if (response.status === 200) {
//         const newsList = response.data?.results?.slice(0, 9).map(news => ({
//           ...news,
//           write_date: moment(new Date(news.write_date)).format('YYYY-MM-DD HH:mm:ss'),
//           subject: Util.truncateString(Util.replaceHtmlTag(news.subject), 35),
//           content: Util.truncateString(Util.replaceHtmlTag(news.content), 80),
//         }));
//         setNewsList(newsList);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     getDataList();
//     getDashboardData();
//     getNewsList();
//     getFixedData();
//     console.log("설렉터", selector)
//   }, [])

//   // select박스 선택될 때마다 발동되는 useEffect
//   useEffect(() => {
//     if(isRefresh){
//       setIsRefresh(false)
//       getDataList();
//   }
//   }, [isRefresh])

//   // 그래프 그릴 때 쓰는거
//   const qtyArr = () => {
//     let arr = []
//     let subArr = [0, 0, 0]
//     if(newYear == 2022){ 
//       for(let i = dataList[0]?.y.length - 1; i >= 0; i--){
//         arr.push(dataList[0]?.y[i]?.qty)
//       }
//       arr = [...subArr, ...arr]
//     }else{
//       for(let i = dataList[0]?.y.length - 1; i >= 0 ; i--){
//         arr.push(dataList[0]?.y[i]?.qty)
//       }
//       arr = [...arr]
//     }
//     return arr;
//   }

//   // 년도 선택될 때마다 발동되는 useEfeect
//   useEffect(() => {
//     qtyArr()
//   }, [newYear])

//   // 그래프 그릴 때 쓰는거
//   const lineChartData = {
//     labels: [1 + '월', 2 + '월', 3 + '월', 4 + '월', 5 + '월', 6 + '월', 7 + '월', 8 + '월', 9 + '월', 10 + '월', 11 + '월', 12 + '월'],
//     series: [
//       qtyArr()
//     ],
//   };
//   const lineChartOptions = {
//       low: 0,
//       showArea: true,
//   }

//   const handleYearClick = (e) => {
//     setNewYear(e.target.value)
//     setMonthDateText(`${e.target.value}년`),
//     setIsFetching(false),
//     setIsListEnd(false),
//     setDataList([]),
//     setNewDateText(''),
//     setNewMonth(''),
//     setMaxDate(new Date(e.target.value, newMonth,0).getDate()),
//     setPage(1)
//     setIsRefresh(true)    
//   }


//   return (
//     // <React.Fragment>
//       // <div className="page-content">
//       //   <MetaTags>
//       //     <title>홈 | Road Safety Total Operating Center </title>
//       //   </MetaTags>
//       //   <Container fluid>
//       //     <div className="page-title-box">
//       //       <Row className="align-items-center">
//       //         <Col md={8}>
//       //           <h6 className="page-title">웨버 홈</h6>
//       //           <ol className="breadcrumb m-0">
//       //             <li className="breadcrumb-item active">'국내최초' 음식물류 폐기물 스마트 수거 플랫폼</li>
//       //           </ol>
//       //         </Col>

//       //       </Row>
//       //     </div>
//         <>
//           <Row>
//             <Col xl={9}>
//               <Card>
//                 <CardBody>
//                   <div style={{display: 'flex'}}>
//                     <h4 className="card-title mb-4">월별 배출량 (단위: L)</h4>
//                     <select id="yearselect" name="yearselect" className='btn btn-primary dropdown-toggle waves-effect waves-light bg-primary' style={{height: 24, marginLeft: 18, fontSize: 14, padding: "0 6px"}} onChange={(e) => {
//                       handleYearClick(e)
//                       console.log(e.target.value)
//                     }}>
//                         <option value="" selected disabled hidden>년도선택</option>
//                       {yearSelect && yearSelect.map((item, index) => {
//                         return (
//                           <option key={index} value={item}>{item + "년"}</option>
//                         )
//                       })}
//                     </select>
//                   </div>
//                   <Row>
//                     <Col lg={8}>
//                       <div>
//                         <ChartistGraph data={lineChartData} style={{ height: "300px" }} options={lineChartOptions} type={'Line'} />
//                       </div>
//                     </Col>
//                     <Col lg={4}>
//                       <Row style={{marginTop: 72}}>
//                         <Col md={6}>
//                           <div className="text-center">
//                             <p className="text-muted mb-4">이달의 배출량</p>
//                             <h3>{thisMonth}L</h3>
//                           </div>
//                         </Col>
//                         <Col md={6}>
//                           <div className="text-center">
//                             <p className="text-muted mb-4">저번달의 배출량</p>
//                             <h3>{prevMonth}L</h3>
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col md={3}>
//               <Card className="bg-primary bg-fill" style={{height: 383.2, backgroundImage: {trade-history}}}>
//                 <CardBody>
//                   <div className="text-center text-white py-4" style={{marginTop: 48}}>
//                     <h1 style={{fontWeight: 500}}>거래내역 보기</h1>
//                     <p className="text-white-50 mb-0" style={{marginTop: 36}}>
//                       일자별, 월별 거래명세서를 확인하세요!
//                     </p>
//                     <Link to="trade-history" className="text-white">
//                       <p style={{marginTop: 12}}>View more</p>
//                     </Link>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>

//           <Container fluid>
//             <div className="page-title-box" style={{paddingTop: 0}}>
//               <Row className="align-items-center">
//                 <Col md={12}>
//                   <h6 className="page-title">지금 이 시간 웨버는?</h6>
//                   <ol className="breadcrumb m-0">
//                     <li className="breadcrumb-item active">웨버의 성장을 지켜봐주세요.</li>
//                   </ol>
//                 </Col>
//               </Row>
//             </div>
//           </Container>

//             <Col xl={1} md={6} style={{width: "11.78%"}}>
//               <Card className="mini-stat text-white" style={{backgroundColor: '#C9CACA'}}>
//                 <CardBody>
//                   <div className="mb-4">
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       이 시간 웨버는?
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       수거·처리{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={client} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       고객사 수
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(companyCount)}개{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionAmount} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래량
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectSum)}kg{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionPrice} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래대금
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectMoneySum)}원{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={1} md={6} style={{width: "11.78%"}}>
//               <Card className="mini-stat text-white" style={{backgroundColor: '#C9CACA'}}>
//                 <CardBody>
//                   <div className="mb-4">
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       이 시간 웨버는?
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       재생원료{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionAmount} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래량
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectSum)}kg{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//             <Col xl={2} md={6} style={{width: "15.28%"}}>
//               <Card className="mini-stat bg-primary text-white">
//                 <CardBody>
//                   <div className="mb-4">
//                     <div className="float-start mini-stat-img me-4">
//                       <img src={transactionPrice} alt="" />
//                     </div>
//                     <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
//                       총 거래대금
//                     </h5>
//                     <h4 className="fw-medium font-size-22">
//                       {Util.setPrice(collectMoneySum)}원{" "}
//                     </h4>
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>

//           <div className="page-title-box" style={{paddingTop: 0}}>
//             <Row className="align-items-center">
//               <Col md={12}>
//                 <h6 className="page-title">웨버뉴스!</h6>
//                 <ol className="breadcrumb m-0">
//                   <li className="breadcrumb-item active">음식물 배출 및 재생원료에 대한 정보를 얻어가세요.</li>
//                 </ol>
//               </Col>
//             </Row>
//           </div>

//           <Row>
//             {newsList && newsList.map((item, index) => { return (
//               <Col xl={4} md={6} key={index}>
//                 <Card className="directory-card">
//                   <CardBody>
//                     <div className="d-flex">
//                       <img
//                         src={item.image_url}
//                         alt="사진"
//                         className="img-fluid img-thumbnail avatar-lg"
//                         style={{width: 6.5 + 'rem'}}
//                       />
//                       <div className="flex-1 ms-3">
//                         <h5 className="text-primary font-size-18 mt-0 mb-1">
//                         {item.subject}
//                         </h5>
//                         <p className="font-size-12 mb-2">{item.created_at.substr(11, 8)}</p>
//                         <p className="mb-0">{item.created_at.substr(0, 10)}</p>
//                       </div>
//                     </div>
//                     <hr />
//                     <p className="mb-0">
//                       <b>{item.content}</b>{" "}
//                       <a href={item.link} target="_blank" className="text-primary">
//                         {" "}
//                         더 보기
//                       </a>
//                     </p>
//                   </CardBody>
//                 </Card>
//               </Col>
//             )})
//             }
//           </Row>
//         </>
//       //   </Container>
//       // </div>

//     // </React.Fragment>
//   )
// }

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

// export default withTranslation()(Dashboard)
